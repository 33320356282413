import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { numberFormat, onlyNumber } from '../../../Utils/Common';
import Spinner, { MiniSpinner } from '../../layouts/Spinner';
import moment from 'moment';
import {
    handleInputChange,
    updateQty,
    submitStockTransfer,
    resetModalInputs,
    searchSubmit,
    removeScan,
} from '../../../redux/actions/stock_transfer/stock_transfer.actions';
import ItemModal from './itemModal';

function TransferModal({
    stock_transfer,
    show,
    onHide,
    updateQty,
    submitStockTransfer,
    handleInputChange,
    resetModalInputs,
    searchSubmit,
    removeScan,
}) {
    const [isPaying, setIsPaying] = useState(false);

    const handleSubmitTransfer = (movement) => {
        setIsPaying(true);
        submitStockTransfer(movement, () => setIsPaying(false)); // Pass movement and a callback
    };

    const { transfer_item, qty, remarks } = stock_transfer;

    // Reset modal inputs when opening
    useEffect(() => {
        if (show) {
            resetModalInputs();
        }
    }, [show, resetModalInputs]);

    if (!show) return null;

    const onFilesSelected = (files) => {
        handleInputChange({ target: { name: 'new_uploads', value: files } });
    };

    // console.log('transfer_item', transfer_item);
    return (
        <Modal show={show} onHide={onHide} size="md" centered>
            <Modal.Header closeButton disabled={isPaying}>
                <Modal.Title>Process Transfer </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* <>
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">
                                    Adjust Return Quantity
                                </h5>
                                <div className="d-flex justify-content-center mb-2">
                                    <b className="mr-2">Quantity:</b>{' '}
                                    <div className="input-group w-50">
                                        <div className="input-group-prepend">
                                            <button
                                                onClick={() => updateQty('dec')}
                                                className="btn btn-outline-secondary"
                                                type="button"
                                            >
                                                <i className="ft ft-minus"></i>
                                            </button>
                                        </div>
                                        <input
                                            onChange={handleInputChange}
                                            onKeyPress={(e) => onlyNumber(e)}
                                            name="qty"
                                            value={qty}
                                            type="number"
                                            className="form-control"
                                            min="0"
                                        />
                                        <div className="input-group-append">
                                            <button
                                                onClick={() => updateQty('inc')}
                                                className="btn btn-outline-secondary"
                                                type="button"
                                            >
                                                <i className="ft ft-plus"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <b>Remarks:</b>
                                    <textarea
                                        rows="2"
                                        placeholder="Remarks"
                                        name="remarks"
                                        value={remarks}
                                        onChange={handleInputChange}
                                        className="form-control f-regular mt-2"
                                        required
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </> */}
                <div className="col-12 mb-2 pr-2">
                    <div className="row p-2">
                        <div className="row mb-1">
                            <label className="col-md-4 col-sm-12 pl-0 text-center align-self-center">
                                SKU/Barcode:{' '}
                            </label>
                            <div className="col-md-7 col-10 px-0">
                                <form onSubmit={(e) => searchSubmit(e, 'btn')}>
                                    <div class="input-group mb-0">
                                        <input
                                            type="text"
                                            id="search"
                                            value={stock_transfer.search}
                                            onChange={handleInputChange}
                                            name="search"
                                            className="form-control f-regular"
                                            placeholder="Search"
                                            required
                                            // disabled={
                                            //     stock_transfer.pos_transaction_edit ===
                                            //     'transaction'
                                            //         ? true
                                            //         : false
                                            // }
                                        />
                                        <div class="input-group-append">
                                            <button
                                                className="btn btn-sm btn-primary btn-z-index search-btn"
                                                type="submit"
                                            >
                                                {/* {stock_transfer.loading ==
                                                                    'stock_transfer-search' &&
                                                                stock_transfer.multi_products ==
                                                                    false ? (
                                                                    <span
                                                                        class="spinner-border spinner-border-sm"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                ) : ( */}
                                                <i className="ft ft-search"></i>
                                                {/* )} */}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-1 pr-0">
                                <div className="float-right">
                                    <div className="input-group-prepend">
                                        <span
                                            href="#"
                                            className="tool-tip text-primary"
                                            data-tip="this is a tool tip"
                                        >
                                            <i className="la la-question-circle"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {transfer_item.length > 0 ? (
                            <div className="col-12 card p-1">
                                <div className="card-header p-0">
                                    <div className="d-flex justify-content-end">
                                        <div>
                                            {transfer_item.loading ==
                                            'stock-transfer-scan' ? (
                                                ''
                                            ) : (
                                                <i
                                                    onClick={() => removeScan()}
                                                    className="ft ft-x ft-tran-icon font-weight-bold pos-close"
                                                ></i>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body row pt-0">
                                    {transfer_item.loading ==
                                    'stock-transfer-scan' ? (
                                        <div className="mx-auto my-4">
                                            <MiniSpinner />
                                        </div>
                                    ) : (
                                        <div className="row my-2">
                                            <div className="col-lg-12 col-12">
                                                <div className="row justify-content-between">
                                                    <div className="d-flex justify-content-center">
                                                        <div className="col-6">
                                                            <img
                                                                src={
                                                                    transfer_item[0]
                                                                        .product_image
                                                                }
                                                                className="img-fluid pos-img mt-0 pt-0"
                                                                alt="img"
                                                            />
                                                        </div>

                                                        <div className="col-6 d-flex justify-content-center align-items-center">
                                                            <div class="input-group-prepend">
                                                                <span
                                                                    onClick={() =>
                                                                        updateQty(
                                                                            'dec'
                                                                        )
                                                                    }
                                                                    class="input-group-text p-0 pos-btn-grey"
                                                                >
                                                                    <i className="ft ft-minus pos-qty-icon"></i>
                                                                </span>
                                                            </div>
                                                            <input
                                                                onChange={
                                                                    handleInputChange
                                                                }
                                                                onKeyPress={(
                                                                    e
                                                                ) =>
                                                                    onlyNumber(
                                                                        e
                                                                    )
                                                                }
                                                                name="qty"
                                                                value={qty}
                                                                type="number"
                                                                class="form-control form-control-lg  f-regular"
                                                                min="1"
                                                            />
                                                            <div class="input-group-append">
                                                                <span
                                                                    onClick={() =>
                                                                        updateQty(
                                                                            'inc'
                                                                        )
                                                                    }
                                                                    class="input-group-text p-0 pos-btn-grey"
                                                                >
                                                                    <i className="ft ft-plus pos-qty-icon"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        {transfer_item[0]
                                                            .stockWarning && (
                                                            <span
                                                                className={`lbl-status lbl-lg lbl-pos-warning`}
                                                            >
                                                                LOW THRESHOLD
                                                            </span>
                                                        )}
                                                        <div className="pos-strong-text pt-1">
                                                            {
                                                                transfer_item[0]
                                                                    .product_name
                                                            }
                                                        </div>
                                                        <div className="pos-description">
                                                            SKU:{' '}
                                                            {
                                                                transfer_item[0]
                                                                    .sku
                                                            }
                                                        </div>
                                                        <div className="pos-description">
                                                            Barcode:{' '}
                                                            {
                                                                transfer_item[0]
                                                                    .barcode
                                                            }
                                                        </div>
                                                        <div className="pos-description">
                                                            Brand:{' '}
                                                            {
                                                                transfer_item[0]
                                                                    .brand_name
                                                            }
                                                        </div>
                                                        <div className="pos-description mb-2">
                                                            SRP: P{' '}
                                                            {numberFormat(
                                                                transfer_item[0]
                                                                    .srp
                                                            )}
                                                        </div>

                                                        <div>
                                                            <b>Remarks:</b>
                                                            <textarea
                                                                rows="2"
                                                                placeholder="Remarks"
                                                                name="remarks"
                                                                value={remarks}
                                                                onChange={
                                                                    handleInputChange
                                                                }
                                                                className="form-control f-regular mt-2"
                                                                required
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="col-12 card mb-0">
                                <div className="card-body row pos-blank-card">
                                    {/* THIS IS A EMPTY CARD */}
                                </div>
                            </div>
                        )}
                    </div>{' '}
                </div>{' '}
            </Modal.Body>
            <Modal.Footer>
                {/* <Button variant="secondary" onClick={onHide}>
                        Cancel
                    </Button> */}
                <button
                    onClick={() => handleSubmitTransfer('in')}
                    type="button"
                    className="btn btn-primary btn-in btn-trans btn-lg mr-0 btn-add"
                >
                    In
                </button>{' '}
                <button
                    onClick={() => handleSubmitTransfer('out')}
                    type="button"
                    className="btn btn-primary btn-out btn-trans btn-lg mr-0 btn-add"
                >
                    Out
                </button>
            </Modal.Footer>

            {/* ITEM MODAL */}
            <ItemModal />
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    stock_transfer: state.stock_transfer,
    show: state.stock_transfer.stock_transfer_modal,
});

export default connect(mapStateToProps, {
    handleInputChange,
    updateQty,
    submitStockTransfer,
    resetModalInputs,
    searchSubmit,
    removeScan,
})(TransferModal);
