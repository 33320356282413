import http from '../../../Services/api.laravel.http';

export const StockTransferService = {
    getStockTransfers: (formParams) =>
        http.post('/admin/stock-transfer/search', formParams),
    scanBarcode: (formParams) =>
        http.post('/admin/stock-transfer/scan', formParams),
    submitStockTransfer: (formParams) =>
        http.post('/admin/stock-transfer/transfer', formParams),
};
