import React, { Fragment, useEffect, useState, useMemo } from 'react';
import Spinner from '../layouts/Spinner';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import AsyncSelect from 'react-select/async';
import Select, { components } from 'react-select';
import DatePicker from 'react-datepicker';
import {
    getQuotations,
    searchQuotation,
    getQuotationItems,
    sumbit_delivery,
    handleSelectPaymentType,
    getTermsConditions,
    handleSelectTermsConditions,
    handleSelectReceiptType,
    checkBoxManual,
    handleCreateSO,
    handleSelectDRTemplate,
    getDeliveryTemplates,
    getCustomers,
    getProducts,
    handleSelectCustomer,
    handleSelectItem,
    changeQty,
    addItem,
    remItem,
    handleDiscountItem,
    handleSelectDiscountTypeProduct,
    handleInputDiscountProduct,
    vat_change,
    deliveryFee,
    discountType,
    discountInput,
    refNo,
    remarksInput,
    setModal,
    clearDelivery,
    searchProducts,
    searchCustomers,
    handleInputChangeDiscount,
    handleSelectDiscountType,
    handleTaxType,
    computationHandler,
    clear_data,
    ShowComboBox,
    fetchQuotations,
    dynamicInputChange,
    createProduct,
    skuSkipValidation,
    generateSku,
} from '../../redux/actions/delivery/delivery.actions';
import { numberFormat, stockClass } from '../../Utils/Common';
import { SwalWarning, SwalFormWarning } from '../../Services/_swal.service';
import DeliveryCustomerModal from './modals/customer.modal';
import { Link, Redirect } from 'react-router-dom';
import { Show } from 'react-redux-permission';
import BeforeUnloadComponent from 'react-beforeunload-component';
import { useHistory, useLocation } from 'react-router-dom';
import { blockRoute } from '../../redux/actions/helper/helper.actions';
import DynamicFont from 'react-dynamic-font';
import debounce from 'debounce-promise';
import { subscriberSetting } from '../../redux/actions/settings_app/setting_app.actions';
import { ToastWarning } from '../../Services/_toast.service';
import { MiniSpinner } from '../layouts/Spinner';

function DeliveryCreate({
    delivery: {
        quotations,
        search,
        si_data,
        loading,
        enable_submit,
        quotation_id,
        payment_mode_options,
        isSearchable,
        terms_conditions,
        receipt_type_options,
        delivery_templates,
        dr_default_template,
        customers,
        items,
        default_customer_value,
        customer_modal,
        tax_types,
        pre_load,
        redirect,
        generate_so,
    },
    getQuotations,
    fetchQuotations,
    searchQuotation,
    getQuotationItems,
    sumbit_delivery,
    handleSelectPaymentType,
    getTermsConditions,
    handleSelectTermsConditions,
    handleSelectReceiptType,
    checkBoxManual,
    handleCreateSO,
    handleSelectDRTemplate,
    getDeliveryTemplates,
    getCustomers,
    getProducts,
    handleSelectCustomer,
    handleSelectItem,
    changeQty,
    addItem,
    remItem,
    handleDiscountItem,
    handleSelectDiscountTypeProduct,
    handleInputDiscountProduct,
    vat_change,
    deliveryFee,
    discountType,
    discountInput,
    refNo,
    remarksInput,
    setModal,
    clearDelivery,
    location,
    block_route,
    blockRoute,
    searchProducts,
    searchCustomers,
    handleInputChangeDiscount,
    handleSelectDiscountType,
    handleTaxType,
    computationHandler,
    subscriberSetting,
    clear_data,
    ShowComboBox,
    dynamicInputChange,
    createProduct,
    skuSkipValidation,
    generateSku,
}) {
    const [delivery_date, setdatePick] = useState(new Date());
    // const location = useLocation();

    let history = useHistory();

    useEffect(() => {
        blockRoute(false);
    }, []);

    useEffect(() => {
        async function fetchAll() {
            Promise.all([
                getQuotations(),
                fetchQuotations(),
                getTermsConditions(),
                getDeliveryTemplates(),
                getCustomers(),
                getProducts(),
                clearDelivery(),
                clear_data(),
                subscriberSetting('delivery'),
            ]);
        }

        fetchAll();
    }, [search]);

    const { discount, discount_type, tax, tax_type, other } = si_data;
    useEffect(() => {
        computationHandler();
    }, [
        JSON.stringify(si_data.items),
        discount,
        discount_type,
        tax,
        tax_type,
        other,
    ]);

    useMemo(() => {
        // if (si_data.is_selected) {
        //   subscriberSetting("delivery");
        // }
    }, [si_data.is_selected]);

    useEffect(() => {
        // When onClick navbar prevent another dialog box
        window.addEventListener('beforeunload', function (e) {
            // the absence of a returnValue property on the event will guarantee the browser unload happens
            delete e['returnValue'];
            blockRoute(false);
        });

        if (block_route) {
            window.history.pushState(null, null, window.location.pathname);
            window.addEventListener('popstate', onBackButtonEvent);
        }

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [block_route]);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if (block_route) {
            SwalFormWarning(
                'Warning',
                'Do you want to leave without saving',
                () => goLastLocation(),
                () => blockAgain()
            );
        }
    };

    const goLastLocation = () => {
        history.goBack();
        blockRoute(false);
    };

    const blockAgain = () =>
        window.history.pushState(null, null, window.location.pathname);

    useEffect(() => {
        if (location.makeDR != null) {
            getQuotationItems(location.makeDR);
        }
    }, [location.makeDR]);

    if (si_data.is_selected) {
        location.makeDr = null;
    }

    const submitClick = (status) => {
        // const { location } = history
        // console.log('HISTORY', location?.makeDR?.pageOrigin ? location.makeDR.pageOrigin : 'nothing')
        let stat_arr = status.split('-');
        let stats = stat_arr[0];
        let actions = stat_arr[1] !== undefined ? stat_arr[1] : null;

        if (si_data.is_selected === true) {
            if (default_customer_value.value === '') {
                ToastWarning('Please Select Customer.');
            } else {
                const formdata = {
                    // quotation_id: quotation_id,
                    date: delivery_date,
                    dr_status: stats,
                    si_data: si_data,
                };

                if (status == 'saved' || status == 'saved-print') {
                    SwalWarning(
                        'Warning!',
                        'You are about to save Delivery Receipt, saving this will stop you from editing any other changes. Continue?',
                        () => sumbit_delivery(formdata, actions)
                    );
                } else if (status == 'draft-email') {
                    SwalWarning(
                        'Warning!',
                        'You are about to draft and email Delivery Receipt, saving this will stop you from editing any other changes. Continue?',
                        () => sumbit_delivery(formdata, actions)
                    );
                } else {
                    sumbit_delivery(formdata, actions);
                }
            }
        } else {
            const formdata = {
                quotation_id: quotation_id,
                date: delivery_date,
                dr_status: stats,
                si_data: si_data,
            };
            if (status == 'saved' || status == 'saved-print') {
                SwalWarning(
                    'Warning!',
                    'You are about to save Delivery Receipt, saving this will stop you from editing any other changes. Continue?',
                    () => sumbit_delivery(formdata, actions)
                );
            } else if (status == 'saved-email') {
                SwalWarning(
                    'Warning!',
                    'You are about to save and email Delivery Receipt, saving this will stop you from editing any other changes. Continue?',
                    () => sumbit_delivery(formdata, actions)
                );
            } else if (status == 'draft-email') {
                SwalWarning(
                    'Warning!',
                    'You are about to draft and email Delivery Receipt, saving this will stop you from editing any other changes. Continue?',
                    () => sumbit_delivery(formdata, actions)
                );
            } else {
                sumbit_delivery(formdata, actions);
            }
        }

        // ** THIS CAUSE MULTIPLE SUBMITS
        // if (location.makeDR) {
        //   if (location.makeDR.pageOrigin === "si-makeDR") {
        //     const formdata = {
        //       quotation_id: quotation_id,
        //       date: delivery_date,
        //       dr_status: stats,
        //       si_data: si_data,
        //     };
        //     if (status == "saved" || status == "saved-print") {
        //       SwalWarning(
        //         "Warning!",
        //         "You are about to save Delivery Receipt, saving this will stop you from editing any other changes. Continue?",
        //         () => sumbit_delivery(formdata, actions)
        //       );
        //     } else if (status == "saved-email") {
        //       SwalWarning(
        //         "Warning!",
        //         "You are about to save and email Delivery Receipt, saving this will stop you from editing any other changes. Continue?",
        //         () => sumbit_delivery(formdata, actions)
        //       );
        //     } else if (status == "draft-email") {
        //       SwalWarning(
        //         "Warning!",
        //         "You are about to draft and email Delivery Receipt, saving this will stop you from editing any other changes. Continue?",
        //         () => sumbit_delivery(formdata, actions)
        //       );
        //     } else {
        //       sumbit_delivery(formdata, actions);
        //     }
        //   }
        // }
    };
    const Option = (props) => {
        return (
            <components.Option {...props}>
                <div>
                    {props.data.label} [{props.data.brand}]
                </div>
                <div style={{ fontSize: 11 }}>{props.data.sku}</div>
                <div style={{ fontSize: 11 }}>{props.data.description}</div>
            </components.Option>
        );
    };

    const customFilter = (option, searchText) => {
        if (
            option.data.label
                .toLowerCase()
                .includes(searchText.toLowerCase()) ||
            option.data.sku.toLowerCase().includes(searchText.toLowerCase())
        ) {
            return true;
        } else {
            return false;
        }
    };

    // let percent_value = [
    //   { value: 0, label: "None" },
    //   { value: 1, label: "Percent" },
    //   { value: 2, label: "Amount" },
    // ];
    let percent_value = [
        { value: 'none', label: 'None' },
        { value: 'percent', label: 'Percent' },
        { value: 'amount', label: 'Amount' },
    ];

    if (redirect) {
        return <Redirect to={'/deliveries'} />;
    }
    // console.log('si_data', si_data);

    // console.log("location.makeDr", location.makeDr)
    return (
        <BeforeUnloadComponent
            blockRoute={block_route}
            ignoreBeforeunloadDocument={true}
            modalComponentHandler={({
                handleModalLeave,
                handleModalCancel,
            }) => {
                SwalFormWarning(
                    'Warning',
                    'Do you want to leave without saving',
                    () => handleModalLeave(),
                    () => handleModalCancel()
                );
            }}
        >
            <div className="app-content content">
                <ReactTooltip effect="solid" event="click" />
                <div className="content-wrapper max-1920 mx-auto">
                    <div className="content-wrapper-before"></div>
                    <div className="content-header row">
                        <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                            <h3 className="content-header-title mb-0 d-inline-block">
                                Delivery Receipts
                            </h3>
                            <div className="breadcrumbs-top d-inline-block">
                                <div className="breadcrumb-wrapper mr-1">
                                    <ol className="breadcrumb f-regular">
                                        <li className="breadcrumb-item ">
                                            <a href="#" className="text-white">
                                                Create
                                            </a>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-body margin-top-5">
                        <section className="row f-regular">
                            <div className="col-sm-12">
                                <div id="what-is" className="card ">
                                    <div className="card-header">
                                        <Link to="/deliveries">
                                            <i className="ft ft-arrow-left"></i>{' '}
                                            Back
                                        </Link>
                                        <a className="heading-elements-toggle">
                                            <i className="la la-ellipsis-v font-medium-3"></i>
                                        </a>
                                    </div>

                                    {loading ? (
                                        <MiniSpinner />
                                    ) : (
                                        <div className="card-content collapse show f-regular">
                                            <div className="card-body pb-10m">
                                                {/* <div className='row'>
                          <div className='col-md-8'></div>
                          <div className='col-md-4'>
                            <div className="form-group pb-0 mb-0 button_container">
                                <label className="switch">
                                  <input 
                                    type="checkbox" 
                                    checked={si_data.is_selected} 
                                    onChange={checkBoxManual}
                                  />
                                  <span className="slider round"></span>
                                </label>

                                <label
                                  htmlFor='manual-input'
                                  className='font-medium-2 text-bold-600 ml-1'
                                >
                                {si_data.is_selected ? 'Manual' : 'Automated'}
                                </label>
                              </div>
                            </div>
                        
                        </div> */}

                                                <div className="row">
                                                    <div className="col-md-8"></div>
                                                    <div className="col-md-4">
                                                        <div className="form-group row">
                                                            <label className="col-md-12">
                                                                Create Sales
                                                                Order
                                                            </label>
                                                            <div className="col-md-10 col-10 pr-0">
                                                                <Select
                                                                    value={
                                                                        si_data.generate_so ===
                                                                        null
                                                                            ? null
                                                                            : {
                                                                                  value: si_data.generate_so
                                                                                      ? 'yes'
                                                                                      : 'no',
                                                                                  label: si_data.generate_so
                                                                                      ? 'Yes'
                                                                                      : 'No',
                                                                              }
                                                                    }
                                                                    onChange={
                                                                        handleCreateSO
                                                                    }
                                                                    options={[
                                                                        {
                                                                            value: 'yes',
                                                                            label: 'Yes',
                                                                        },
                                                                        {
                                                                            value: 'no',
                                                                            label: 'No',
                                                                        },
                                                                    ]}
                                                                    placeholder="Select"
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="float-right pl-0 col-md-2 col-2">
                                                                <div className="input-group-prepend">
                                                                    <span
                                                                        href="#"
                                                                        className="tool-tip text-primary"
                                                                        data-tip="Choose Yes or No to generate Sales Order"
                                                                    >
                                                                        <i className="la la-question-circle"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <section>
                                                            <div className="form-group row">
                                                                <label className="col-md-12">
                                                                    Template
                                                                </label>
                                                                <div className="col-md-10 col-10 pr-0">
                                                                    <Select
                                                                        options={
                                                                            delivery_templates
                                                                        }
                                                                        onChange={
                                                                            handleSelectDRTemplate
                                                                        }
                                                                        value={
                                                                            dr_default_template
                                                                        }
                                                                        isSearchable={
                                                                            isSearchable
                                                                        }
                                                                        required
                                                                    />
                                                                </div>
                                                                <div className="float-right pl-0 col-md-2 col-2">
                                                                    <div className="input-group-prepend">
                                                                        <span
                                                                            href="#"
                                                                            className="tool-tip text-primary"
                                                                            data-tip="this is a tool tip"
                                                                        >
                                                                            <i className="la la-question-circle"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {si_data.is_selected ? (
                                                                <div className="form-group row">
                                                                    <label className="col-md-12">
                                                                        Customer{' '}
                                                                        <span className="text-danger">
                                                                            *
                                                                        </span>{' '}
                                                                    </label>
                                                                    <div className="col-md-10 col-10 pr-0">
                                                                        <AsyncSelect
                                                                            isLoading={
                                                                                loading
                                                                            }
                                                                            classNamePrefix="lp-copy-sel"
                                                                            loadingMessage={() =>
                                                                                'searching...'
                                                                            }
                                                                            defaultOptions={
                                                                                customers
                                                                            }
                                                                            value={
                                                                                default_customer_value
                                                                            }
                                                                            noOptionsMessage={({
                                                                                inputValue,
                                                                            }) =>
                                                                                inputValue
                                                                                    ? 'No Customer'
                                                                                    : 'Search Customer'
                                                                            }
                                                                            loadOptions={debounce(
                                                                                (
                                                                                    value
                                                                                ) =>
                                                                                    searchCustomers(
                                                                                        value
                                                                                    ),
                                                                                500
                                                                            )}
                                                                            isSearchable={
                                                                                isSearchable
                                                                            }
                                                                            cacheOptions
                                                                            onChange={
                                                                                handleSelectCustomer
                                                                            }
                                                                            placeholder="Select Customer..."
                                                                            required
                                                                        />
                                                                    </div>
                                                                    <div className="float-right pl-0 col-md-2 col-2">
                                                                        <div className="input-group-prepend">
                                                                            <span
                                                                                href="#"
                                                                                className="tool-tip text-primary"
                                                                                data-tip="this is a tool tip"
                                                                            >
                                                                                <i className="la la-question-circle"></i>
                                                                            </span>
                                                                            <div
                                                                                className="a-tagicon icon-s"
                                                                                onClick={() =>
                                                                                    setModal(
                                                                                        'customer_modal',
                                                                                        true
                                                                                    )
                                                                                }
                                                                            >
                                                                                <i className="ft ft-plus-circle text-primary"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="form-group row">
                                                                    <label className="col-md-12">
                                                                        Customer
                                                                        Name
                                                                    </label>
                                                                    <div className="col-md-10 col-10 pr-0">
                                                                        <div className="form-control f-regular">
                                                                            {
                                                                                si_data.customer
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="float-right pl-0 col-md-2 col-2">
                                                                        <div className="input-group-prepend">
                                                                            <span
                                                                                href="#"
                                                                                className="tool-tip text-primary"
                                                                                data-tip="this is a tool tip"
                                                                            >
                                                                                <i className="la la-question-circle"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </section>
                                                    </div>
                                                    <div className="col-md-4"></div>
                                                    <div className="col-md-4">
                                                        <section>
                                                            <div className="form-group row">
                                                                <label className="col-md-12">
                                                                    Reference
                                                                    No.
                                                                </label>
                                                                <div className="col-md-10 col-10 pr-0">
                                                                    <input
                                                                        className="form-control f-regular"
                                                                        placeholder="Reference No."
                                                                        value={
                                                                            si_data.reference_no
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            refNo(
                                                                                e
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="float-right pl-0 col-md-2 col-2">
                                                                    <div className="input-group-prepend">
                                                                        <span
                                                                            href="#"
                                                                            className="tool-tip text-primary"
                                                                            data-tip="this is a tool tip"
                                                                        >
                                                                            <i className="la la-question-circle"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>

                                                        <div className="form-group row">
                                                            <label className="col-md-12">
                                                                Received Date
                                                            </label>
                                                            <div className="col-md-10 col-10 pr-0">
                                                                <div className="date-picker-100">
                                                                    <DatePicker
                                                                        onChange={(
                                                                            date
                                                                        ) =>
                                                                            setdatePick(
                                                                                date
                                                                            )
                                                                        }
                                                                        selected={
                                                                            delivery_date
                                                                        }
                                                                        className="form-control f-regular"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="float-right pl-0 col-md-2 col-2">
                                                                <div className="input-group-prepend">
                                                                    <span
                                                                        href="#"
                                                                        className="tool-tip text-primary"
                                                                        data-tip="this is a tool tip"
                                                                    >
                                                                        <i className="la la-question-circle"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="table-responsive">
                                                            <table className="table table-condensed table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="text-left td-16m">
                                                                            SKU
                                                                        </th>
                                                                        <th className="text-center">
                                                                            Product
                                                                        </th>
                                                                        <th className="text-center">
                                                                            Qty
                                                                        </th>
                                                                        <th className="text-center">
                                                                            Unit
                                                                        </th>
                                                                        <th className="text-center">
                                                                            SRP
                                                                        </th>
                                                                        <th className="text-center">
                                                                            Unit/Price
                                                                        </th>
                                                                        <th className="text-center min-width-80">
                                                                            Amount
                                                                        </th>
                                                                        {si_data.is_selected ? (
                                                                            <th
                                                                                width="100"
                                                                                className="text-center"
                                                                            >
                                                                                Action
                                                                            </th>
                                                                        ) : (
                                                                            <th
                                                                                width="100"
                                                                                className="text-center"
                                                                            >
                                                                                Action
                                                                            </th>
                                                                        )}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        !si_data.is_selected ? (
                                                                            <tr className="bg-warning bg-accent-2">
                                                                                <td
                                                                                    colSpan={
                                                                                        si_data.is_selected
                                                                                            ? 8
                                                                                            : 8
                                                                                    }
                                                                                    className="text-center"
                                                                                >
                                                                                    --Please
                                                                                    select
                                                                                    if
                                                                                    you
                                                                                    want
                                                                                    to
                                                                                    Generate
                                                                                    Sales
                                                                                    Order.--
                                                                                </td>
                                                                            </tr>
                                                                        ) : loading ? (
                                                                            <tr>
                                                                                <td
                                                                                    colSpan={
                                                                                        si_data.is_selected
                                                                                            ? 8
                                                                                            : 8
                                                                                    }
                                                                                    className="text-center"
                                                                                >
                                                                                    <Spinner />
                                                                                </td>
                                                                            </tr>
                                                                        ) : si_data.is_selected ? (
                                                                            si_data.customer ==
                                                                            '' ? (
                                                                                <tr className="bg-warning bg-accent-2">
                                                                                    <td
                                                                                        colSpan={
                                                                                            si_data.is_selected
                                                                                                ? 8
                                                                                                : 8
                                                                                        }
                                                                                        className="text-center"
                                                                                    >
                                                                                        --Please
                                                                                        select
                                                                                        a
                                                                                        Customer.--
                                                                                    </td>
                                                                                </tr>
                                                                            ) : (
                                                                                si_data.items.map(
                                                                                    (
                                                                                        data,
                                                                                        key
                                                                                    ) => {
                                                                                        return (
                                                                                            <Fragment
                                                                                                key={
                                                                                                    key
                                                                                                }
                                                                                            >
                                                                                                {' '}
                                                                                                {/* This is the key part */}
                                                                                                <tr
                                                                                                    className={stockClass(
                                                                                                        data.status_code
                                                                                                    )}
                                                                                                >
                                                                                                    {/* <tr
                                                key={key}
                                                className={stockClass(
                                                  data.status_code
                                                )}
                                              >  */}
                                                                                                    <td className="table-data__sku ">
                                                                                                        {data.non_inventory ? (
                                                                                                            <>
                                                                                                                <div className="input-group">
                                                                                                                    {/* SKU Input Field */}
                                                                                                                    <input
                                                                                                                        type="text"
                                                                                                                        className="form-control text-left"
                                                                                                                        id="sku"
                                                                                                                        name="sku"
                                                                                                                        value={
                                                                                                                            data.sku
                                                                                                                        }
                                                                                                                        step="any"
                                                                                                                        onBlur={(
                                                                                                                            e
                                                                                                                        ) =>
                                                                                                                            skuSkipValidation(
                                                                                                                                e,
                                                                                                                                key
                                                                                                                            )
                                                                                                                        }
                                                                                                                        onChange={(
                                                                                                                            e
                                                                                                                        ) =>
                                                                                                                            dynamicInputChange(
                                                                                                                                e,
                                                                                                                                key
                                                                                                                            )
                                                                                                                        }
                                                                                                                        required
                                                                                                                    />
                                                                                                                    {/* Generate Button */}
                                                                                                                    {data.sku ? (
                                                                                                                        <>

                                                                                                                        </>
                                                                                                                    ) : (
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="border border-0 bg-white"
                                                                                                                            onClick={(
                                                                                                                                e
                                                                                                                            ) =>
                                                                                                                                generateSku(
                                                                                                                                    e,
                                                                                                                                    key
                                                                                                                                )
                                                                                                                            }
                                                                                                                        >
                                                                                                                            {' '}
                                                                                                                            <i className="la la-cogs text-primary"></i>
                                                                                                                        </button>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    style={{
                                                                                                                        fontSize:
                                                                                                                            '10px',
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <i
                                                                                                                        className="la la-check-square text-primary"
                                                                                                                        style={{
                                                                                                                            fontSize:
                                                                                                                                '10px',
                                                                                                                        }}
                                                                                                                    ></i>{' '}
                                                                                                                    Non-Inv
                                                                                                                </div>
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                {
                                                                                                                    data.sku
                                                                                                                }
                                                                                                            </>
                                                                                                        )}
                                                                                                    </td>
                                                                                                    <td className="min-wdth-300 max-wdth-300">
                                                                                                        {data.selected ? (
                                                                                                            <div>
                                                                                                                {/* <div className='f-right'  onClick={() => ShowComboBox(key)}>
                                                        <i className='ft ft-chevron-down c-pointer'></i>
                                                      </div> */}
                                                                                                                <div className="mw-90">
                                                                                                                    <Link
                                                                                                                        to={`/inventory/view/${data._id}`}
                                                                                                                        target="_blank"
                                                                                                                        onClick={(
                                                                                                                            e
                                                                                                                        ) =>
                                                                                                                            e.stopPropagation()
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {
                                                                                                                            data.product_name
                                                                                                                        }
                                                                                                                    </Link>
                                                                                                                </div>
                                                                                                                <div className="f-12 text-dark">
                                                                                                                    {
                                                                                                                        data.sku
                                                                                                                    }
                                                                                                                </div>
                                                                                                                <div className="f-12 text-dark">
                                                                                                                    {
                                                                                                                        data.description
                                                                                                                    }
                                                                                                                </div>
                                                                                                                <div className="f-12 text-dark">
                                                                                                                    {
                                                                                                                        data.brand
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        ) : data.non_inventory ? (
                                                                                                            <div className="d-flex">
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    className="form-control text-left"
                                                                                                                    name="product_name"
                                                                                                                    value={
                                                                                                                        data.product_name
                                                                                                                    }
                                                                                                                    step="any"
                                                                                                                    onChange={(
                                                                                                                        e
                                                                                                                    ) =>
                                                                                                                        dynamicInputChange(
                                                                                                                            e,
                                                                                                                            key
                                                                                                                        )
                                                                                                                    }
                                                                                                                    required
                                                                                                                />

                                                                                                                <div className="d-flex align-items-center ml-1">
                                                                                                                    <Show when="inv.receiving.itemReceipts.cost">
                                                                                                                        <a
                                                                                                                            className="ml-10"
                                                                                                                            onClick={() =>
                                                                                                                                createProduct(
                                                                                                                                    key,
                                                                                                                                    false
                                                                                                                                )
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <i className="ft ft-x-circle text-warning"></i>
                                                                                                                        </a>
                                                                                                                    </Show>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        ) : (
                                                                                                            <div className="d-flex">
                                                                                                                <AsyncSelect
                                                                                                                    className="w-100"
                                                                                                                    value={
                                                                                                                        data.product_value
                                                                                                                    }
                                                                                                                    isLoading={
                                                                                                                        loading
                                                                                                                    }
                                                                                                                    components={{
                                                                                                                        Option,
                                                                                                                    }}
                                                                                                                    classNamePrefix="lp-copy-sel"
                                                                                                                    loadingMessage={() =>
                                                                                                                        'searching...'
                                                                                                                    }
                                                                                                                    noOptionsMessage={({
                                                                                                                        inputValue,
                                                                                                                    }) =>
                                                                                                                        inputValue
                                                                                                                            ? 'No Products'
                                                                                                                            : 'Search product'
                                                                                                                    }
                                                                                                                    defaultOptions={
                                                                                                                        data.items
                                                                                                                    }
                                                                                                                    loadOptions={debounce(
                                                                                                                        (
                                                                                                                            value
                                                                                                                        ) =>
                                                                                                                            searchProducts(
                                                                                                                                value,
                                                                                                                                key
                                                                                                                            ),
                                                                                                                        1000
                                                                                                                    )}
                                                                                                                    isSearchable={
                                                                                                                        true
                                                                                                                    }
                                                                                                                    cacheOptions
                                                                                                                    onChange={(
                                                                                                                        val
                                                                                                                    ) =>
                                                                                                                        handleSelectItem(
                                                                                                                            val,
                                                                                                                            key
                                                                                                                        )
                                                                                                                    }
                                                                                                                    placeholder="Select Products..."
                                                                                                                    required
                                                                                                                />{' '}
                                                                                                                <div className="d-flex align-items-center ml-1">
                                                                                                                    <a
                                                                                                                        className="ml-10"
                                                                                                                        onClick={() =>
                                                                                                                            createProduct(
                                                                                                                                key,
                                                                                                                                true
                                                                                                                            )
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <i className="la la-cart-plus text-primary"></i>
                                                                                                                    </a>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )}

                                                                                                        {/* <Select components={{Option}} filterOption={customFilter} classNamePrefix="lp-copy-sel" options={items} onChange={(value) => handleSelectItem(value, key)} isSearchable={isSearchable} value={data.product_value}/> */}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        className="mw-6m text-center"
                                                                                                        align="center"
                                                                                                    >
                                                                                                        {data.non_inventory ? (
                                                                                                            <input
                                                                                                                value={
                                                                                                                    data.qty
                                                                                                                }
                                                                                                                onChange={(
                                                                                                                    e
                                                                                                                ) =>
                                                                                                                    dynamicInputChange(
                                                                                                                        e,
                                                                                                                        key
                                                                                                                    )
                                                                                                                }
                                                                                                                className="form-control text-center"
                                                                                                                type="number"
                                                                                                                name="qty"
                                                                                                                min={
                                                                                                                    0
                                                                                                                }
                                                                                                            />
                                                                                                        ) : (
                                                                                                            <input
                                                                                                                value={
                                                                                                                    data.qty
                                                                                                                }
                                                                                                                onChange={(
                                                                                                                    e
                                                                                                                ) =>
                                                                                                                    changeQty(
                                                                                                                        e,
                                                                                                                        key
                                                                                                                    )
                                                                                                                }
                                                                                                                className="form-control text-center"
                                                                                                                type="number"
                                                                                                                name="qty"
                                                                                                                min={
                                                                                                                    0
                                                                                                                }
                                                                                                            />
                                                                                                        )}
                                                                                                    </td>
                                                                                                    <td className="min-wdth-100 max-wdth-100 text-center">
                                                                                                        {data.non_inventory ? (
                                                                                                            <>
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    className="form-control text-left"
                                                                                                                    name="product_unit_name"
                                                                                                                    value={
                                                                                                                        data.product_unit_name
                                                                                                                    }
                                                                                                                    step="any"
                                                                                                                    onChange={(
                                                                                                                        e
                                                                                                                    ) =>
                                                                                                                        dynamicInputChange(
                                                                                                                            e,
                                                                                                                            key
                                                                                                                        )
                                                                                                                    }
                                                                                                                    required
                                                                                                                />
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                {
                                                                                                                    data.product_unit_name
                                                                                                                }
                                                                                                            </>
                                                                                                        )}
                                                                                                    </td>
                                                                                                    <td className="mw-8m text-right">
                                                                                                        {data.non_inventory ? (
                                                                                                            <input
                                                                                                                value={
                                                                                                                    data.srp
                                                                                                                }
                                                                                                                onChange={(
                                                                                                                    e
                                                                                                                ) =>
                                                                                                                    dynamicInputChange(
                                                                                                                        e,
                                                                                                                        key
                                                                                                                    )
                                                                                                                }
                                                                                                                className="form-control text-right"
                                                                                                                type="number"
                                                                                                                name="srp"
                                                                                                                min={
                                                                                                                    0
                                                                                                                }
                                                                                                            />
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                {numberFormat(
                                                                                                                    data.srp
                                                                                                                )}
                                                                                                            </>
                                                                                                        )}
                                                                                                    </td>
                                                                                                    <td className="mw-8m text-right">
                                                                                                        {data.unit_price
                                                                                                            ? numberFormat(
                                                                                                                  data.unit_price
                                                                                                              )
                                                                                                            : numberFormat(
                                                                                                                  data.srp
                                                                                                              )}
                                                                                                    </td>
                                                                                                    <td className="min-wdth-150 max-wdth-150 text-right">
                                                                                                        {numberFormat(
                                                                                                            data.amount
                                                                                                        )}
                                                                                                    </td>
                                                                                                    <td className="text-center mw-2m">
                                                                                                        {!data.non_inventory ? (
                                                                                                            <>
                                                                                                                <span className="m-1">
                                                                                                                    <button
                                                                                                                        className="btn btn-sm btn-primary"
                                                                                                                        onClick={(
                                                                                                                            e
                                                                                                                        ) =>
                                                                                                                            handleDiscountItem(
                                                                                                                                key,
                                                                                                                                false
                                                                                                                            )
                                                                                                                        }
                                                                                                                        type="button"
                                                                                                                    >
                                                                                                                        <i className="ft ft-tag"></i>
                                                                                                                    </button>
                                                                                                                </span>

                                                                                                                <span>
                                                                                                                    <button
                                                                                                                        className="btn btn-danger btn-sm"
                                                                                                                        onClick={(
                                                                                                                            e
                                                                                                                        ) =>
                                                                                                                            remItem(
                                                                                                                                key
                                                                                                                            )
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <i className="ft-trash-2"></i>
                                                                                                                    </button>
                                                                                                                </span>
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                <span>
                                                                                                                    <button
                                                                                                                        className="btn btn-danger btn-sm"
                                                                                                                        onClick={(
                                                                                                                            e
                                                                                                                        ) =>
                                                                                                                            remItem(
                                                                                                                                key
                                                                                                                            )
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <i className="ft-trash-2"></i>
                                                                                                                    </button>
                                                                                                                </span>
                                                                                                            </>
                                                                                                        )}
                                                                                                    </td>
                                                                                                </tr>
                                                                                                {data.showDiscountRow && (
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            colSpan={
                                                                                                                1
                                                                                                            }
                                                                                                        ></td>
                                                                                                        <td
                                                                                                            className="d-flex ali justify-content-center align-items-center"
                                                                                                            style={{
                                                                                                                gap: '5%',
                                                                                                            }}
                                                                                                        >
                                                                                                            <span>
                                                                                                                Discount:
                                                                                                            </span>

                                                                                                            <span className="w-20 table-data__discount">
                                                                                                                {(() => {
                                                                                                                    switch (
                                                                                                                        data
                                                                                                                            .discount_type
                                                                                                                            .value
                                                                                                                    ) {
                                                                                                                        case 'percent':
                                                                                                                            return (
                                                                                                                                <div class="input-group">
                                                                                                                                    <input
                                                                                                                                        onChange={(
                                                                                                                                            e
                                                                                                                                        ) =>
                                                                                                                                            handleInputDiscountProduct(
                                                                                                                                                e,
                                                                                                                                                key
                                                                                                                                            )
                                                                                                                                        }
                                                                                                                                        value={
                                                                                                                                            data.discount
                                                                                                                                        }
                                                                                                                                        name="discount"
                                                                                                                                        className="form-control text-right f-regular"
                                                                                                                                        type="number"
                                                                                                                                    />
                                                                                                                                    <div class="input-group-append">
                                                                                                                                        <span className="input-group-text py-0 f-regular discount-1">
                                                                                                                                            %
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            );
                                                                                                                        case 'amount':
                                                                                                                            return (
                                                                                                                                <input
                                                                                                                                    onChange={(
                                                                                                                                        e
                                                                                                                                    ) =>
                                                                                                                                        handleInputDiscountProduct(
                                                                                                                                            e,
                                                                                                                                            key
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                    value={
                                                                                                                                        data.discount
                                                                                                                                    }
                                                                                                                                    name="discount"
                                                                                                                                    className="form-control text-right f-regular"
                                                                                                                                    type="number"
                                                                                                                                />
                                                                                                                            );
                                                                                                                        default: // 0 disabled
                                                                                                                            return (
                                                                                                                                <input
                                                                                                                                    value={
                                                                                                                                        data.discount
                                                                                                                                    }
                                                                                                                                    disabled
                                                                                                                                    name="discount"
                                                                                                                                    className="form-control text-right f-regular"
                                                                                                                                    type="number"
                                                                                                                                />
                                                                                                                            );
                                                                                                                    }
                                                                                                                })()}
                                                                                                            </span>
                                                                                                            <span className="table-data__discount-dropdown">
                                                                                                                <Select
                                                                                                                    name="discount_type"
                                                                                                                    onChange={(
                                                                                                                        selectOption
                                                                                                                    ) =>
                                                                                                                        handleSelectDiscountTypeProduct(
                                                                                                                            selectOption,
                                                                                                                            key
                                                                                                                        )
                                                                                                                    }
                                                                                                                    menuPortalTarget={
                                                                                                                        document.body
                                                                                                                    }
                                                                                                                    value={percent_value.find(
                                                                                                                        (
                                                                                                                            option
                                                                                                                        ) =>
                                                                                                                            option.value ===
                                                                                                                            data
                                                                                                                                .discount_type
                                                                                                                                .value
                                                                                                                    )}
                                                                                                                    defaultValue={percent_value.find(
                                                                                                                        (
                                                                                                                            option
                                                                                                                        ) =>
                                                                                                                            option.value ===
                                                                                                                            data
                                                                                                                                .discount_type
                                                                                                                                .value
                                                                                                                    )}
                                                                                                                    label="Single select"
                                                                                                                    options={
                                                                                                                        percent_value
                                                                                                                    }
                                                                                                                    isSearchable={
                                                                                                                        false
                                                                                                                    }
                                                                                                                />
                                                                                                            </span>
                                                                                                        </td>
                                                                                                        <td></td>
                                                                                                        <td></td>
                                                                                                        <td></td>
                                                                                                        <td></td>

                                                                                                        <td className="min-wdth-100 max-wdth-100 text-right text-danger">
                                                                                                            -{' '}
                                                                                                            {numberFormat(
                                                                                                                Number(
                                                                                                                    data.discount_amount
                                                                                                                ).toFixed(
                                                                                                                    2
                                                                                                                )
                                                                                                            )}
                                                                                                        </td>

                                                                                                        <td
                                                                                                            align="center"
                                                                                                            className="mw-2m"
                                                                                                        >
                                                                                                            <button
                                                                                                                className="btn btn-sm btn-danger"
                                                                                                                onClick={(
                                                                                                                    e
                                                                                                                ) =>
                                                                                                                    handleDiscountItem(
                                                                                                                        key,
                                                                                                                        true
                                                                                                                    )
                                                                                                                }
                                                                                                                type="button"
                                                                                                            >
                                                                                                                <i className="ft ft-trash"></i>
                                                                                                            </button>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                )}
                                                                                            </Fragment>
                                                                                        );
                                                                                    }
                                                                                )
                                                                            )
                                                                        ) : (
                                                                            si_data.items.map(
                                                                                (
                                                                                    data,
                                                                                    key
                                                                                ) => {
                                                                                    return (
                                                                                        <Fragment
                                                                                            key={
                                                                                                key
                                                                                            }
                                                                                        >
                                                                                            {' '}
                                                                                            {/* This is the key part */}
                                                                                            <tr
                                                                                                className={stockClass(
                                                                                                    data.status_code
                                                                                                )}
                                                                                            >
                                                                                                {/* <tr
                                            key={key}
                                            className={stockClass(
                                              data.status_code
                                            )}
                                          > */}
                                                                                                <td className="table-data__sku ">
                                                                                                    {
                                                                                                        data.sku
                                                                                                    }
                                                                                                </td>
                                                                                                <td className="min-wdth-300 max-wdth-300">
                                                                                                    {data.new ? (
                                                                                                        <div>
                                                                                                            <div className="mw-90">
                                                                                                                <Link
                                                                                                                    to={`/inventory/view/${data._id}`}
                                                                                                                    target="_blank"
                                                                                                                    onClick={(
                                                                                                                        e
                                                                                                                    ) =>
                                                                                                                        e.stopPropagation()
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        data.product_name
                                                                                                                    }
                                                                                                                </Link>
                                                                                                            </div>
                                                                                                            <div className="f-12 text-dark">
                                                                                                                {
                                                                                                                    data.sku
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className="f-12 text-dark">
                                                                                                                {
                                                                                                                    data.description
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className="f-12 text-dark">
                                                                                                                {
                                                                                                                    data.brand
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    ) : data.selected ? (
                                                                                                        <div
                                                                                                            onClick={() =>
                                                                                                                ShowComboBox(
                                                                                                                    key
                                                                                                                )
                                                                                                            }
                                                                                                            className={
                                                                                                                key <
                                                                                                                si_data.lastAddedDropdownIndex
                                                                                                                    ? 'unclickable'
                                                                                                                    : 'c-pointer'
                                                                                                            }
                                                                                                        >
                                                                                                            <div className="f-right">
                                                                                                                {/* <i className='ft ft-chevron-down c-pointer'></i> */}
                                                                                                            </div>
                                                                                                            <div className="mw-90">
                                                                                                                {
                                                                                                                    data.product_name
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className="f-12 text-dark">
                                                                                                                {
                                                                                                                    data.sku
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className="f-12 text-dark">
                                                                                                                {
                                                                                                                    data.description
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className="f-12 text-dark">
                                                                                                                {
                                                                                                                    data.brand
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    ) : (
                                                                                                        <AsyncSelect
                                                                                                            value={
                                                                                                                data.product_value
                                                                                                            }
                                                                                                            isLoading={
                                                                                                                loading
                                                                                                            }
                                                                                                            components={{
                                                                                                                Option,
                                                                                                            }}
                                                                                                            classNamePrefix="lp-copy-sel"
                                                                                                            loadingMessage={() =>
                                                                                                                'searching...'
                                                                                                            }
                                                                                                            noOptionsMessage={({
                                                                                                                inputValue,
                                                                                                            }) =>
                                                                                                                inputValue
                                                                                                                    ? 'No Products'
                                                                                                                    : 'Search product'
                                                                                                            }
                                                                                                            defaultOptions={
                                                                                                                data.items
                                                                                                            }
                                                                                                            loadOptions={debounce(
                                                                                                                (
                                                                                                                    value
                                                                                                                ) =>
                                                                                                                    searchProducts(
                                                                                                                        value,
                                                                                                                        key
                                                                                                                    ),
                                                                                                                1000
                                                                                                            )}
                                                                                                            isSearchable={
                                                                                                                true
                                                                                                            }
                                                                                                            cacheOptions
                                                                                                            onChange={(
                                                                                                                val
                                                                                                            ) =>
                                                                                                                handleSelectItem(
                                                                                                                    val,
                                                                                                                    key
                                                                                                                )
                                                                                                            }
                                                                                                            placeholder="Select Products..."
                                                                                                            required
                                                                                                        />
                                                                                                    )}

                                                                                                    {/* <Select components={{Option}} filterOption={customFilter} classNamePrefix="lp-copy-sel" options={items} onChange={(value) => handleSelectItem(value, key)} isSearchable={isSearchable} value={data.product_value}/> */}
                                                                                                </td>
                                                                                                <td
                                                                                                    className="mw-6m text-center"
                                                                                                    align="center"
                                                                                                >
                                                                                                    <input
                                                                                                        value={
                                                                                                            data.qty
                                                                                                        }
                                                                                                        onChange={(
                                                                                                            e
                                                                                                        ) =>
                                                                                                            changeQty(
                                                                                                                e,
                                                                                                                key
                                                                                                            )
                                                                                                        }
                                                                                                        className="form-control text-center"
                                                                                                        type="number"
                                                                                                        name="qty"
                                                                                                        min={
                                                                                                            0
                                                                                                        }
                                                                                                    />
                                                                                                </td>
                                                                                                <td className="min-wdth-100 max-wdth-100 text-center">
                                                                                                    {
                                                                                                        data.product_unit_name
                                                                                                    }
                                                                                                </td>
                                                                                                <td className="mw-8m text-right">
                                                                                                    {numberFormat(
                                                                                                        data.srp
                                                                                                    )}
                                                                                                </td>
                                                                                                <td className="mw-8m text-right">
                                                                                                    {data.unit_price
                                                                                                        ? numberFormat(
                                                                                                              data.unit_price
                                                                                                          )
                                                                                                        : numberFormat(
                                                                                                              data.srp
                                                                                                          )}
                                                                                                </td>
                                                                                                <td className="min-wdth-150 max-wdth-150 text-right">
                                                                                                    {numberFormat(
                                                                                                        data.amount
                                                                                                    )}
                                                                                                </td>
                                                                                                <td className="text-center mw-2m">
                                                                                                    <span className="m-1">
                                                                                                        <button
                                                                                                            className="btn btn-sm btn-primary"
                                                                                                            onClick={(
                                                                                                                e
                                                                                                            ) =>
                                                                                                                handleDiscountItem(
                                                                                                                    key,
                                                                                                                    false
                                                                                                                )
                                                                                                            }
                                                                                                            type="button"
                                                                                                        >
                                                                                                            <i className="ft ft-tag"></i>
                                                                                                        </button>
                                                                                                    </span>

                                                                                                    <span>
                                                                                                        <button
                                                                                                            className="btn btn-danger btn-sm"
                                                                                                            onClick={(
                                                                                                                e
                                                                                                            ) =>
                                                                                                                remItem(
                                                                                                                    e,
                                                                                                                    key
                                                                                                                )
                                                                                                            }
                                                                                                        >
                                                                                                            <i className="ft-trash-2"></i>
                                                                                                        </button>
                                                                                                    </span>
                                                                                                </td>
                                                                                            </tr>
                                                                                            {(data.discount_type &&
                                                                                                data
                                                                                                    .discount_type
                                                                                                    .value !==
                                                                                                    'none') ||
                                                                                            data.showDiscountRow ? (
                                                                                                <tr>
                                                                                                    <td
                                                                                                        colSpan={
                                                                                                            1
                                                                                                        }
                                                                                                    ></td>
                                                                                                    <td
                                                                                                        className="d-flex ali justify-content-center align-items-center"
                                                                                                        style={{
                                                                                                            gap: '5%',
                                                                                                        }}
                                                                                                    >
                                                                                                        <span>
                                                                                                            Discount:
                                                                                                        </span>

                                                                                                        <span className="w-20 table-data__discount">
                                                                                                            {(() => {
                                                                                                                switch (
                                                                                                                    data.discount_type
                                                                                                                        ? data
                                                                                                                              .discount_type
                                                                                                                              .value
                                                                                                                        : null
                                                                                                                ) {
                                                                                                                    case 'percent':
                                                                                                                        return (
                                                                                                                            <div class="input-group">
                                                                                                                                <input
                                                                                                                                    onChange={(
                                                                                                                                        e
                                                                                                                                    ) =>
                                                                                                                                        handleInputDiscountProduct(
                                                                                                                                            e,
                                                                                                                                            key
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                    value={
                                                                                                                                        data.discount
                                                                                                                                    }
                                                                                                                                    name="discount"
                                                                                                                                    className="form-control text-right f-regular"
                                                                                                                                    type="number"
                                                                                                                                />
                                                                                                                                <div class="input-group-append">
                                                                                                                                    <span className="input-group-text py-0 f-regular discount-1">
                                                                                                                                        %
                                                                                                                                    </span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        );
                                                                                                                    case 'amount':
                                                                                                                        return (
                                                                                                                            <input
                                                                                                                                onChange={(
                                                                                                                                    e
                                                                                                                                ) =>
                                                                                                                                    handleInputDiscountProduct(
                                                                                                                                        e,
                                                                                                                                        key
                                                                                                                                    )
                                                                                                                                }
                                                                                                                                value={
                                                                                                                                    data.discount
                                                                                                                                }
                                                                                                                                name="discount"
                                                                                                                                className="form-control text-right f-regular"
                                                                                                                                type="number"
                                                                                                                            />
                                                                                                                        );
                                                                                                                    default: // 0 disabled
                                                                                                                        return (
                                                                                                                            <input
                                                                                                                                value={
                                                                                                                                    data.discount
                                                                                                                                }
                                                                                                                                disabled
                                                                                                                                name="discount"
                                                                                                                                className="form-control text-right f-regular"
                                                                                                                                type="number"
                                                                                                                            />
                                                                                                                        );
                                                                                                                }
                                                                                                            })()}
                                                                                                        </span>
                                                                                                        <span className="table-data__discount-dropdown">
                                                                                                            <Select
                                                                                                                name="discount_type"
                                                                                                                onChange={(
                                                                                                                    selectOption
                                                                                                                ) =>
                                                                                                                    handleSelectDiscountTypeProduct(
                                                                                                                        selectOption,
                                                                                                                        key
                                                                                                                    )
                                                                                                                }
                                                                                                                menuPortalTarget={
                                                                                                                    document.body
                                                                                                                }
                                                                                                                value={percent_value.find(
                                                                                                                    (
                                                                                                                        option
                                                                                                                    ) =>
                                                                                                                        option.value ===
                                                                                                                        data
                                                                                                                            .discount_type
                                                                                                                            .value
                                                                                                                )}
                                                                                                                defaultValue={percent_value.find(
                                                                                                                    (
                                                                                                                        option
                                                                                                                    ) =>
                                                                                                                        option.value ===
                                                                                                                        data
                                                                                                                            .discount_type
                                                                                                                            .value
                                                                                                                )}
                                                                                                                label="Single select"
                                                                                                                options={
                                                                                                                    percent_value
                                                                                                                }
                                                                                                                isSearchable={
                                                                                                                    false
                                                                                                                }
                                                                                                            />
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td></td>
                                                                                                    <td></td>
                                                                                                    <td></td>
                                                                                                    <td></td>

                                                                                                    <td className="min-wdth-100 max-wdth-100 text-right text-danger">
                                                                                                        -{' '}
                                                                                                        {numberFormat(
                                                                                                            Number(
                                                                                                                data.discount_amount
                                                                                                            ).toFixed(
                                                                                                                2
                                                                                                            )
                                                                                                        )}
                                                                                                    </td>

                                                                                                    <td
                                                                                                        align="center"
                                                                                                        className="mw-2m"
                                                                                                    >
                                                                                                        <button
                                                                                                            className="btn btn-sm btn-danger"
                                                                                                            onClick={(
                                                                                                                e
                                                                                                            ) =>
                                                                                                                handleDiscountItem(
                                                                                                                    key,
                                                                                                                    true
                                                                                                                )
                                                                                                            }
                                                                                                            type="button"
                                                                                                        >
                                                                                                            <i className="ft ft-trash"></i>
                                                                                                        </button>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ) : null}
                                                                                        </Fragment>
                                                                                    );
                                                                                }
                                                                            )
                                                                        )
                                                                        // (
                                                                        //   si_data.items.map((data, key) => {
                                                                        //     // console.log(data)
                                                                        //     return (
                                                                        //       <tr
                                                                        //         key={key}
                                                                        //         className={stockClass(data.status_code)}
                                                                        //       >
                                                                        //         <td>
                                                                        //           <DynamicFont content={data.sku} />
                                                                        //         </td>
                                                                        //         <td>
                                                                        //           <div>
                                                                        //             <div className='mw-90'>
                                                                        //               {data.product_name}
                                                                        //             </div>
                                                                        //             <div className='f-12 text-dark'>
                                                                        //               {data.sku}
                                                                        //             </div>
                                                                        //             <div className='f-12 text-dark'>
                                                                        //               {data.description}
                                                                        //             </div>
                                                                        //           </div>
                                                                        //         </td>
                                                                        //         <td className='text-right text-center'>
                                                                        //           {data.qty}
                                                                        //         </td>
                                                                        //         <td className='text-right text-center'>
                                                                        //           {data.product_unit_name}
                                                                        //         </td>
                                                                        //         <td className='text-right'>
                                                                        //           {numberFormat(data.srp)}
                                                                        //         </td>
                                                                        //         <td className='text-right'>
                                                                        //           {numberFormat(data.unit_price)}
                                                                        //         </td>
                                                                        //         <td className='text-right'>
                                                                        //           {numberFormat(data.amount)}
                                                                        //         </td>
                                                                        //       </tr>
                                                                        //     );
                                                                        //   })
                                                                        // )
                                                                    }
                                                                    {si_data.is_selected ? (
                                                                        si_data.customer ===
                                                                        '' ? (
                                                                            <>

                                                                            </>
                                                                        ) : (
                                                                            <tr>
                                                                                <td
                                                                                    colSpan="8"
                                                                                    className="text-left"
                                                                                >
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-primary"
                                                                                        onClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            addItem(
                                                                                                e
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <i className="la la-plus"></i>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    ) : si_data.customer ===
                                                                      '' ? (
                                                                        <></>
                                                                    ) : (
                                                                        <tr>
                                                                            <td
                                                                                colSpan="8"
                                                                                className="text-left"
                                                                            >
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-primary"
                                                                                    onClick={(
                                                                                        e
                                                                                    ) =>
                                                                                        addItem(
                                                                                            e
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <i className="la la-plus"></i>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    )}

                                                                    {si_data.is_selected ? (
                                                                        si_data.customer ===
                                                                        '' ? (
                                                                            <>

                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <tr>
                                                                                    <td colSpan="5"></td>
                                                                                    <td className="text-right">
                                                                                        Sub
                                                                                        Total
                                                                                    </td>
                                                                                    <td className="text-right">
                                                                                        {numberFormat(
                                                                                            si_data.sub_total
                                                                                        )}
                                                                                    </td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td
                                                                                        colSpan={
                                                                                            5
                                                                                        }
                                                                                    ></td>
                                                                                    <td>
                                                                                        <div align="right">
                                                                                            Discount
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        {(() => {
                                                                                            switch (
                                                                                                si_data
                                                                                                    .discount_type
                                                                                                    .value
                                                                                            ) {
                                                                                                case 'percent':
                                                                                                    return (
                                                                                                        <div class="input-group">
                                                                                                            <input
                                                                                                                onChange={
                                                                                                                    handleInputChangeDiscount
                                                                                                                }
                                                                                                                value={
                                                                                                                    si_data.discount
                                                                                                                }
                                                                                                                name="discount"
                                                                                                                className="form-control text-right f-regular"
                                                                                                                type="number"
                                                                                                            />
                                                                                                            <div class="input-group-append">
                                                                                                                <span className="input-group-text py-0 f-regular discount-1">
                                                                                                                    %
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    );
                                                                                                case 'amount':
                                                                                                    return (
                                                                                                        <input
                                                                                                            onChange={
                                                                                                                handleInputChangeDiscount
                                                                                                            }
                                                                                                            value={
                                                                                                                si_data.discount
                                                                                                            }
                                                                                                            name="discount"
                                                                                                            className="form-control text-right f-regular"
                                                                                                            type="number"
                                                                                                        />
                                                                                                    );
                                                                                                default: // 0 disabled
                                                                                                    return (
                                                                                                        <input
                                                                                                            value={
                                                                                                                si_data.discount
                                                                                                            }
                                                                                                            disabled
                                                                                                            name="discount"
                                                                                                            className="form-control text-right f-regular"
                                                                                                            type="number"
                                                                                                        />
                                                                                                    );
                                                                                            }
                                                                                        })()}
                                                                                    </td>
                                                                                    <td
                                                                                        width="10%"
                                                                                        className="text-center padding-td-5"
                                                                                    >
                                                                                        <Select
                                                                                            name="discount_type"
                                                                                            onChange={
                                                                                                handleSelectDiscountType
                                                                                            }
                                                                                            menuPortalTarget={
                                                                                                document.body
                                                                                            }
                                                                                            value={percent_value.find(
                                                                                                (
                                                                                                    option
                                                                                                ) =>
                                                                                                    option.value ===
                                                                                                    si_data
                                                                                                        .discount_type
                                                                                                        .value
                                                                                            )}
                                                                                            defaultValue={percent_value.find(
                                                                                                (
                                                                                                    option
                                                                                                ) =>
                                                                                                    option.value ===
                                                                                                    si_data
                                                                                                        .discount_type
                                                                                                        .value
                                                                                            )}
                                                                                            label="Single select"
                                                                                            options={
                                                                                                percent_value
                                                                                            }
                                                                                            isSearchable={
                                                                                                false
                                                                                            }
                                                                                        />
                                                                                    </td>
                                                                                </tr>

                                                                                {/* <tr>
                                          <td colSpan={5}></td>
                                          <td>
                                            <div align='right'>
                                              VAT ({si_data.tax} %)
                                            </div>
                                          </td>
                                          <td align='right'>
                                            {numberFormat(
                                              si_data.vat_amount.toFixed(2)
                                            )}
                                          </td>
                                          <td className='padding-td-5 min-width-150'>
                                            <Select
                                              name='tax_type'
                                              onChange={handleTaxType}
                                              value={si_data.tax_type}
                                              menuPortalTarget={document.body}
                                              options={tax_types}
                                              isSearchable={false}
                                            />
                                          </td>
                                        </tr> */}

                                                                                <tr>
                                                                                    <td
                                                                                        colSpan={
                                                                                            5
                                                                                        }
                                                                                    ></td>
                                                                                    <td>
                                                                                        <div align="right">
                                                                                            VAT
                                                                                            (
                                                                                            {
                                                                                                si_data.tax
                                                                                            }{' '}
                                                                                            %)
                                                                                        </div>
                                                                                    </td>
                                                                                    <td align="right">
                                                                                        {numberFormat(
                                                                                            si_data.vat_amount.toFixed(
                                                                                                2
                                                                                            )
                                                                                        )}
                                                                                    </td>
                                                                                    <td className="padding-td-5 min-width-150">
                                                                                        <Select
                                                                                            name="tax_type"
                                                                                            onChange={
                                                                                                handleTaxType
                                                                                            }
                                                                                            value={
                                                                                                si_data.tax_type
                                                                                            }
                                                                                            menuPortalTarget={
                                                                                                document.body
                                                                                            }
                                                                                            options={
                                                                                                tax_types
                                                                                            }
                                                                                            isSearchable={
                                                                                                false
                                                                                            }
                                                                                        />
                                                                                    </td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td colSpan="5"></td>
                                                                                    <td className="text-right">
                                                                                        Delivery
                                                                                        Fee
                                                                                    </td>
                                                                                    <td className="text-right">
                                                                                        <input
                                                                                            type="number"
                                                                                            className="form-control f-regular text-right"
                                                                                            value={
                                                                                                si_data.delivery_fee
                                                                                            }
                                                                                            onChange={
                                                                                                deliveryFee
                                                                                            }
                                                                                        />
                                                                                    </td>
                                                                                    <td></td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td></td>
                                                                                    <td className="text-right"></td>
                                                                                    <td className="text-right"></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td className="text-right">
                                                                                        <strong>
                                                                                            Total
                                                                                        </strong>
                                                                                    </td>
                                                                                    <td className="text-right">
                                                                                        {numberFormat(
                                                                                            si_data.total
                                                                                        )}
                                                                                    </td>
                                                                                    {si_data.is_selected ? (
                                                                                        <td></td>
                                                                                    ) : (
                                                                                        <td></td>
                                                                                    )}
                                                                                </tr>
                                                                            </>
                                                                        )
                                                                    ) : si_data.customer ===
                                                                      '' ? (
                                                                        <></>
                                                                    ) : (
                                                                        <>
                                                                            <tr>
                                                                                <td colSpan="5"></td>
                                                                                <td className="text-right">
                                                                                    Sub
                                                                                    Total
                                                                                </td>
                                                                                <td className="text-right">
                                                                                    {numberFormat(
                                                                                        si_data.sub_total
                                                                                    )}
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td
                                                                                    colSpan={
                                                                                        5
                                                                                    }
                                                                                ></td>
                                                                                <td>
                                                                                    <div align="right">
                                                                                        Discount
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    {(() => {
                                                                                        switch (
                                                                                            si_data
                                                                                                .discount_type
                                                                                                .value
                                                                                        ) {
                                                                                            case 'percent':
                                                                                                return (
                                                                                                    <div class="input-group">
                                                                                                        <input
                                                                                                            onChange={
                                                                                                                handleInputChangeDiscount
                                                                                                            }
                                                                                                            value={
                                                                                                                si_data.discount
                                                                                                            }
                                                                                                            name="discount"
                                                                                                            className="form-control text-right f-regular"
                                                                                                            type="number"
                                                                                                        />
                                                                                                        <div class="input-group-append">
                                                                                                            <span className="input-group-text py-0 f-regular discount-1">
                                                                                                                %
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                );
                                                                                            case 'amount':
                                                                                                return (
                                                                                                    <input
                                                                                                        onChange={
                                                                                                            handleInputChangeDiscount
                                                                                                        }
                                                                                                        value={
                                                                                                            si_data.discount
                                                                                                        }
                                                                                                        name="discount"
                                                                                                        className="form-control text-right f-regular"
                                                                                                        type="number"
                                                                                                    />
                                                                                                );
                                                                                            default: // 0 disabled
                                                                                                return (
                                                                                                    <input
                                                                                                        value={
                                                                                                            si_data.discount
                                                                                                        }
                                                                                                        disabled
                                                                                                        name="discount"
                                                                                                        className="form-control text-right f-regular"
                                                                                                        type="number"
                                                                                                    />
                                                                                                );
                                                                                        }
                                                                                    })()}
                                                                                </td>
                                                                                <td
                                                                                    width="10%"
                                                                                    className="text-center padding-td-5"
                                                                                >
                                                                                    <Select
                                                                                        name="discount_type"
                                                                                        onChange={
                                                                                            handleSelectDiscountType
                                                                                        }
                                                                                        menuPortalTarget={
                                                                                            document.body
                                                                                        }
                                                                                        value={percent_value.find(
                                                                                            (
                                                                                                option
                                                                                            ) =>
                                                                                                option.value ===
                                                                                                si_data
                                                                                                    .discount_type
                                                                                                    .value
                                                                                        )}
                                                                                        defaultValue={percent_value.find(
                                                                                            (
                                                                                                option
                                                                                            ) =>
                                                                                                option.value ===
                                                                                                si_data
                                                                                                    .discount_type
                                                                                                    .value
                                                                                        )}
                                                                                        label="Single select"
                                                                                        options={
                                                                                            percent_value
                                                                                        }
                                                                                        isSearchable={
                                                                                            false
                                                                                        }
                                                                                    />
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td
                                                                                    colSpan={
                                                                                        5
                                                                                    }
                                                                                ></td>
                                                                                <td>
                                                                                    <div align="right">
                                                                                        VAT
                                                                                        (
                                                                                        {
                                                                                            si_data.tax
                                                                                        }{' '}
                                                                                        %)
                                                                                    </div>
                                                                                </td>
                                                                                <td align="right">
                                                                                    {numberFormat(
                                                                                        si_data.vat_amount.toFixed(
                                                                                            2
                                                                                        )
                                                                                    )}
                                                                                </td>
                                                                                <td className="padding-td-5 min-width-150">
                                                                                    <Select
                                                                                        name="tax_type"
                                                                                        onChange={
                                                                                            handleTaxType
                                                                                        }
                                                                                        value={
                                                                                            si_data.tax_type
                                                                                        }
                                                                                        menuPortalTarget={
                                                                                            document.body
                                                                                        }
                                                                                        options={
                                                                                            tax_types
                                                                                        }
                                                                                        isSearchable={
                                                                                            false
                                                                                        }
                                                                                    />
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td colSpan="5"></td>
                                                                                <td className="text-right">
                                                                                    Delivery
                                                                                    Fee
                                                                                </td>
                                                                                <td className="text-right">
                                                                                    <input
                                                                                        type="number"
                                                                                        className="form-control f-regular text-right"
                                                                                        value={
                                                                                            si_data.delivery_fee
                                                                                        }
                                                                                        onChange={
                                                                                            deliveryFee
                                                                                        }
                                                                                    />
                                                                                </td>
                                                                                <td></td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td></td>
                                                                                <td className="text-right"></td>
                                                                                <td className="text-right"></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td className="text-right">
                                                                                    <strong>
                                                                                        Total
                                                                                    </strong>
                                                                                </td>
                                                                                <td className="text-right">
                                                                                    {numberFormat(
                                                                                        si_data.total
                                                                                    )}
                                                                                </td>
                                                                                {si_data.is_selected ? (
                                                                                    <td></td>
                                                                                ) : (
                                                                                    <td></td>
                                                                                )}
                                                                            </tr>
                                                                        </>
                                                                    )}

                                                                    {/* <tr>
                                    <td colSpan='5'></td>
                                    <td className='text-right'>Sub Total</td>
                                    <td className='text-right'>
                                      {numberFormat(si_data.sub_total)}
                                    </td>
                                    {si_data.is_selected ? <td></td> :  <td></td>}
                                  </tr> */}
                                                                    {/* {si_data.is_selected ? ( */}
                                                                    {/* <tr>
                                      <td colSpan={5}></td>
                                      <td>
                                        <div align='right'>Discount</div>
                                      </td>
                                      <td>
                                        {(() => {
                                          switch (si_data.discount_type.value) {
                                            case "percent":
                                              return (
                                                <div class='input-group'>
                                                  <input
                                                    onChange={
                                                      handleInputChangeDiscount
                                                    }
                                                    value={si_data.discount}
                                                    name='discount'
                                                    className='form-control text-right f-regular'
                                                    type='number'
                                                  />
                                                  <div class='input-group-append'>
                                                    <span className='input-group-text py-0 f-regular discount-1'>
                                                      %
                                                    </span>
                                                  </div>
                                                </div>
                                              );
                                            case "amount":
                                              return (
                                                <input
                                                  onChange={
                                                    handleInputChangeDiscount
                                                  }
                                                  value={si_data.discount}
                                                  name='discount'
                                                  className='form-control text-right f-regular'
                                                  type='number'
                                                />
                                              );
                                            default: // 0 disabled
                                              return (
                                                <input
                                                  value={si_data.discount}
                                                  disabled
                                                  name='discount'
                                                  className='form-control text-right f-regular'
                                                  type='number'
                                                />
                                              );
                                          }
                                        })()}
                                      </td>
                                      <td
                                        width='10%'
                                        className='text-center padding-td-5'
                                      >
                                        <Select
                                              name='discount_type'
                                              onChange={handleSelectDiscountType}
                                              menuPortalTarget={document.body}
                                              value={
                                                percent_value.find(
                                                  (option) => option.value === si_data.discount_type.value
                                                )
                                              }
                                              defaultValue={
                                                percent_value.find(
                                                  (option) => option.value === si_data.discount_type.value
                                                )
                                              }
                                              label='Single select'
                                              options={percent_value}
                                              isSearchable={false}
                                            />
                                      </td>
                                    </tr> */}
                                                                    {/* ) : (
                                    <tr>
                                      <td colSpan='5'></td>
                                      <td className='text-right'>Discount</td>
                                      <td className='text-right'>
                                        {numberFormat(si_data.discount)}
                                      </td>
                                    </tr>
                                  )} */}
                                                                    {/* {si_data.is_selected ? ( */}
                                                                    {/* <tr>
                                      <td colSpan={5}></td>
                                      <td>
                                        <div align='right'>
                                          VAT ({si_data.tax} %)
                                        </div>
                                      </td>
                                      <td align='right'>
                                        {numberFormat(
                                          si_data.vat_amount.toFixed(2)
                                        )}
                                      </td>
                                      <td className='padding-td-5 min-width-150'>
                                        <Select
                                          name='tax_type'
                                          onChange={handleTaxType}
                                          value={si_data.tax_type}
                                          menuPortalTarget={document.body}
                                          options={tax_types}
                                          isSearchable={false}
                                        />
                                      </td>
                                    </tr> */}
                                                                    {/* ) : (
                                    <tr>
                                      <td colSpan={5}></td>
                                      <td>
                                        <div align='right'>
                                          <span className='f-16'>
                                            {si_data.tax_type.value === "none"
                                              ? null
                                              : si_data.tax_type.value + ":"}
                                          </span>{" "}
                                          VAT ({si_data.tax} %)
                                        </div>
                                      </td>
                                      <td align='right'>
                                        {numberFormat(
                                          si_data.vat_amount.toFixed(2)
                                        )}
                                      </td>
                                    </tr>
                                  )} */}

                                                                    {/* <tr>
                                                                  <td colSpan="5"></td>
                                                                  <td className="text-right">{si_data.tax_type.value === 'none' ? null : si_data.tax_type.value} VAT ({si_data.tax} %)</td>
                                                                  <td className="text-right">{numberFormat(si_data.vat_amount)}</td>
                                                                  {
                                                                      si_data.is_selected ? 
                                                                      <td className="text-center">
                                                                          <input type="checkbox" onChange={(e) => vat_change(e)}/>
                                                                      </td>
                                                                      :
                                                                      <section></section>
                                                                  }
                                                              </tr> */}

                                                                    {/* <tr>
                                      <td colSpan='5'></td>
                                      <td className='text-right'>Delivery Fee</td>
                                      <td className='text-right'>
                                        <input
                                          type='number'
                                          className='form-control f-regular text-right'
                                          value={si_data.delivery_fee}
                                          onChange={deliveryFee}
                                        />
                                      </td>
                                      <td></td>
                                    </tr>
                            
                                  <tr>
                                    <td></td>
                                    <td className='text-right'></td>
                                    <td className='text-right'></td>
                                    <td></td>
                                    <td></td>
                                    <td className='text-right'>
                                      <strong>Total</strong>
                                    </td>
                                    <td className='text-right'>
                                      {numberFormat(si_data.total)}
                                    </td>
                                    {si_data.manuais_selectedl_input ? <td></td> :  <td></td> }
                                  </tr> */}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        {!si_data.is_selected ? (
                                                            <div className="form-group">
                                                                <label>
                                                                    Remarks
                                                                </label>
                                                                <textarea
                                                                    className="form-control f-regular"
                                                                    value={
                                                                        si_data.remarks
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        remarksInput(
                                                                            e
                                                                        )
                                                                    } //new
                                                                    //disabled //old
                                                                ></textarea>
                                                            </div>
                                                        ) : (
                                                            <div className="form-group">
                                                                <label>
                                                                    Remarks from
                                                                    Sales
                                                                    Invoice
                                                                </label>
                                                                <textarea
                                                                    className="form-control f-regular"
                                                                    value={
                                                                        si_data.remarks
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        remarksInput(
                                                                            e
                                                                        )
                                                                    }
                                                                ></textarea>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>
                                                                Payment Mode:
                                                            </label>
                                                            <div className="input-group input-group-full">
                                                                {
                                                                    si_data.is_selected ? (
                                                                        <Select
                                                                            options={
                                                                                payment_mode_options
                                                                            }
                                                                            onChange={
                                                                                handleSelectPaymentType
                                                                            }
                                                                            value={
                                                                                si_data.payment_mode
                                                                            }
                                                                            isSearchable={
                                                                                isSearchable
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <Select
                                                                            options={
                                                                                payment_mode_options
                                                                            }
                                                                            onChange={
                                                                                handleSelectPaymentType
                                                                            }
                                                                            value={
                                                                                si_data.payment_mode
                                                                            }
                                                                            isSearchable={
                                                                                isSearchable
                                                                            }
                                                                        />
                                                                    )
                                                                    // (
                                                                    //   <div className='form-control f-regular'>
                                                                    //     {si_data.payment_mode.label}
                                                                    //   </div>
                                                                    // )
                                                                }

                                                                <div className="input-group-prepend">
                                                                    <span
                                                                        href="#"
                                                                        className="tool-tip text-primary"
                                                                        data-tip="this is a tool tip"
                                                                    >
                                                                        <i className="la la-question-circle"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>
                                                                Payment Terms:{' '}
                                                            </label>
                                                            {
                                                                si_data.is_selected ? (
                                                                    <section>
                                                                        <div className="input-group input-group-full">
                                                                            {terms_conditions.length >
                                                                                0 && (
                                                                                <Select
                                                                                    options={
                                                                                        terms_conditions
                                                                                    }
                                                                                    onChange={
                                                                                        handleSelectTermsConditions
                                                                                    }
                                                                                    value={
                                                                                        si_data.payment_terms
                                                                                    }
                                                                                    isSearchable={
                                                                                        isSearchable
                                                                                    }
                                                                                />
                                                                            )}
                                                                            <div className="input-group-prepend">
                                                                                <span
                                                                                    href="#"
                                                                                    className="tool-tip text-primary"
                                                                                    data-tip="this is a tool tip"
                                                                                >
                                                                                    <i className="la la-question-circle"></i>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        {terms_conditions.length ==
                                                                            0 && (
                                                                            <div>
                                                                                {' '}
                                                                                No
                                                                                payment_terms
                                                                                please
                                                                                add{' '}
                                                                                <a href="/setting/term-condition">
                                                                                    here
                                                                                </a>
                                                                            </div>
                                                                        )}
                                                                    </section>
                                                                ) : (
                                                                    <section>
                                                                        <div className="input-group input-group-full">
                                                                            {terms_conditions.length >
                                                                                0 && (
                                                                                <Select
                                                                                    options={
                                                                                        terms_conditions
                                                                                    }
                                                                                    onChange={
                                                                                        handleSelectTermsConditions
                                                                                    }
                                                                                    value={
                                                                                        si_data.payment_terms
                                                                                    }
                                                                                    isSearchable={
                                                                                        isSearchable
                                                                                    }
                                                                                />
                                                                            )}
                                                                            <div className="input-group-prepend">
                                                                                <span
                                                                                    href="#"
                                                                                    className="tool-tip text-primary"
                                                                                    data-tip="this is a tool tip"
                                                                                >
                                                                                    <i className="la la-question-circle"></i>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        {terms_conditions.length ==
                                                                            0 && (
                                                                            <div>
                                                                                {' '}
                                                                                No
                                                                                payment_terms
                                                                                please
                                                                                add{' '}
                                                                                <a href="/setting/term-condition">
                                                                                    here
                                                                                </a>
                                                                            </div>
                                                                        )}
                                                                    </section>
                                                                )
                                                                //(
                                                                //   <section>
                                                                //     <div className='input-group input-group-full'>
                                                                //       <div className='form-control f-regular'>
                                                                //         {si_data.payment_terms.label}
                                                                //       </div>
                                                                //       <div className='input-group-prepend'>
                                                                //         <span
                                                                //           href='#'
                                                                //           className='tool-tip text-primary'
                                                                //           data-tip='this is a tool tip'
                                                                //         >
                                                                //           <i className='la la-question-circle'></i>
                                                                //         </span>
                                                                //       </div>
                                                                //     </div>
                                                                //   </section>
                                                                // )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>
                                                                Receipt Type{' '}
                                                                <span className="text-danger">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <div className="input-group input-group-full">
                                                                {
                                                                    si_data.is_selected ? (
                                                                        <Select
                                                                            options={
                                                                                receipt_type_options
                                                                            }
                                                                            onChange={
                                                                                handleSelectReceiptType
                                                                            }
                                                                            value={
                                                                                si_data.receipt_type
                                                                            }
                                                                            isSearchable={
                                                                                isSearchable
                                                                            }
                                                                            required
                                                                        />
                                                                    ) : (
                                                                        <Select
                                                                            options={
                                                                                receipt_type_options
                                                                            }
                                                                            onChange={
                                                                                handleSelectReceiptType
                                                                            }
                                                                            value={
                                                                                si_data.receipt_type
                                                                            }
                                                                            isSearchable={
                                                                                isSearchable
                                                                            }
                                                                            required
                                                                        />
                                                                    )
                                                                    // (
                                                                    //   <div className='form-control f-regular'>
                                                                    //     {si_data.receipt_type.label}
                                                                    //   </div>
                                                                    // )
                                                                }

                                                                <div className="input-group-prepend">
                                                                    <span
                                                                        href="#"
                                                                        className="tool-tip text-primary"
                                                                        data-tip="this is a tool tip"
                                                                    >
                                                                        <i className="la la-question-circle"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="card-footer">
                                        <div className="row justify-content-end mt-3">
                                            <div className="mr-2">
                                                <div className="form-group">
                                                    {/* <Show when="inv.delivery.receipts.draft"><button className="btn btn-outline-light text-dark " type="submit" onClick={() => submitClick('draft')}>Draft</button></Show>
                                                <button className="btn btn-primary text-white margin-l-5" type="submit" onClick={() => submitClick('saved')} disabled={enable_submit}>Save</button> */}
                                                    <Show when="inv.receiving.itemReceipts.draft">
                                                        <div className="btn-group btn-dpd">
                                                            <button
                                                                className="btn btn-default btn-outline-light text-dark btn-main"
                                                                onClick={() =>
                                                                    submitClick(
                                                                        'draft'
                                                                    )
                                                                }
                                                                type="button"
                                                                disabled={
                                                                    loading
                                                                }
                                                            >
                                                                Save as Draft
                                                            </button>
                                                            <button
                                                                data-toggle="dropdown"
                                                                className="btn btn-default btn-outline-light text-dark dropdown-toggle btn-carret"
                                                                type="button"
                                                                disabled={
                                                                    loading
                                                                }
                                                            >
                                                                <span className="caret"></span>
                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                {/* <li><a className="dpd-menu" onClick={() => submitClick('draft')}>Daft</a></li> */}
                                                                <li>
                                                                    <a
                                                                        className="dpd-menu"
                                                                        onClick={() =>
                                                                            submitClick(
                                                                                'draft-email'
                                                                            )
                                                                        }
                                                                    >
                                                                        Draft &
                                                                        Email
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a
                                                                        className="dpd-menu"
                                                                        onClick={() =>
                                                                            submitClick(
                                                                                'draft-print'
                                                                            )
                                                                        }
                                                                    >
                                                                        Draft &
                                                                        Print
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </Show>{' '}
                                                    <div className="btn-group btn-dpd">
                                                        <button
                                                            className="btn btn-primary  btn-main"
                                                            onClick={() =>
                                                                submitClick(
                                                                    'saved'
                                                                )
                                                            }
                                                            // disabled={enable_submit}
                                                            type="button"
                                                            disabled={loading}
                                                        >
                                                            Save Delivery
                                                        </button>
                                                        <button
                                                            data-toggle="dropdown"
                                                            className="btn btn-primary dropdown-toggle btn-carret"
                                                            type="button"
                                                            disabled={loading}
                                                            // disabled={enable_submit}
                                                        >
                                                            <span className="caret"></span>
                                                        </button>
                                                        <ul className="dropdown-menu">
                                                            {/* <li><a className="dpd-menu" href="#" onClick={() => submitClick('saved')} disabled={enable_submit}>Save</a></li> */}
                                                            <li>
                                                                <a
                                                                    className="dpd-menu"
                                                                    onClick={() =>
                                                                        submitClick(
                                                                            'saved-email'
                                                                        )
                                                                    }
                                                                >
                                                                    Save & Email
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    className="dpd-menu"
                                                                    onClick={() =>
                                                                        submitClick(
                                                                            'saved-print'
                                                                        )
                                                                    }
                                                                >
                                                                    Save & Print
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <DeliveryCustomerModal
                    show={customer_modal}
                    onHide={() => setModal('customer_modal', false)}
                />
            </div>
        </BeforeUnloadComponent>
    );
}

const mapStateToProps = (state) => ({
    delivery: state.delivery,
    block_route: state.helper.block_route,
});

export default connect(mapStateToProps, {
    getQuotations,
    fetchQuotations,
    searchQuotation,
    getQuotationItems,
    sumbit_delivery,
    handleSelectPaymentType,
    getTermsConditions,
    handleSelectTermsConditions,
    handleSelectReceiptType,
    checkBoxManual,
    handleCreateSO,
    handleSelectDRTemplate,
    getDeliveryTemplates,
    getCustomers,
    getProducts,
    handleSelectCustomer,
    handleSelectItem,
    changeQty,
    addItem,
    remItem,
    handleDiscountItem,
    handleSelectDiscountTypeProduct,
    handleInputDiscountProduct,
    vat_change,
    deliveryFee,
    discountType,
    discountInput,
    refNo,
    remarksInput,
    setModal,
    clearDelivery,
    blockRoute,
    searchProducts,
    searchCustomers,
    handleInputChangeDiscount,
    handleSelectDiscountType,
    handleTaxType,
    computationHandler,
    subscriberSetting,
    clear_data,
    ShowComboBox,
    dynamicInputChange,
    createProduct,
    skuSkipValidation,
    generateSku,
})(DeliveryCreate);
