import React, { Fragment, useEffect, useState } from 'react';
import Spinner from '../layouts/Spinner';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import {
    getStockTransfers,
    handleSearchInput,
    getSingleStockTransfer,
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
    // confirmDeleteDialogBox,
    setModal,
} from '../../redux/actions/stock_transfer/stock_transfer.actions';
import ReactTooltip from 'react-tooltip';
import { formattedDate, truncateObjectId } from '../../Utils/Common';
import { Redirect, useHistory } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles,
} from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Show, useAccess } from 'react-redux-permission';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import {
    handleHover,
    setMUIPage,
    updateMUISetting,
} from '../../redux/actions/helper/helper.actions';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import TransferModal from './modals/transferModal';

function StockTransfer({
    stock_transfer: {
        stock_transfers,
        set_row_checked,
        pagination,
        search,
        loading,
        edit_url,
        create_url,
        history_url,
        sort_order_name,
        sort_order_direction,
    },
    getStockTransfers,
    handleSearchInput,
    getSingleStockTransfer,
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
    // confirmDeleteDialogBox,
    handleHover,
    hoverIndex,
    updateMUISetting,
    setMUIPage,
    muiPage,
    setModal,
}) {
    const { hasPermission } = useAccess();

    const canCreate = hasPermission('inv.inventory.stock_transfers.create');
    const canDelete = hasPermission('inv.inventory.stock_transfers.delete');
    const canEdit = hasPermission('inv.inventory.stock_transfers.edit');
    const canview = hasPermission('inv.inventory.stock_transfers.view');
    const canViewHistroy = hasPermission(
        'inv.inventory.stock_transfers.history'
    );

    // START DEVOUNCED SEARCH
    const debouncedSearch = useDebounce(search, 800);
    const [oldDebounced, setOldDebounced] = useState('');

    const [copiedId, setCopiedId] = useState(null);

    useEffect(() => {
        if (debouncedSearch) {
            if (debouncedSearch[0]) {
                if (oldDebounced !== debouncedSearch[0]) {
                    if (debouncedSearch[0].length !== 1) {
                        if (canview) {
                            setOldDebounced(debouncedSearch[0]);
                            getStockTransfers();
                        }
                    }
                }
            } else {
                if (oldDebounced !== '') {
                    if (canview) {
                        setOldDebounced('');
                        getStockTransfers();
                    }
                }
            }
        }
    }, [debouncedSearch]);
    // END DEVOUNCE SEARCH

    useEffect(() => {
        getStockTransfers(muiPage);
    }, []);

    let history = useHistory();
    const changePage = (link) => history.push(link);

    const getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MUIDataTable: {
                    root: {},
                    paper: {
                        boxShadow: 'none',
                    },
                },
                MUIDataTableBodyRow: {
                    root: {
                        '&:nth-child(odd)': {
                            backgroundColor: 'rgba(0,0,0,.05)',
                        },
                    },
                },
                MuiTableRow: {
                    hover: {
                        '&:hover': {
                            backgroundColor: '#bfcfe4 !important',
                        },
                    },
                },
                MUIDataTableBodyCell: {
                    root: {
                        '&:nth-child(2)': {
                            height: '61px',
                            width: '250px',
                        },
                        '&:nth-child(3)': {
                            width: '250px',
                            'text-align': 'justify',
                        },
                        '&:nth-child(4)': {
                            width: '250px',
                        },
                        '&:nth-child(5)': {
                            width: '250px',
                        },
                        '&:nth-child(6)': {
                            width: '150px',
                        },
                    },
                },
            },
        });

    const columns = [
        {
            name: 'created_at',
            label: 'Date Created',
            options: {
                display: true,
                customBodyRender: (value) => {
                    return <span> {value.substr(0, 10)} </span>;
                },
                setCellProps: (value) => {
                    return { className: 'cursor-pointer' };
                },
            },
        },
        {
            name: 'product_name',
            label: 'Product Name',
            options: {
                display: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let id = stock_transfers[tableMeta['rowIndex']].product_id;
                    return <Link to={`/inventory/view/${id}`}>{value}</Link>;
                },
                setCellHeaderProps: (value) => {
                    return { className: 'cursor-pointer text-center' };
                },
            },
        },
        {
            name: 'sku',
            label: 'SKU',
            options: {
                display: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let id = stock_transfers[tableMeta['rowIndex']].product_id;
                    return <Link to={`/inventory/view/${id}`}>{value}</Link>;
                },
                setCellHeaderProps: (value) => {
                    return { className: 'cursor-pointer text-center' };
                },
            },
        },
        {
            name: 'description',
            label: 'Description',
            options: {
                display: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let id = stock_transfers[tableMeta['rowIndex']].product_id;
                    return <Link to={`/inventory/view/${id}`}>{value}</Link>;
                },
                setCellHeaderProps: (value) => {
                    return { className: 'cursor-pointer text-center' };
                },
            },
        },
        {
            name: 'brand_name',
            label: 'Brand',
            options: {
                display: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let id = stock_transfers[tableMeta['rowIndex']].product_id;
                    return <Link to={`/inventory/view/${id}`}>{value}</Link>;
                },
                setCellHeaderProps: (value) => {
                    return { className: 'cursor-pointer text-center' };
                },
            },
        },
        {
            name: 'qty',
            label: 'Qty',
            options: {
                filter: false,
                sort: false,
                setCellProps: (value) => {
                    return { className: 'cursor-pointer' };
                },
                customBodyRender: (value) => value || 'N/A',
            },
        },
        {
            name: 'movement',
            label: 'Movement',
            options: {
                filter: false,
                sort: false,
                setCellProps: (value) => {
                    return { className: 'cursor-pointer' };
                },
                customBodyRender: (value) => value || 'N/A',
            },
        },
        {
            name: 'remarks',
            label: 'Remarks',
            options: {
                filter: false,
                sort: false,
                setCellProps: (value) => {
                    return { className: 'cursor-pointer' };
                },
                customBodyRender: (value) => value || 'N/A',
            },
        },
    ];

    const options = {
        serverSide: true,
        jumpToPage: true,
        filterType: 'dropdown',
        responsive: 'standard',
        search: false,
        filter: false,
        searchOpen: true,
        searchProps: {
            onBlur: (e) => {
                console.log('onBlur!');
            },
            onKeyUp: (e) => {
                console.log('onKeyUp!');
            },
        },
        rowsPerPageOptions: [10, 20, 50, 100, 200],
        rowsPerPage: pagination.itemsCountPerPage,
        count: pagination.totalItemsCount,
        page: pagination.activePage - 1,
        sortOrder: {
            name: sort_order_name,
            direction: sort_order_direction,
        },
        downloadOptions: {
            filename: `stock_transfers_${formattedDate()}.csv`,
        },
        textLabels: {
            body: {
                noMatch: loading ? (
                    <Spinner />
                ) : (
                    'Sorry, there is no matching data to display'
                ),
            },
        },
        onChangeRowsPerPage: (numberOfRows) => {
            getStockTransfers(null, numberOfRows);
            updateMUISetting('rows', 'stock_transfer', numberOfRows);
        },
        onTableChange: (action, tableState) => {
            // console.log(action, tableState);
            switch (action) {
                case 'changePage':
                    getStockTransfers(
                        tableState.page + 1,
                        pagination.itemsCountPerPage
                    );
                    setMUIPage(tableState.page + 1);
                    break;
                case 'sort':
                    // console.log(tableState.sortOrder.name, tableState.sortOrder.direction);
                    let column_name = tableState.sortOrder.name;
                    let direction = tableState.sortOrder.direction;
                    sortTableByColumn(column_name, direction);
                    break;
                case 'search':
                    // handleSearchInputMUI(tableState.searchText);
                    break;
                case 'rowSelectionChange':
                    let selectedRows = tableState.selectedRows.data;
                    let currentRows = tableState.data;
                    getSelectedRows(selectedRows, currentRows);
                    break;
                case 'propsUpdate':
                    // let update_checked = tableState.selectedRows = { data: [], lookup: {}, showResponsive: false };
                    if (set_row_checked) {
                        tableState.selectedRows['data'] = [];
                        tableState.selectedRows['lookup'] = {};
                        tableState.selectedRows['showResponsive'] = false;
                    } else {
                        return tableState;
                    }
                case 'viewColumnsChange':
                    updateMUISetting(
                        'columns',
                        'stock_transfer',
                        null,
                        tableState.columns
                    );
                    break;
                default:
                    console.log('action not handled.');
            }
        },
        // onRowsDelete: () => {
        //     // Confirm Delete Modal
        //     if (canDelete) {
        //         // confirmDeleteDialogBox();
        //     }

        //     return false;
        // },
        onCellClick: (colData, cellMeta) => {
            // console.log(purchase_orders[cellMeta.dataIndex]._id);
            if (cellMeta.colIndex != 6) {
                if (canViewHistroy) {
                    // changePage(`${history_url}/${stock_transfers[cellMeta.dataIndex]._id}`);
                }
            }
        },
        customSearchRender: (searchText, handleSearch, hideSearch, options) => {
            return (
                <div className="custom-search">
                    <i className="ft ft-search"></i>{' '}
                    <input
                        type="text"
                        name="search"
                        onKeyUp={handleSearchInputMUI}
                        className="custom-search-input"
                    />
                </div>
            );
        },
        setRowProps: (data, dataIndex, rowIndex) => {
            return {
                onMouseEnter: () => handleHover('in', rowIndex),
                onMouseLeave: () => handleHover('out', rowIndex),
            };
        },
    };
    if (!canDelete) {
        options.selectableRows = false;
    }

    const _columns = updateMUISetting(
        'view-columns',
        'stock_transfer',
        null,
        columns
    );

    return (
        <>
            <div className="app-content content">
                <ReactTooltip effect="solid" event="click" />
                <div className="content-wrapper max-1920 mx-auto">
                    <div className="content-wrapper-before"></div>
                    <div className="content-header row">
                        <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                            <h3 className="content-header-title mb-0 d-inline-block">
                                Inventory
                            </h3>

                            <div className="breadcrumbs-top d-inline-block">
                                <div className="breadcrumb-wrapper mr-1">
                                    <ol className="breadcrumb f-regular">
                                        <li className="breadcrumb-item ">
                                            <a
                                                href="stock-transfer"
                                                className="text-white"
                                            >
                                                {' '}
                                                Stock Transfer
                                            </a>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Show when="inv.inventory.stock-transfer.view">
                        <div className="content-body margin-top-5">
                            <section id="basic-examples">
                                <div className="row f-regular">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <h4 className="card-title">
                                                    List of Transfer{' '}
                                                    <a
                                                        href="#"
                                                        className="tool-tip"
                                                        data-tip="this is a tool tip"
                                                    >
                                                        <i className="la la-question-circle"></i>
                                                    </a>
                                                    <Show when="inv.inventory.stock-transfer.create">
                                                        <button
                                                            className="btn btn-primary pull-right btn-sm"
                                                            onClick={() =>
                                                                setModal(
                                                                    'stock_transfer_modal',
                                                                    true
                                                                )
                                                            }
                                                        >
                                                            <i className="ft ft-plus"></i>{' '}
                                                            Quick Transfer
                                                        </button>
                                                    </Show>
                                                </h4>
                                            </div>
                                            <div className="card-content collapse show">
                                                <div className="card-body">
                                                    <section className="row">
                                                        <div className="col-sm-12">
                                                            <MuiThemeProvider
                                                                theme={getMuiTheme()}
                                                            >
                                                                <MUIDataTable
                                                                    title={
                                                                        'List of Transfer'
                                                                    }
                                                                    data={
                                                                        stock_transfers
                                                                    }
                                                                    columns={
                                                                        _columns
                                                                    }
                                                                    options={
                                                                        options
                                                                    }
                                                                />
                                                            </MuiThemeProvider>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </Show>
                </div>
            </div>
            <TransferModal
                className="modal-centered"
                onHide={() => setModal('stock_transfer_modal', null)}
            />
        </>
    );
}

const mapStateToProps = (state) => ({
    stock_transfer: state.stock_transfer,
    hoverIndex: state.helper.hoverIndex,
    muiPage: state.helper.muiPage,
});

export default connect(mapStateToProps, {
    getStockTransfers,
    handleSearchInput,
    getSingleStockTransfer,
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
    // confirmDeleteDialogBox,
    handleHover,
    updateMUISetting,
    setMUIPage,
    setModal,
})(StockTransfer);
