import React, { useEffect, useState } from 'react';
import Spinner from '../layouts/Spinner';
import { connect } from 'react-redux';
import {
    getDailySales,
    dailySalesChecker,
    sortTableByColumn,
    getSelectedRows,
    setModal,
} from '../../redux/actions/daily_sales/daily_sales.action';
import { formattedDate } from '../../Utils/Common';
import { Show, useAccess } from 'react-redux-permission';
import { Link } from 'react-router-dom';
import DailySalesDateFilter from './dailySales.datefilter.global';
import DailySalesViewModal from './dailySales.modal.view';
import SalesFilter from './dailySales.salesFilter.js';
import { NumberFormat } from '../../Services/_numberformat.service.js';
import * as XLSX from 'xlsx';

function DailySalesTable({
    daily_sales: {
        daily_sales,
        sort_order_direction,
        pagination,
        loading,
        sort_order_name,
        set_row_checked,
        search,
        daily_sales_modal,
        sales_option, // Adding sales_option here
    },
    getDailySales,
    dailySalesChecker,
    sortTableByColumn,
    setMUIPage,
    getSelectedRows,
    pageOrigin,
    muiPage,
    setModal,
}) {
    const { hasPermission } = useAccess();
    const canview = hasPermission('inv.dashboard.daily-sales.view');

    const [visibleColumns, setVisibleColumns] = useState({
        product_name: true,
        sku: true,
        brand_name: true,
        description: true,
        supplier_name: true,
        pos_qty: true,
        pos_sales: true,
        sales_order_qty: true,
        sales_order_sales: true,
        total_qty: true,
        total_sales: true,
        available_stocks: true,
        incoming_stocks: true,
        draft_incoming_stocks: true,
    });

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isChecking, setIsChecking] = useState(true); // New state to manage spinner

    useEffect(() => {
        const fetchData = async () => {
            setIsChecking(true); // Start spinner
            await dailySalesChecker(); // Ensure this function is asynchronous if it's not already
            setIsChecking(false); // Stop spinner after check is complete
            getDailySales(muiPage, null, pageOrigin);
        };

        fetchData();
    }, [getDailySales, muiPage, pageOrigin]);

    useEffect(() => {
        // Adjust the visibility of columns based on sales_option
        if (sales_option.value === 'all') {
            setVisibleColumns((prev) => ({
                ...prev,
                pos_sales: false,
                sales_order_sales: false,
                total_qty: true,
                pos_qty: true,
                sales_order_qty: true,
                total_sales: true,
            }));
        } else if (sales_option.value === 'pos') {
            setVisibleColumns((prev) => ({
                ...prev,
                pos_sales: true,
                pos_qty: true,
                sales_order_sales: false,
                sales_order_qty: false,
                total_qty: false,
                total_sales: false,
            }));
        } else if (sales_option.value === 'sales order') {
            setVisibleColumns((prev) => ({
                ...prev,
                pos_sales: false,
                pos_qty: false,
                sales_order_sales: true,
                sales_order_qty: true,
                total_qty: false,
                total_sales: false,
            }));
        }
    }, [sales_option]);

    // Calculate totals for each field
    const calculateTotals = () => {
        let totals = {
            pos_qty: 0,
            pos_sales: 0,
            sales_order_qty: 0,
            sales_order_sales: 0,
            total_qty: 0,
            total_sales: 0,
            available_stocks: 0,
            incoming_stocks: 0,
            draft_incoming_stocks: 0,
        };

        daily_sales.forEach((sale) => {
            totals.pos_qty += sale.pos_qty || 0;
            totals.pos_sales += sale.pos_sales || 0;
            totals.sales_order_qty += sale.sales_order_qty || 0;
            totals.sales_order_sales += sale.sales_order_sales || 0;
            totals.total_qty += sale.total_qty || 0;
            totals.total_sales += sale.total_sales || 0;
            totals.available_stocks += sale.available_stocks || 0;
            totals.incoming_stocks += sale.incoming_stocks || 0;
            totals.draft_incoming_stocks += sale.draft_incoming_stocks || 0;
        });

        return totals;
    };

    const totals = calculateTotals();

    const handleSort = (columnName) => {
        const direction =
            sort_order_name === columnName && sort_order_direction === 'asc'
                ? 'desc'
                : 'asc';
        sortTableByColumn(columnName, direction, pageOrigin);
    };

    const getSortIcon = (columnName) => {
        return (
            <i
                className={`la la-arrows-v ${
                    sort_order_name === columnName &&
                    sort_order_direction === 'asc'
                        ? 'rotate-180'
                        : ''
                }`}
                style={{ fontSize: '14px' }}
            ></i>
        );
    };

    const handleRowClick = (rowId) => {
        const selectedRow = daily_sales.find((row) => row._id === rowId);
        const products = selectedRow?.products || [];
        setModal('daily_sales_modal', products);
    };

    const handleDownloadExcel = () => {
        // Prepare the data for the Excel file
        const headers = Object.keys(visibleColumns)
            .filter((column) => visibleColumns[column])
            .map((column) => column.replace('_', ' '));

        const data = daily_sales.map((sale) =>
            Object.keys(visibleColumns)
                .filter((column) => visibleColumns[column])
                .map((column) =>
                    sale[column] !== null && sale[column] !== undefined
                        ? sale[column]
                        : 'N/A'
                )
        );

        // Calculate totals (same function as above)
        const totals = calculateTotals();

        // Add the totals row at the end of the data array
        const totalsRow = Object.keys(visibleColumns)
            .filter((column) => visibleColumns[column])
            .map((column) => {
                switch (column) {
                    case 'pos_qty':
                        return totals.pos_qty;
                    case 'pos_sales':
                        return `₱${NumberFormat(totals.pos_sales)}`;
                    case 'sales_order_qty':
                        return totals.sales_order_qty;
                    case 'sales_order_sales':
                        return `₱${NumberFormat(totals.sales_order_sales)}`;
                    case 'total_qty':
                        return totals.total_qty;
                    case 'total_sales':
                        return `₱${NumberFormat(totals.total_sales)}`;
                    case 'available_stocks':
                        return totals.available_stocks;
                    case 'incoming_stocks':
                        return totals.incoming_stocks;
                    case 'draft_incoming_stocks':
                        return totals.draft_incoming_stocks;
                    default:
                        return ''; // No data for other columns
                }
            });

        // Add totalsRow to the data array
        data.push(totalsRow);

        // Combine headers and data
        const worksheetData = [headers, ...data];

        // Create a worksheet
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

        // Create a new workbook
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Daily Sales');

        // Generate an Excel file
        XLSX.writeFile(workbook, `dailySales-${formattedDate()}.xlsx`);
    };

    const handlePrint = () => {
        const printContent =
            document.getElementById('table-to-print').innerHTML;
        const newWindow = window.open('', '', 'width=800,height=600');
        newWindow.document.write(
            '<html><head><title>Print</title></head><body>'
        );
        newWindow.document.write(printContent);
        newWindow.document.write('</body></html>');
        newWindow.document.close();
        newWindow.print();
    };

    const toggleColumn = (columnName) => {
        setVisibleColumns((prevState) => ({
            ...prevState,
            [columnName]: !prevState[columnName],
        }));
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    // Check if still loading or is checking the daily sales
    if (loading || isChecking) {
        return <Spinner />;
    }

    return (
        <section>
            <Show when="inv.dashboard.daily-sales">
                <div>
                    <SalesFilter
                        filterBy="daily_sales"
                        pageOrigin={pageOrigin}
                    />
                </div>

                <div className="ds-table-actions">
                    <button
                        className="small-button"
                        onClick={handleDownloadExcel}
                        title="Download Excel"
                    >
                        <i className="la la-cloud-download"></i>
                    </button>
                    <button
                        className="small-button"
                        onClick={handlePrint}
                        title="Print"
                    >
                        <i className="la la-print"></i>
                    </button>
                    <div className="dropdown">
                        <button
                            className="small-button"
                            onClick={toggleDropdown}
                            title="View Columns"
                        >
                            <i className="la la-columns"></i>
                        </button>
                        {dropdownOpen && (
                            <div className="dropdown-content">
                                {Object.keys(visibleColumns).map((column) => (
                                    <label key={column}>
                                        <input
                                            type="checkbox"
                                            checked={visibleColumns[column]}
                                            onChange={() =>
                                                toggleColumn(column)
                                            }
                                        />
                                        {column.replace('_', ' ')}
                                    </label>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <div className="ds-table-container">
                    <table
                        id="ds-table-to-print"
                        className="ds-table ds-table-striped"
                    >
                        <thead>
                            <tr>
                                {visibleColumns.product_name && (
                                    <th
                                        style={{ width: '150px' }}
                                        onClick={() =>
                                            handleSort('product_name')
                                        }
                                    >
                                        Name {getSortIcon('product_name')}
                                    </th>
                                )}
                                {visibleColumns.sku && (
                                    <th onClick={() => handleSort('sku')}>
                                        SKU {getSortIcon('sku')}
                                    </th>
                                )}
                                {visibleColumns.brand_name && (
                                    <th
                                        onClick={() => handleSort('brand_name')}
                                    >
                                        Brand {getSortIcon('brand_name')}
                                    </th>
                                )}
                                {visibleColumns.description && (
                                    <th
                                        style={{ width: '300px' }}
                                        onClick={() =>
                                            handleSort('description')
                                        }
                                    >
                                        Description {getSortIcon('description')}
                                    </th>
                                )}
                                {visibleColumns.supplier_name && (
                                    <th
                                        style={{ width: '150px' }}
                                        onClick={() => handleSort('supplier')}
                                    >
                                        Supplier {getSortIcon('supplier')}
                                    </th>
                                )}
                                {visibleColumns.pos_qty && (
                                    <th onClick={() => handleSort('pos_qty')}>
                                        POS <br /> Qty {getSortIcon('pos_qty')}
                                    </th>
                                )}
                                {visibleColumns.pos_sales && (
                                    <th onClick={() => handleSort('pos_sales')}>
                                        POS <br /> Sales{' '}
                                        {getSortIcon('pos_sales')}
                                    </th>
                                )}
                                {visibleColumns.sales_order_qty && (
                                    <th
                                        onClick={() =>
                                            handleSort('sales_order_qty')
                                        }
                                    >
                                        SO <br /> Qty{' '}
                                        {getSortIcon('sales_order_qty')}
                                    </th>
                                )}
                                {visibleColumns.sales_order_sales && (
                                    <th
                                        onClick={() =>
                                            handleSort('sales_order_sales')
                                        }
                                    >
                                        SO <br /> Sales{' '}
                                        {getSortIcon('sales_order_sales')}
                                    </th>
                                )}
                                {visibleColumns.total_qty && (
                                    <th onClick={() => handleSort('total_qty')}>
                                        Total <br /> Qty{' '}
                                        {getSortIcon('total_qty')}
                                    </th>
                                )}
                                {visibleColumns.total_sales && (
                                    <th>
                                        Total <br /> Sales{' '}
                                        {getSortIcon('total_sales')}
                                    </th>
                                )}
                                {visibleColumns.available_stocks && (
                                    <th
                                        onClick={() =>
                                            handleSort('available_stocks')
                                        }
                                    >
                                        AVL <br /> Stocks{' '}
                                        {getSortIcon('available_stocks')}
                                    </th>
                                )}
                                {visibleColumns.incoming_stocks && (
                                    <th
                                        onClick={() =>
                                            handleSort('incoming_stocks')
                                        }
                                    >
                                        INC
                                        <br /> Stocks{' '}
                                        {getSortIcon('incoming_stocks')}
                                    </th>
                                )}
                                {visibleColumns.draft_incoming_stocks && (
                                    <th
                                        onClick={() =>
                                            handleSort('draft_incoming_stocks')
                                        }
                                    >
                                        Draft INC
                                        <br /> Stocks{' '}
                                        {getSortIcon('draft_incoming_stocks')}
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {daily_sales.length === 0 ? (
                                <tr>
                                    <td
                                        colSpan={
                                            Object.keys(visibleColumns).length
                                        }
                                        style={{
                                            textAlign: 'center',
                                            padding: '20px',
                                        }}
                                    >
                                        Sorry, there is no matching data to
                                        display
                                    </td>
                                </tr>
                            ) : (
                                daily_sales.map((sale) => (
                                    <tr
                                        key={sale._id}
                                        onClick={() => handleRowClick(sale._id)}
                                    >
                                        {visibleColumns.product_name && (
                                            <td>
                                                <div className="td-value">
                                                    <Link
                                                        to={`/inventory/view/${sale._id}`}
                                                    >
                                                        {sale.product_name}
                                                    </Link>
                                                </div>
                                            </td>
                                        )}
                                        {visibleColumns.sku && (
                                            <td className="description-cell">
                                                <div className="td-value">
                                                    {sale.sku}
                                                </div>
                                            </td>
                                        )}
                                        {visibleColumns.brand_name && (
                                            <td className="description-cell">
                                                <div className="td-value">
                                                    {sale.brand_name ? (
                                                        <Link
                                                            to={`/inventory/products/brands/edit/${sale.brand_id}`}
                                                        >
                                                            {sale.brand_name}
                                                        </Link>
                                                    ) : (
                                                        'N/A'
                                                    )}
                                                </div>
                                            </td>
                                        )}
                                        {visibleColumns.description && (
                                            <td className="description-cell">
                                                {sale.description || 'N/A'}
                                            </td>
                                        )}
                                        {visibleColumns.supplier_name && (
                                            <td>
                                                <div className="td-value">
                                                    {sale.supplier_name ? (
                                                        <Link
                                                            to={`/purchases/suppliers/edit/${sale.supplier_id}`}
                                                        >
                                                            {sale.supplier_name}
                                                        </Link>
                                                    ) : (
                                                        'N/A'
                                                    )}
                                                </div>
                                            </td>
                                        )}
                                        {visibleColumns.pos_qty && (
                                            <td>
                                                <div className="td-value">
                                                    {NumberFormat(
                                                        sale.pos_qty.toFixed()
                                                    )}
                                                </div>
                                            </td>
                                        )}
                                        {visibleColumns.pos_sales && (
                                            <td>
                                                <div className="td-value">
                                                    {' '}
                                                    ₱
                                                    {NumberFormat(
                                                        sale.pos_sales.toFixed(
                                                            2
                                                        )
                                                    )}
                                                </div>
                                            </td>
                                        )}
                                        {visibleColumns.sales_order_qty && (
                                            <td>
                                                <div className="td-value">
                                                    {NumberFormat(
                                                        sale.sales_order_qty.toFixed()
                                                    )}
                                                </div>
                                            </td>
                                        )}
                                        {visibleColumns.sales_order_sales && (
                                            <td>
                                                <div className="td-value">
                                                    {' '}
                                                    ₱
                                                    {NumberFormat(
                                                        sale.sales_order_sales.toFixed(
                                                            2
                                                        )
                                                    )}
                                                </div>
                                            </td>
                                        )}
                                        {visibleColumns.total_qty && (
                                            <td>
                                                <div className="td-value">
                                                    {NumberFormat(
                                                        sale.total_qty.toFixed()
                                                    )}
                                                </div>
                                            </td>
                                        )}
                                        {visibleColumns.total_sales && (
                                            <td>
                                                <div className="td-value">
                                                    {' '}
                                                    ₱
                                                    {NumberFormat(
                                                        sale.total_sales.toFixed(
                                                            2
                                                        )
                                                    )}
                                                </div>
                                            </td>
                                        )}
                                        {visibleColumns.available_stocks && (
                                            <td>
                                                <div className="td-value">
                                                    {sale.available_stocks !==
                                                        undefined &&
                                                    sale.available_stocks !==
                                                        null
                                                        ? new Intl.NumberFormat().format(
                                                              sale.available_stocks.toFixed(
                                                                  0
                                                              )
                                                          )
                                                        : 'N/A'}
                                                </div>
                                            </td>
                                        )}
                                        {visibleColumns.incoming_stocks && (
                                            <td>
                                                <div className="td-value">
                                                    {sale.incoming_stocks !==
                                                        undefined &&
                                                    sale.incoming_stocks !==
                                                        null
                                                        ? new Intl.NumberFormat().format(
                                                              sale.incoming_stocks.toFixed(
                                                                  0
                                                              )
                                                          )
                                                        : 'N/A'}
                                                </div>
                                            </td>
                                        )}{' '}
                                        {visibleColumns.draft_incoming_stocks && (
                                            <td>
                                                <div className="td-value">
                                                    {sale.draft_incoming_stocks !==
                                                        undefined &&
                                                    sale.draft_incoming_stocks !==
                                                        null
                                                        ? new Intl.NumberFormat().format(
                                                              sale.draft_incoming_stocks.toFixed(
                                                                  0
                                                              )
                                                          )
                                                        : 'N/A'}
                                                </div>
                                            </td>
                                        )}
                                    </tr>
                                ))
                            )}
                        </tbody>
                        <tfoot className="table-footer-totals">
                            <tr>
                                {visibleColumns.product_name && <td></td>}
                                {visibleColumns.sku && <td></td>}
                                {visibleColumns.brand_name && <td></td>}
                                {visibleColumns.description && <td></td>}
                                {visibleColumns.supplier_name && <td></td>}
                                {visibleColumns.pos_qty && (
                                    <td>{NumberFormat(totals.pos_qty)}</td>
                                )}
                                {visibleColumns.pos_sales && (
                                    <td>₱{NumberFormat(totals.pos_sales)}</td>
                                )}
                                {visibleColumns.sales_order_qty && (
                                    <td>
                                        {NumberFormat(totals.sales_order_qty)}
                                    </td>
                                )}
                                {visibleColumns.sales_order_sales && (
                                    <td>
                                        ₱
                                        {NumberFormat(totals.sales_order_sales)}
                                    </td>
                                )}
                                {visibleColumns.total_qty && (
                                    <td>{NumberFormat(totals.total_qty)}</td>
                                )}
                                {visibleColumns.total_sales && (
                                    <td>₱{NumberFormat(totals.total_sales)}</td>
                                )}
                                {visibleColumns.available_stocks && (
                                    <td>
                                        {NumberFormat(totals.available_stocks)}
                                    </td>
                                )}
                                {visibleColumns.incoming_stocks && (
                                    <td>
                                        {NumberFormat(totals.incoming_stocks)}
                                    </td>
                                )}
                                {visibleColumns.draft_incoming_stocks && (
                                    <td>
                                        {NumberFormat(
                                            totals.draft_incoming_stocks
                                        )}
                                    </td>
                                )}
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div className="ds-table-footer">
                    <p>Total Documents: {pagination.totalItemsCount}</p>
                </div>
            </Show>
            <DailySalesViewModal
                onHide={() => setModal('daily_sales_modal', null)}
            />
        </section>
    );
}

const mapStateToProps = (state) => ({
    daily_sales: state.daily_sales,
    muiPage: state.helper.muiPage,
});

export default connect(mapStateToProps, {
    getDailySales,
    dailySalesChecker,
    sortTableByColumn,
    getSelectedRows,
    setModal,
})(DailySalesTable);
