import React, { Fragment, useRef, useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { MiniSpinner } from '../../layouts/Spinner.js';
// import SalesOrderPrintContent from './SalesOrderPrintContent';
import {
    setPage,
    goPage,
    sendEmail,
    reSendEmail,
    getPdf,
} from '../../../redux/actions/sales_order/sales_order.actions.js';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { globalDropDown } from '../../../redux/actions/helper/helper.actions.js';
import DropDownAttachFileGlobal from '../../layouts/DropDownAttachFile.global.js';
import SalesOrderCustomTemplate from '../custom_template/SalesOrderCustomTemplate';

function SalesOrderModalPrint({
    single_sales_order,
    mini_loading,
    auth,
    print_modal_si,
    download_pdf,
    setPage,
    onHide,
    edit_url,
    goPage,
    globalDropDown,
    files_to_remove,
    new_uploads,
    subs_setting,
    sending_email,
    sendEmail,
    reSendEmail,
    getPdf,
}) {
    const componentRef = useRef();
    const status_lbl = (
        <span className={`lbl-status mr-1 lbl-${single_sales_order.status}`}>
            {single_sales_order.status}
        </span>
    );

    // previous button
    const previousBtn = (
        <>
            {' '}
            {single_sales_order.latest_sales_order_no !=
                single_sales_order.sales_order_no && (
                <div className="mdl-arrow-left arr-link">
                    <Link onClick={() => goPage('prev')}>
                        <i className="la la-angle-left mdl-arrow"></i>
                    </Link>
                </div>
            )}
        </>
    );

    // next button
    const nextBtn = (
        <>
            {' '}
            {single_sales_order.oldest_sales_order_no !=
                single_sales_order.sales_order_no && (
                <div className="mdl-arrow-right arr-link">
                    <Link onClick={() => goPage('next')}>
                        <i className="la la-angle-right mdl-arrow"></i>
                    </Link>
                </div>
            )}{' '}
        </>
    );

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    let file_counter =
        (single_sales_order.uploads ? single_sales_order.uploads.length : 0) +
        new_uploads.length -
        files_to_remove.length;

    return (
        <Modal
            centered
            dialogClassName="modal-wdth"
            show={print_modal_si}
            size="lg"
            onHide={() => setPage('print_modal_si', null, false)}
            animation={true}
        >
            <Modal.Header className="inv-bg-header">
                {/* <Modal.Title> Sales Order</Modal.Title> */}
                <div className="col-4 pl-0">
                    <div className="modal-status">{status_lbl}</div>
                </div>
                <div className="col-8 pr-0">
                    <div className="float-right">
                        <Link
                            to={`${edit_url}/${single_sales_order._id}`}
                            className="btn btn-circle btn-inv btn-grey mr-1 icon-edit-c"
                        >
                            <i className="ft ft-edit cursor-pointer"></i>
                        </Link>
                        <button
                            onClick={(e) => globalDropDown(e, 'show')}
                            className="btn btn-circle btn-inv btn-grey mr-1 icon-paper-c relative"
                        >
                            <i className="ft ft-paperclip cursor-pointer"></i>
                            {file_counter > 0 ? (
                                <span className="badge badge-pill badge-sm badge-danger badge-up badge-file">
                                    {file_counter > 9 ? '9+' : file_counter}
                                </span>
                            ) : (
                                <></>
                            )}
                        </button>
                        <DropDownAttachFileGlobal
                            origin="sales_order"
                            origin_data={single_sales_order}
                            new_uploads={new_uploads}
                            files_to_remove={files_to_remove}
                        />
                        {single_sales_order.email_sent ? (
                            <button
                                className="btn btn-circle btn-inv btn-grey mr-1"
                                onClick={(e) =>
                                    reSendEmail(e, single_sales_order._id)
                                }
                            >
                                <i
                                    className={
                                        sending_email
                                            ? 'icon-loading-custom icon-20 cursor-pointer'
                                            : 'ft-custom-mail-check cursor-pointer'
                                    }
                                ></i>
                            </button>
                        ) : (
                            <button
                                className="btn btn-circle btn-inv btn-grey mr-1"
                                onClick={(e) =>
                                    sendEmail(e, single_sales_order._id)
                                }
                            >
                                <i
                                    className={
                                        sending_email
                                            ? 'icon-loading-custom icon-20 cursor-pointer'
                                            : 'ft-custom-mail-x cursor-pointer'
                                    }
                                ></i>
                            </button>
                        )}
                        <button
                            className="btn btn-circle btn-inv btn-grey mr-1 icon-pdf-c"
                            onClick={(e) =>
                                getPdf(
                                    e,
                                    single_sales_order._id,
                                    single_sales_order.sales_order_no,
                                    single_sales_order.customer.name
                                )
                            }
                        >
                            <i
                                className={
                                    download_pdf
                                        ? 'icon-loading-custom icon-20 cursor-pointer'
                                        : 'la la-file-pdf-o cursor-pointer'
                                }
                            ></i>
                        </button>
                        <button
                            onClick={handlePrint}
                            className="btn btn-circle btn-inv btn-grey mr-1"
                        >
                            <i className="ft ft-printer cursor-pointer"></i>
                        </button>
                        <button
                            onClick={() =>
                                setPage('print_modal_si', null, false)
                            }
                            className="btn btn-circle btn-inv m-close-btn cursor-pointer"
                        >
                            <i className="ft ft-x cursor-pointer"></i>
                        </button>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body className="unset-scroll p-0 m-0">
                <div className="sd-border">
                    {previousBtn}
                    {mini_loading ? (
                        <div className="my-5">
                            <MiniSpinner />
                        </div>
                    ) : (
                        <SalesOrderCustomTemplate componentRef={componentRef} />
                    )}
                    {nextBtn}
                </div>
            </Modal.Body>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    single_sales_order: state.sales_order.single_sales_order,
    print_modal_si: state.sales_order.print_modal_si,
    mini_loading: state.sales_order.mini_loading,
    new_uploads: state.sales_order.new_uploads,
    files_to_remove: state.sales_order.files_to_remove,
    subs_setting: state.sales_order.subs_setting,
    sending_email: state.sales_order.sending_email,
    download_pdf: state.sales_order.download_pdf,
});

export default connect(mapStateToProps, {
    setPage,
    goPage,
    globalDropDown,
    sendEmail,
    reSendEmail,
    getPdf,
})(SalesOrderModalPrint);

// <>
// 	<SalesOrderPrintContent si={single_sales_order} auth={auth} print="0" print_rows="5" />
// 	<div style={{'display':'none'}}><SalesOrderPrintContent si={single_sales_order} auth={auth} ref={componentRef} print="1" print_rows="19"/></div>
// </>
