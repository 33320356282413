import {
    SET_LOADING,
    SEARCH_INPUT,
    GET_STATUS,
    SET_PAGE,
    SET_MINI_LOADING,
    GET_SINGLE_SALES_ORDER,
    GET_SALES_ORDERS,
    GET_SO_STATUS,
    SALES_ORDER_INPUT_CHANGE,
    GET_SI_CUSTOMERS,
    GET_SI_PRODUCTS,
    GET_SI_TERMS_CONDITIONS,
    CLEAR_SALES_ORDER,
    HANDLE_SI_SELECT_TERMS_CONDITIONS,
    HANDLE_SI_SELECT_ITEM,
    HANDLE_SI_REMOVE_ITEM,
    HANDLE_SI_SELECT_PAYMENT,
    HANDLE_SI_ADD_ITEM,
    SET_LAST_ADDED_DROPDOWN_INDEX,
    HANDLE_SI_SRP,
    HANDLE_SI_QTY,
    HANDLE_SI_INPUT_ATTR,
    HANDLE_SI_TOGGLE_EDIT_BTN_ATTR,
    HANDLE_SI_SELECT_CUSTOMER,
    HANDLE_SI_CHECK_VAT,
    GET_SI_TEMPLATES,
    HANDLE_SELECT_SI_TEMPLATE,
    HANDLE_SI_SELECT_TYPE_RECEIPT,
    HANDLE_SI_TOGGLE_EDIT_ROW,
    HANDLE_SI_EDIT_INPUT_ROW,
    HANDLE_SI_EDIT_UPDATE_ROW,
    HANDLE_SI_SUBMIT_ATTR,
    HANDLE_SI_SELECT_PAYMENT_TYPE,
    SET_SI_ITEM_ID,
    SET_MODAL,
    HANDLE_CHECK_MANUAL,
    SO_GET_QUOTATIONS,
    HANDLE_SO_SELECT_QUOTATION,
    SET_DATE_PICKER,
    SORT_BY_COLUMN,
    SET_QU_ITEM_ID,
    GET_ROWS_TO_DELETE,
    SET_ROW_CHECKED,
    SO_UPDATE_FILE,
    SO_REMOVE_FILE,
    SO_UPLOADS_TO_REMOVE,
    SO_GET_UPLOADED_FILES,
    ITEMS_TEMPLATE,
    ADD_ITEM,
    TOGGLE_DISCOUNT_ROW,
    SI_SET_CUSTOMER_DATA,
    SI_CLEAR_UPLOAD,
    SI_SELECT_TAX_TYPE,
    GET_SI_SUB_SETTING,
    SI_COMPUTATION_HANDLER,
    SEND_EMAIL,
    RESEND_EMAIL,
    DYNAMIC_SEND_EMAIL,
    DOWNLOAD_BTN,
    EMAIL_SETTING_DATA,
    BTN_FORM,
    CREATE_PRODUCT,
    HANDLE_ITEM_FORM_DISABLE,
} from './../types';

const initialState = {
    pagination: {
        totalCount: 0,
        activePage: 1,
        itemsCountPerPage: 1,
        totalItemsCount: 10,
        pageRangeDisplayed: 8,
    },
    sales_orders: [],
    status_view: 'all',
    pagination_list: { 0: [], 1: [], 2: [], all: [] },
    single_sales_order: {
        sales_order_no: null,
        quotation_id: null,
        sales_order_items: [],
        lastAddedDropdownIndex: null,
        vat_amount: 0,
        tax: 0,
        default_tax: 12,
        tax_type: { value: 'none', label: 'None' },
        sub_total: 0,
        total: 0,
        customer_id: null,
        other: 0,
        customer: null,
        remarks: '',
        payment_terms: { value: '', label: 'None' },
        receipt_type: { value: 'sales order', label: 'Sales Order' },
        payment_type: { value: '', label: 'None' },
        status: '',
        reference_no: '',
        sales_order_date: new Date(),
        is_manual: false,
        subs_setting: [],
        uploads: [],
        sending_email: false,
        payment_status: '',
        delivery_status: '',
        download_pdf: false,
        form_disable: false,
    },
    tax_types: [
        { value: 'none', label: 'None' },
        { value: 'exclusive', label: 'Exclusive' },
        { value: 'inclusive', label: 'Inclusive' },
    ],
    subs_setting: null,
    new_uploads: [],
    files_to_remove: [],
    edit_sales_order_items: [],
    customers: [],
    quotation_options: [],
    receipt_type_options: [
        { value: 'sales order', label: 'Sales Order' },
        { value: 'delivery receipt', label: 'Delivery Receipt' },
    ],
    payment_type_options: [
        { value: '', label: 'None' },
        { value: 'cash', label: 'Cash' },
        { value: 'credit card', label: 'Credit Card' },
        { value: 'cod', label: 'COD' },
        { value: 'check', label: 'Check' },
        { value: 'bank transfer', label: 'Bank Transfer' },
    ],
    items: [],
    terms_conditions: [],
    sales_order_templates: [],
    si_default_template: [],
    itemSelected: {
        _id: '',
        amount: 0.0,
        srp: 0.0,
        product_name: '',
        product_unit_id: '',
        product_unit_name: '',
        qty: 0,
        sku: '',
    },
    edit_attr: {
        discount: 0,
        vat: 0,
        other: 0.0,
    },
    default_product_value: { value: '', label: 'Choose Product' },
    default_customer_value: { value: '', label: '' },
    isSearchable: true,
    isDiscount: false,
    isVat: false,
    isOther: false,
    loading: false,
    mini_loading: false,
    delete_modal: false,
    edit_row_index: null,
    main_page: false,
    edit_page: false,
    print_modal_dr: false,
    print_modal_si: false,
    main_url: '/sales/sales-orders',
    create_url: '/sales/sales-orders/create',
    edit_url: '/sales/sales-orders/edit',
    item_id: null,
    customer_modal: false,
    payment_terms_modal: false,
    is_disabled: true,
    sort_order_name: null,
    sort_order_direction: null,
    rows_to_delete: null,
    diselect_row: false,
    set_row_checked: false,
    id_obj: null,
    item_template: {
        _id: '',
        amount: 0.0,
        srp: 0.0,
        unit_price: 0.0,
        product_name: '',
        product_unit_id: '',
        product_id: '',
        product_unit_name: '',
        qty: 0,
        items: [],
        new: true,
        description: '',
        barcode: '',
        selected: false,
        non_inventory: false,
        sku: '',
    },
    sending_email: false,
    download_pdf: false,
    email_config: [],
    btn_form: {
        save: false,
        draft: false,
        email_save: false,
        cancel: false,
        approve: false,
    },
    search: '',
    form_disable: false,
};

const salesOrderReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SALES_ORDERS:
            return {
                ...state,
                sales_orders: action.payload.sales_orders,
                pagination: action.payload.pagination,
                set_row_checked: false,
            };
        case GET_SO_STATUS:
            return {
                ...state,
                sales_orders: action.payload.sales_orders,
                pagination: action.payload.pagination,
                pagination_list: action.payload.pagination_list,
            };
        case GET_SINGLE_SALES_ORDER:
            return {
                ...state,
                single_sales_order: {
                    ...state.single_sales_order,
                    ...action.payload.single_sales_order,
                    sales_order_items: action.payload.sales_order_items,
                },
                edit_sales_order_items: action.payload.sales_order_items,
                edit_attr: action.payload.edit_attr,
                default_customer_value: action.payload.default_customer_value,
                si_default_template: action.payload.si_default_template,
                subs_setting: action.payload.subs_setting,
            };
        case GET_SI_TERMS_CONDITIONS:
            return {
                ...state,
                terms_conditions: action.payload,
            };
        case GET_SI_PRODUCTS:
            return {
                ...state,
                items: action.payload,
                item_template: {
                    ...state.item_template,
                    items: action.payload,
                },
            };
        case GET_SI_CUSTOMERS:
            return {
                ...state,
                customers: action.payload,
            };
        case HANDLE_SI_SELECT_CUSTOMER:
            return {
                ...state,
                single_sales_order: {
                    ...state.single_sales_order,
                    customer_id: action.payload.customer_id,
                    customer: action.payload.customer,
                },
                default_customer_value: action.payload.default_customer_value,
            };
        case HANDLE_SI_SELECT_ITEM:
            return {
                ...state,
                itemSelected: {
                    ...state.itemSelected,
                    ...action.payload.item_selected,
                },
                default_product_value: action.payload.default_product_value,
            };
        case HANDLE_SI_ADD_ITEM:
            return {
                ...state,
                single_sales_order: {
                    ...state.single_sales_order,
                    sales_order_items: action.payload.sales_order_items,
                },
                edit_sales_order_items: action.payload.sales_order_items,
                edit_row_index: null,
                itemSelected: {
                    _id: '',
                    amount: 0.0,
                    srp: 0.0,
                    product_name: '',
                    product_unit_id: '',
                    product_unit_name: '',
                    qty: 0,
                    sku: '',
                },
                default_product_value: { value: '', label: 'Choose Product' },
            };
        case HANDLE_SI_REMOVE_ITEM:
            return {
                ...state,
                single_sales_order: {
                    ...state.single_sales_order,
                    sales_order_items: action.payload.sales_order_items,
                },
                edit_sales_order_items: action.payload.sales_order_items,
                edit_row_index: null,
            };
        case HANDLE_SI_SELECT_TERMS_CONDITIONS:
            return {
                ...state,
                single_sales_order: {
                    ...state.single_sales_order,
                    payment_terms: action.payload,
                },
            };
        case HANDLE_SI_SELECT_PAYMENT:
            return {
                ...state,
                single_sales_order: {
                    ...state.single_sales_order,
                    payment: action.payload,
                },
            };
        case HANDLE_SI_SRP:
            return {
                ...state,
                itemSelected: {
                    ...state.itemSelected,
                    srp: action.payload.srp,
                    amount: action.payload.amount,
                },
            };
        case HANDLE_SI_QTY:
            return {
                ...state,
                itemSelected: {
                    ...state.itemSelected,
                    qty: action.payload.qty,
                    amount: action.payload.amount,
                },
            };
        case HANDLE_SI_TOGGLE_EDIT_ROW:
            return {
                ...state,
                edit_row_index: action.payload,
            };
        case HANDLE_SI_EDIT_INPUT_ROW:
            return {
                ...state,
                edit_sales_order_items: action.payload,
            };
        case HANDLE_SI_EDIT_UPDATE_ROW:
            return {
                ...state,
                single_sales_order: {
                    ...state.single_sales_order,
                    sales_order_items: action.payload,
                },
                edit_row_index: null,
            };
        case HANDLE_SI_INPUT_ATTR:
            return {
                ...state,
                edit_attr: {
                    ...state.edit_attr,
                    [action.payload.key]: action.payload.value,
                },
            };
        case HANDLE_SI_SUBMIT_ATTR:
            return {
                ...state,
                single_sales_order: {
                    ...state.single_sales_order,
                    [action.payload.key]: action.payload.value,
                },
                isDiscount: false,
                isOther: false,
            };
        case HANDLE_SI_TOGGLE_EDIT_BTN_ATTR:
            return {
                ...state,
                [action.payload.attr]: action.payload.status,
            };
        case HANDLE_SI_CHECK_VAT:
            return {
                ...state,
                single_sales_order: {
                    ...state.single_sales_order,
                    vat: action.payload,
                },
            };
        case SALES_ORDER_INPUT_CHANGE:
            return {
                ...state,
                single_sales_order: {
                    ...state.single_sales_order,
                    [action.payload.key]: action.payload.value,
                },
            };
        case GET_SI_TEMPLATES:
            return {
                ...state,
                single_sales_order: {
                    ...state.single_sales_order,
                    template_id: action.payload.template_id,
                },
                sales_order_templates: action.payload.sales_order_templates,
                si_default_template: action.payload.si_default_template,
            };
        case HANDLE_SELECT_SI_TEMPLATE:
            return {
                ...state,
                single_sales_order: {
                    ...state.single_sales_order,
                    template_id: action.payload.template_id,
                },
                si_default_template: action.payload.si_default_template,
            };
        case HANDLE_SI_SELECT_TYPE_RECEIPT:
            return {
                ...state,
                single_sales_order: {
                    ...state.single_sales_order,
                    receipt_type: action.payload.receipt_type,
                },
                default_select_type_receipt:
                    action.payload.default_select_type_receipt,
            };
        case HANDLE_SI_SELECT_PAYMENT_TYPE:
            return {
                ...state,
                single_sales_order: {
                    ...state.single_sales_order,
                    payment_type: action.payload,
                },
            };
        case SET_PAGE:
            if (action.payload.page == 'main_page') {
                return {
                    ...state,
                    [action.payload.page]: action.payload.status,
                    edit_page: false,
                };
            } else if (action.payload.page == 'edit_page') {
                return {
                    ...state,
                    main_page: false,
                    edit_url: action.payload.edit_url,
                    [action.payload.page]: action.payload.status,
                };
            } else {
                return {
                    ...state,
                    [action.payload.page]: action.payload.status,
                };
            }
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case SET_MINI_LOADING:
            return {
                ...state,
                mini_loading: action.payload,
            };
        case SEARCH_INPUT:
            return {
                ...state,
                search: action.payload,
            };
        case GET_STATUS:
            return {
                ...state,
                status_view: action.payload,
            };
        case SET_MODAL:
            return {
                ...state,
                [action.payload.modal]: action.payload.status,
            };
        case HANDLE_CHECK_MANUAL:
            return {
                ...state,
                single_sales_order: {
                    ...state.single_sales_order,
                    is_manual: action.payload.is_manual,
                    quotation_no: '',
                    remarks: '',
                    payment_terms: { value: '', label: 'None' },
                    receipt_type: {
                        value: 'sales order',
                        label: 'Sales Order',
                    },
                    payment_type: { value: '', label: 'None' },
                    discount_type: { value: '', label: 'None' },
                    discount: 0,
                    other: 0,
                    sales_order_items: action.payload.sales_order_items,
                    customer: null,
                },
                default_customer_value: { value: '', label: '' },
            };
        case SO_GET_QUOTATIONS:
            return {
                ...state,
                quotation_options: action.payload,
            };
        case HANDLE_SO_SELECT_QUOTATION:
            return {
                ...state,
                single_sales_order: {
                    ...state.single_sales_order,
                    ...action.payload.single_sales_order,
                    sales_order_items: action.payload.sales_order_items,
                },
                edit_sales_order_items: action.payload.sales_order_items,
                edit_attr: action.payload.edit_attr,
                default_customer_value: action.payload.default_customer_value,
            };
        case CLEAR_SALES_ORDER:
            return {
                ...state,
                single_sales_order: {
                    sales_order_no: null,
                    quotation_id: null,
                    sales_order_items: [],
                    lastAddedDropdownIndex: null,
                    vat_amount: 0,
                    tax: 0,
                    default_tax: 12, // this will be the default tax
                    tax_type: { value: 'none', label: 'None' },
                    sub_total: 0,
                    total: 0,
                    discount: 0,
                    discount_type: { value: 'none', label: 'None' },
                    showDiscountRow: false,
                    customer_id: null,
                    other: 0,
                    customer: null,
                    remarks: '',
                    payment_terms: { value: '', label: 'None' },
                    receipt_type: {
                        value: 'sales order',
                        label: 'Sales Order',
                    },
                    payment_type: { value: '', label: 'None' },
                    status: '',
                    reference_no: '',
                    sales_order_date: new Date(),
                    is_manual: false,
                    subs_setting: [],
                    uploads: [],
                    sending_email: false,
                    payment_status: '',
                    delivery_status: '',
                    download_pdf: false,
                    form_disable: false,
                },
                tax_types: [
                    { value: 'none', label: 'None' },
                    { value: 'exclusive', label: 'Exclusive' },
                    { value: 'inclusive', label: 'Inclusive' },
                ],
                itemSelected: {
                    _id: '',
                    amount: 0.0,
                    srp: 0.0,
                    product_name: '',
                    product_unit_id: '',
                    product_unit_name: '',
                    qty: 0,
                    sku: '',
                },
                edit_attr: {
                    discount: 0,
                    vat: 0,
                    other: 0.0,
                },
                default_product_value: { value: '', label: 'Choose Product' },
                edit_sales_order_items: [],
                isDiscount: false,
                isVat: false,
                isOther: false,
                loading: false,
                mini_loading: false,
                create_modal: false,
                edit_modal: false,
                view_modal: false,
                delete_modal: false,
                create_si_modal: false,
                edit_row_index: null,
                main_page: false,
                edit_page: false,
                print_modal: false,
                main_url: '/sales/sales-orders',
                create_url: '/sales/sales-orders/create',
                edit_url: '/sales/sales-orders/edit',
                item_id: null,
                customer_modal: false,
                payment_terms_modal: false,
                is_manual: false,
                id_obj: null,
            };
        case SET_SI_ITEM_ID:
            return {
                ...state,
                item_id: action.payload,
            };
        case SET_DATE_PICKER:
            return {
                ...state,
                single_sales_order: {
                    ...state.single_sales_order,
                    sales_order_date: action.payload,
                },
            };
        case SORT_BY_COLUMN:
            return {
                ...state,
                sort_order_name: action.payload.sort_order_name,
                sort_order_direction: action.payload.sort_order_direction,
            };
        case SET_QU_ITEM_ID:
            return {
                ...state,
                id_obj: action.payload,
            };
        case GET_ROWS_TO_DELETE:
            return {
                ...state,
                rows_to_delete: action.payload,
            };
        case SET_ROW_CHECKED:
            return {
                ...state,
                set_row_checked: true,
            };
        case SO_UPDATE_FILE:
            return {
                ...state,
                new_uploads: [...state.new_uploads, ...action.payload],
            };
        case SO_REMOVE_FILE:
            if (action.payload.status == 'old') {
                return {
                    ...state,
                    single_sales_order: {
                        ...state.single_sales_order,
                        uploads: [
                            ...state.single_sales_order.uploads.filter(
                                (file) => file.name !== action.payload.name
                            ),
                        ],
                    },
                };
            } else {
                return {
                    ...state,
                    new_uploads: [
                        ...state.new_uploads.filter(
                            (file) => file.name !== action.payload.name
                        ),
                    ],
                };
            }
        case SO_UPLOADS_TO_REMOVE:
            return {
                ...state,
                files_to_remove: [...state.files_to_remove, action.payload],
            };
        case SO_GET_UPLOADED_FILES:
            return {
                ...state,
                single_sales_order: {
                    ...state.single_sales_order,
                    uploads: action.payload,
                },
                new_uploads: [],
                files_to_remove: [],
            };
        case ADD_ITEM:
            return {
                ...state,
                single_sales_order: {
                    ...state.single_sales_order,
                    sales_order_items: action.payload,
                },
            };
        case TOGGLE_DISCOUNT_ROW:
            const updatedItems = state.single_sales_order.sales_order_items.map(
                (item, index) => {
                    if (index === action.payload.index) {
                        return {
                            ...item,
                            showDiscountRow: !action.payload.remove,
                        };
                    } else {
                        return item;
                    }
                }
            );

            return {
                ...state,
                single_sales_order: {
                    ...state.single_sales_order,
                    sales_order_items: updatedItems,
                },
            };
        case SET_LAST_ADDED_DROPDOWN_INDEX:
            return {
                ...state,
                single_sales_order: action.payload,
            };
        case SI_SET_CUSTOMER_DATA:
            return {
                ...state,
                default_customer_value: action.payload,
            };
        case SI_CLEAR_UPLOAD:
            return {
                ...state,
                new_uploads: [],
            };
        case SI_SELECT_TAX_TYPE:
            return {
                ...state,
                single_sales_order: {
                    ...state.single_sales_order,
                    tax: action.payload.tax,
                    tax_type: action.payload.tax_type,
                },
            };
        case GET_SI_SUB_SETTING:
            return {
                ...state,
                single_sales_order: {
                    ...state.single_sales_order,
                    ...action.payload,
                },
            };
        case SI_COMPUTATION_HANDLER:
            return {
                ...state,
                single_sales_order: {
                    ...state.single_sales_order,
                    ...action.payload,
                },
            };
        case SEND_EMAIL:
            return {
                ...state,
                sending_email: action.payload,
            };
        case RESEND_EMAIL:
            return {
                ...state,
                single_sales_order: {
                    ...state.single_sales_order,
                    email_sent: action.payload,
                },
            };
        case DYNAMIC_SEND_EMAIL:
            return {
                ...state,
                sales_orders: action.payload,
            };
        case DOWNLOAD_BTN:
            return {
                ...state,
                download_pdf: action.payload,
            };
        case EMAIL_SETTING_DATA:
            return {
                ...state,
                email_config: action.payload,
            };
        case BTN_FORM:
            return {
                ...state,
                btn_form: action.payload,
            };
        case HANDLE_ITEM_FORM_DISABLE:
            return {
                ...state,
                form_disable: action.payload,
            };
        case CREATE_PRODUCT:
            const updatedSalesOrderItems =
                state.single_sales_order.sales_order_items.map((item, index) =>
                    index === action.payload.index
                        ? {
                              ...item,
                              non_inventory: action.payload.non_inventory,
                          }
                        : item
                );

            return {
                ...state,
                single_sales_order: {
                    ...state.single_sales_order,
                    sales_order_items: updatedSalesOrderItems,
                },
            };
        default:
            return state;
    }
};

export default salesOrderReducer;
