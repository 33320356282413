import React from 'react';
import { Show, useAccess } from 'react-redux-permission';
import DailySalesTable from './dailySales.table';

function DailySales() {
    return (
        <Show when="inv.dashboard.daily-sales">
            <div
                role="tabpanel"
                className="tab-pane w-100"
                id="tabs-stocklogs"
                aria-expanded="true"
                aria-labelledby="base-tab2"
            >
                <DailySalesTable />
            </div>
        </Show>
    );
}

export default DailySales;
