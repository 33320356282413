import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { permissionsReducer as permissions } from 'react-redux-permission';

// All reducers
import brandReducer from './brand.reducer';
import customerReducer from './customer.reducer';
import supplierReducer from './supplier.reducer';
import termConditionReducer from './term_condition.reducer';
import categoryReducer from './category.reducer';
import attributeTemplateReducer from './attribute_template.reducer';
import productReducer from './product.reducer';
import purchaseOrderReducer from './purchase_order.reducer';
import quotationReducer from './quotation.reducer';
import salesInvoiceReducer from './sales_invoice.reducer';
import invoiceReducer from './invoice.reducer';
import salesOrderReducer from './sales_order.reducer';
import productUnitReducer from './product_unit.reducer';
// import purchaseOrderSettingReducer from './_backup/purchase_order_setting.reducer';
import salesInvoiceSettingReducer from './sales_invoice_setting.reducer';
// import quotationSettingReducer from './quotation_setting.reducer';
// import deliverySettingReducer from './delivery_setting.reducer';
import logReducer from './log.reducer';
import userLogReducer from './user_log.reducer';
import receivingReducer from './receiving.reducer';
import inventoryReducer from './inventory.reducer';
import userReducer from './settings/users.reducer';
import rolesReducer from './settings/roles.reducer';
import deliveryReducer from './delivery.reducer';
import deliveryadjsRecuder from './deliveryadjs.reducer';
import authReducer from './auth.reducer';
import posReducer from './pos.reducer';
import settingAppReducer from './setting_app.reducer';
import helperReducer from './helper.reducer';
import profileReducer from './profile.reducer';
import homeReducer from './home.reducer';
import lotnoDataReducer from './lotnodata.reducer';
import emailsettingrecuder from './emailsettings.reducer';
import forgetReducer from './forgetpass.reducer';
import purchaseOrderTemplateSettingReducer from './po_template_setting.reducer';
import quotationTemplateSettingReducer from './qu_template_setting.reducer';
import salesInvoiceTemplateSettingReducer from './si_template_setting.reducer';
import ItemReceiptTemplateSettingReducer from './ir_template_setting.reducer';
import deliveryTemplateSettingReducer from './dr_template_setting.reducer';
import templateSettingGlobalReducer from './template_setting_global.reducer';
import shippingCostReducer from './shipping.reducer';
import collectionReceiptReducer from './collection_receipt.reducer';
import stockLogsReducer from './stockLogs.reducer';
import dailySalesReducer from './dailySales.reducer';
import dailyMovementReducer from './dailyMovement.reducer';
import PickAndPackReducer from './pick_and_pack.reducer';
import returnsReducer from './return.reducer';
import stockTransfer from './stock_transfer.reducer';

// persist config whitelist reducer
const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: [], // reducer will be persisted
};

// combining all reducers
const rootReducer = combineReducers({
    brand: brandReducer,
    customer: customerReducer,
    supplier: supplierReducer,
    term_condition: termConditionReducer,
    category: categoryReducer,
    attribute_template: attributeTemplateReducer,
    product: productReducer,
    purchase_order: purchaseOrderReducer,
    po_template_setting: purchaseOrderTemplateSettingReducer,
    quotation: quotationReducer,
    qu_template_setting: quotationTemplateSettingReducer,
    sales_invoice: salesInvoiceReducer,
    invoice: invoiceReducer,
    sales_order: salesOrderReducer,
    product_unit: productUnitReducer,
    si_template_setting: salesInvoiceTemplateSettingReducer,
    log: logReducer,
    user_log: userLogReducer,
    receiving: receivingReducer,
    ir_template_setting: ItemReceiptTemplateSettingReducer,
    delivery: deliveryReducer,
    dr_template_setting: deliveryTemplateSettingReducer,
    deliveryadjs: deliveryadjsRecuder,
    template_setting_global: templateSettingGlobalReducer,
    inventory: inventoryReducer,
    userManagement: userReducer,
    rolesReducer: rolesReducer,
    permissions: permissions,
    auth: authReducer,
    pos: posReducer,
    setting_app: settingAppReducer,
    helper: helperReducer,
    profile: profileReducer,
    home: homeReducer,
    lotnodata: lotnoDataReducer,
    emailsetting: emailsettingrecuder,
    forgot: forgetReducer,
    shipping: shippingCostReducer,
    collection_receipt: collectionReceiptReducer,
    stock_logs: stockLogsReducer,
    daily_sales: dailySalesReducer,
    daily_movement: dailyMovementReducer,
    pick_and_pack: PickAndPackReducer,
    returns: returnsReducer,
    stock_transfer: stockTransfer,
    // purchase_order_setting: purchaseOrderSettingReducer,
    // sales_invoice_setting: salesInvoiceSettingReducer,
    // quotation_setting: quotationSettingReducer,
    // delivery_setting: deliverySettingReducer,
});

export default persistReducer(persistConfig, rootReducer);
