import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { NumberFormat } from '../../Services/_numberformat.service.js';

function DailySalesViewModal({ show, onHide, products }) {
    if (!show) return null;

    return (
        <Modal show={show} onHide={onHide} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Product Sales</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="ds-table-container">
                    <table className="ds-table ds-table-striped">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Name</th>
                                <th>SKU</th>
                                <th>Brand</th>
                                <th>Description</th>
                                <th>POS Qty</th>
                                <th>Sales Order Qty</th>
                                <th>Total Qty</th>
                                <th>Sales Amount</th>
                                <th>Available Stocks</th>
                                <th>Incoming Stocks</th>
                                <th>Draft Incoming Stocks</th>
                            </tr>
                        </thead>
                        <tbody>
                            {products.map((product, index) => (
                                <tr key={index}>
                                    <td>
                                        {product.created_at
                                            ? product.created_at.substr(0, 10)
                                            : 'N/A'}
                                    </td>
                                    <td>{product.product_name || 'N/A'}</td>
                                    <td>{product.sku || 'N/A'}</td>
                                    <td>{product.brand_name || 'N/A'}</td>
                                    <td>{product.description || 'N/A'}</td>
                                    <td>
                                        {NumberFormat(product.pos_qty || 0)}
                                    </td>
                                    <td>
                                        {NumberFormat(
                                            product.sales_order_qty || 0
                                        )}
                                    </td>
                                    <td>
                                        {NumberFormat(product.total_qty || 0)}
                                    </td>
                                    <td>
                                        {NumberFormat(product.total_sales || 0)}
                                    </td>
                                    <td>
                                        {product.available_stocks !==
                                            undefined &&
                                        product.available_stocks !== null
                                            ? NumberFormat(
                                                  product.available_stocks
                                              )
                                            : 'N/A'}
                                    </td>
                                    <td>
                                        {product.incoming_stocks !==
                                            undefined &&
                                        product.incoming_stocks !== null
                                            ? NumberFormat(
                                                  product.incoming_stocks
                                              )
                                            : 'N/A'}
                                    </td>{' '}
                                    <td>
                                        {product.draft_incoming_stocks !==
                                            undefined &&
                                        product.draft_incoming_stocks !== null
                                            ? NumberFormat(
                                                  product.draft_incoming_stocks
                                              )
                                            : 'N/A'}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    show: state.daily_sales.daily_sales_modal,
    products: state.daily_sales.selected_products,
});

export default connect(mapStateToProps)(DailySalesViewModal);
