import React, { Fragment, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
    setModal,
    searchProduct,
    removeScan,
} from '../../../redux/actions/stock_transfer/stock_transfer.actions';
import { numberFormat, onlyNumber } from '../../../Utils/Common';
import { MiniSpinner } from '../../layouts/Spinner';

function ItemModal({ stock_transfer, setModal, searchProduct, removeScan }) {
    // const { transfer_item } = stock_transfer;
    // const _item_total_price = stock_transfer.item_total_price < 0 ? <span className="lbl-status lbl-danger-stock_transfer">NEGATIVE</span> : numberFormat(stock_transfer.item_total_price);
    // const modalSize = stock_transfer.multi_products ? 'md' : 'lg';

    // console.log('stock_transfer', stock_transfer);
    return (
        <Modal
            centered
            dialogClassName="modal-container"
            show={stock_transfer.transfer_item_modal}
            onHide={() => setModal('transfer_item_modal', false)}
            size="md"
            animation={true}
        >
            <Modal.Header closeButton>
                {' '}
                <Modal.Title></Modal.Title>{' '}
            </Modal.Header>
            <Modal.Body className="mt-0 pt-0">
                <div className="row f-regular">
                    <div className="col-12">
                        {stock_transfer.pos_loading == 'item-modal' ? (
                            <div className="col-12">
                                <div className="my-5">
                                    <MiniSpinner />
                                </div>
                            </div>
                        ) : (
                            stock_transfer.products.map((item) => (
                                <div
                                    onClick={
                                        item.inStock
                                            ? () => {
                                                  searchProduct(
                                                      {
                                                          search: {
                                                              _id: item._id,
                                                              barcode:
                                                                  item.barcode,
                                                          },
                                                      },
                                                      'item-modal'
                                                  );

                                                  // Remove the previous scan
                                                  removeScan();
                                                  // Close the modal after selecting an item
                                                  setModal(
                                                      'transfer_item_modal',
                                                      false
                                                  );
                                              }
                                            : () => {}
                                    }
                                    className={`card card-body pos-card-list shadow mb-1 bg-white rounded ${
                                        item.stockWarning &&
                                        'bg-warning bg-accent-2'
                                    } ${
                                        !item.inStock && 'bg-danger bg-accent-2'
                                    }`}
                                >
                                    <div className="row">
                                        <div className="col-2">
                                            {
                                                <img
                                                    src={item.product_image}
                                                    className="img img-thumbnail"
                                                />
                                            }
                                        </div>
                                        <div className="col-6">
                                            {item.product_name}
                                            <div>
                                                <small>SKU: {item.sku}</small>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="float-right">
                                                <small>
                                                    SRP:{' '}
                                                    <span className="pos-strong-text">
                                                        {numberFormat(item.srp)}
                                                    </span>
                                                </small>
                                                <div>
                                                    {item.stockWarning && (
                                                        <span
                                                            className={`lbl-status lbl-pos-warning`}
                                                        >
                                                            LOW THRESHOLD
                                                        </span>
                                                    )}
                                                </div>
                                                <div>
                                                    {item.inStock != true && (
                                                        <span
                                                            className={`lbl-status lbl-danger`}
                                                        >
                                                            OUT OF STOCK
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    stock_transfer: state.stock_transfer,
});

export default connect(mapStateToProps, {
    setModal,
    searchProduct,
    removeScan,
})(ItemModal);
