import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { Show, useAccess } from 'react-redux-permission';
import { Link, useParams, useLocation } from 'react-router-dom';
import Dashboard from './../dashboard/Dashboard';
import DailySales from './../daily_sales/DailySales';
import DailyMovement from '../daily_movement/DailyMovement';
import { setMUIPage } from '../../redux/actions/helper/helper.actions';

function Home({ home: setMUIPage, muiPage }) {
    let params = new URLSearchParams(useLocation().search);
    const [selectedTab, SetSelectedTab] = useState('Dashboard');
    const [tabs, setTabs] = useState([
        {
            key: 'Dashboard',
            class: 'nav-link inv-top-nav inv-top-nav-active',
            _status: 1,
            permission: 'inv.dashboard.view',
        },
        {
            key: 'Daily Sales',
            class: 'nav-link inv-top-nav',
            _status: 0,
            permission: 'inv.dashboard.daily-sales.view',
        },
        {
            key: 'Daily Movement',
            class: 'nav-link inv-top-nav',
            _status: 0,
            permission: 'inv.dashboard.daily-movement.view',
        },
    ]);

    function handleChangeTab(tab) {
        //  setMUIPage(1);
        SetSelectedTab(tab);
        tabs.map((list) => {
            list.class =
                list.key == tab
                    ? 'nav-link inv-top-nav inv-top-nav-active'
                    : 'nav-link inv-top-nav';

            return list;
        });
        setTabs(tabs);
    }

    useEffect(() => {
        let tab = params.get('mode');

        if (tab) {
            handleChangeTab(tab);
            setMUIPage(muiPage);
        }
    }, []);

    return (
        <div className="app-content content">
            <ReactTooltip effect="solid" event="click" />
            <div className="content-wrapper max-1920 mx-auto">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block">
                            Dashboard{' '}
                        </h3>
                    </div>
                </div>

                <div className="content-body">
                    <section className="row f-regular">
                        <div className="col-sm-12">
                            <div className="card">
                                {/* <div className="card-header"></div> */}
                                <div className="card-content collapse show">
                                    <div className="card-body">
                                        <div className="">
                                            <nav class="nav f-regular nav-product-inv">
                                                {tabs.map((item) => (
                                                    <Show
                                                        when={item.permission}
                                                    >
                                                        <a
                                                            onClick={() =>
                                                                handleChangeTab(
                                                                    item.key
                                                                )
                                                            }
                                                            class={item.class}
                                                        >
                                                            {item.key}
                                                        </a>
                                                    </Show>
                                                ))}
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="row f-regular">
                        <div className="col-sm-12">
                            {(() => {
                                switch (selectedTab) {
                                    case 'Dashboard':
                                        return <Dashboard />;
                                    case 'Daily Sales':
                                        return <DailySales />;
                                    case 'Daily Movement':
                                        return <DailyMovement />;
                                    default:
                                        return null;
                                }
                            })()}
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => ({
    home: state.home,
    muiPage: state.helper.muiPage,
});

export default connect(mapStateToProps, { setMUIPage })(Home);
