/* Common types -> common types use in all reducers*/
export const SET_LOADING = 'SET_LOADING';
// export const SET_SUCCESS = 'SET_SUCCESS';
export const SET_MINI_LOADING = 'SET_MINI_LOADING';
export const SET_DYNAMIC_LOADING = 'SET_DYNAMIC_LOADING';
export const SET_MODAL = 'SET_MODAL';
export const SET_PAGE = 'SET_PAGE';
export const SEARCH_INPUT = 'SEARCH_INPUT';
export const SORT_BY_COLUMN = 'SORT_BY_COLUMN';
export const GET_ROWS_TO_DELETE = 'GET_ROWS_TO_DELETE';
export const SET_ROW_CHECKED = 'SET_ROW_CHECKED';
export const SET_DATE_PICKER = 'SET_DATE_PICKER';
export const HANDLE_CHECK_MANUAL = 'HANDLE_CHECK_MANUAL';
export const HANDLE_HOVER = 'HANDLE_HOVER';
export const NEW_ATTACHMENT = 'NEW_ATTACHMENT';
export const GET_SUB_SETTING = 'GET_SUB_SETTING';

// status tabs -> saved & draft
export const GET_STATUS = 'GET_STATUS';

/* HELPER */
export const BLOCK_ROUTE = 'BLOCK_ROUTE';
export const REQUIRED_INPUT = 'REQUIRED_INPUT';
export const SET_PROGRESS = 'SET_PROGRESS';
export const SET_MUI_PAGE = 'SET_MUI_PAGE';

/* End of Common types */

/* Brand */
export const GET_BRANDS = 'GET_BRANDS';
export const GET_SINGLE_BRAND = 'GET_SINGLE_BRAND';
export const CLEAR_BRAND = 'CLEAR_BRAND';
export const BRAND_INPUT_CHANGE = 'BRAND_INPUT_CHANGE';
export const BRAND_SEARCH = 'BRAND_SEARCH';

/* Customer */
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const GET_SINGLE_CUSTOMER = 'GET_SINGLE_CUSTOMER';
export const CUSTOMER_INPUT_CHANGE = 'CUSTOMER_INPUT_CHANGE';
export const CLEAR_CUSTOMER = 'CLEAR_CUSTOMER';
export const SELECT_COUNTRY = 'SELECT_COUNTRY';
export const HANDLE_MOBILE = 'HANDLE_MOBILE';
export const CUSTOMER_REMOVE_FILE = 'CUSTOMER_REMOVE_FILE';
export const ADD_FILE = 'ADD_FILE';
export const CUSTOMER_UPDATE_FILE = 'CUSTOMER_UPDATE_FILE';
export const CUSTOMER_UPLOADS_TO_REMOVE = 'CUSTOMER_UPLOADS_TO_REMOVE';
export const GET_CUSTOMER_HISTORY_SALES_INVOICES =
    'GET_CUSTOMER_HISTORY_SALES_INVOICES';
export const GET_CUSTOMER_HISTORY_QUOTATIONS =
    'GET_CUSTOMER_HISTORY_QUOTATIONS';
export const DATE_INPUT_CLEAR = 'DATE_INPUT_CLEAR';
export const DATE_FILTER_INPUT = 'DATE_FILTER_INPUT';
export const GET_QUOTATION_DATE_FILTER = 'GET_QUOTATION_DATE_FILTER';
export const GET_SALES_INVOICE_DATE_FILTER = 'GET_SALES_INVOICE_DATE_FILTER';
export const CLEAR_CUSTOMER_HISTORY = 'CLEAR_CUSTOMER_HISTORY';
export const GET_CUSTOMER_HISTORY_SINGLE_QUOTATION =
    'GET_CUSTOMER_HISTORY_SINGLE_QUOTATION';
export const GET_CUSTOMER_HISTORY_SINGLE_SALES_INVOICE =
    'GET_CUSTOMER_HISTORY_SINGLE_SALES_INVOICE';
export const UPLOADS_TO_REMOVE = 'UPLOADS_TO_REMOVE';
export const CUSTOMER_HISTORY_DATE_INPUT_CLEAR =
    'CUSTOMER_HISTORY_DATE_INPUT_CLEAR';
export const CUSTOMER_GET_UPLOADED_FILES = 'CUSTOMER_GET_UPLOADED_FILES';
export const CUSTOMER_CLEAR_UPLOAD = 'CUSTOMER_CLEAR_UPLOAD';

/* Supplier */
export const GET_SUPPLIERS = 'GET_SUPPLIERS';
export const GET_SINGLE_SUPPLIER = 'GET_SINGLE_SUPPLIER';
export const SUPPLIER_INPUT_CHANGE = 'SUPPLIER_INPUT_CHANGE';
export const CLEAR_SUPPLIER = 'CLEAR_SUPPLIER';
export const GET_SUPPLIER_HISTORY_PURCHASE_ORDERS =
    'GET_SUPPLIER_HISTORY_PURCHASE_ORDERS';
export const GET_SUPPLIER_HISTORY_PRODUCTS = 'GET_SUPPLIER_HISTORY_PRODUCTS';
export const SUPPLIER_HISTORY_DATE_FILTER_INPUT =
    'SUPPLIER_HISTORY_DATE_FILTER_INPUT';
export const SUPPLIER_HISTORY_PURCHASE_ORDER_DATE_FILTER =
    'SUPPLIER_HISTORY_PURCHASE_ORDER_DATE_FILTER';
export const SUPPLIER_HISTORY_DATE_INPUT_CLEAR =
    'SUPPLIER_HISTORY_DATE_INPUT_CLEAR';
export const GET_SUPPLIER_HISTORY_SINGLE_PO = 'GET_SUPPLIER_HISTORY_SINGLE_PO';
export const GET_SUPPLIER_HISTORY_SINGLE_PRODUCT =
    'GET_SUPPLIER_HISTORY_SINGLE_PRODUCT';
export const SET_PAYMENT_DETAILS = 'SET_PAYMENT_DETAILS';

/* Term Condition || PAYMENT TERMS */
export const GET_TERMS_CONDITIONS = 'GET_TERMS_CONDITIONS';
export const GET_SINGLE_TERM_CONDITION = 'GET_SINGLE_TERM_CONDITION';
export const CLEAR_TERM_CONDITION = 'CLEAR_TERM_CONDITION';
export const TERM_CONDITION_INPUT_CHANGE = 'TERM_CONDITION_INPUT_CHANGE';
export const TERMS_TOGGLE_LIST = 'TERMS_TOGGLE_LIST';

/* Category */
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_PARENT_CATEGORY = 'GET_PARENT_CATEGORY';
export const SET_PARENT_CATEGORY = 'SET_PARENT_CATEGORY';
export const GET_SINGLE_CATEGORY = 'GET_SINGLE_CATEGORY';
export const CLEAR_CATEGORY = 'CLEAR_CATEGORY';
export const CATEGORY_INPUT_CHANGE = 'CATEGORY_INPUT_CHANGE';
export const PARENT_CATEGORY_CHECKED = 'PARENT_CATEGORY_CHECKED';

/* Delivery Receive */
export const GET_DELIVERY_RECEIVES = 'GET_DELIVERY_RECEIVES';
export const GET_SINGLE_DELIVERY_RECEIVE = 'GET_SINGLE_DELIVERY_RECEIVE';
export const DELIVERY_RECEIVE_INPUT_CHANGE = 'DELIVERY_RECEIVE_INPUT_CHANGE';
export const DELIVERY_RECEIVE_ITEM_CHANGE = 'DELIVERY_RECEIVE_ITEM_CHANGE';
export const CLEAR_DELIVERY_RECEIVE = 'CLEAR_DELIVERY_RECEIVE';
export const GET_DR_PURCHASE_ORDERS = 'GET_DR_PURCHASE_ORDERS';
export const GET_SINGLE_DR_PURCHASE_ORDER = 'GET_SINGLE_DR_PURCHASE_ORDER';
export const HANDLE_SELECT_PO_NO = 'HANDLE_SELECT_PO_NO';
export const SET_PO = 'SET_PO';
export const SET_PO_ITEMS = 'SET_PO_ITEMS';
export const PO_ITEMS_TEMPLATE = 'PO_ITEMS_TEMPLATE';
export const ITEMS_TEMPLATE = 'ITEMS_TEMPLATE';

/* Delivery Release */
export const GET_DELIVERY_RELEASES = 'GET_DELIVERY_RELEASES';
export const GET_SINGLE_DELIVERY_RELEASE = 'GET_SINGLE_DELIVERY_RELEASE';
export const DELIVERY_RELEASE_INPUT_CHANGE = 'DELIVERY_RECEIVE_INPUT_CHANGE';
export const DELIVERY_RELEASE_ITEM_CHANGE = 'DELIVERY_RELEASE_ITEM_CHANGE';
export const CLEAR_DELIVERY_RELEASE = 'CLEAR_DELIVERY_RELEASE';
export const GET_DR_SALES_INVOICES = 'GET_DR_SALES_INVOICES';
export const GET_DR_SINGLE_SALES_INVOICE = 'GET_DR_SINGLE_SALES_INVOICE';
export const HANDLE_SELECT_SI_NO = 'HANDLE_SELECT_SI_NO';

/* Attribute Template */
export const GET_ATTRIBUTE_TEMPLATES = 'GET_ATTRIBUTE_TEMPLATES';
export const GET_ATTRIBUTES_FOR_TEMPLATE = 'GET_ATTRIBUTES_FOR_TEMPLATE';
export const GET_SINGLE_ATTRIBUTE_TEMPLATE = 'GET_SINGLE_ATTRIBUTE_TEMPLATE';
export const CLEAR_ATTRIBUTE_TEMPLATE = 'CLEAR_ATTRIBUTE_TEMPLATE';
export const ATTRIBUTE_TEMPLATE_INPUT_CHANGE =
    'ATTRIBUTE_TEMPLATE_INPUT_CHANGE';
export const ATTRIBUTE_TEMPLATE_INPUT_CHECK = 'ATTRIBUTE_TEMPLATE_INPUT_CHECK';
export const UPDATE_ATTRIBUTES_WITH_CHECK = 'UPDATE_ATTRIBUTES_WITH_CHECK';
export const GET_UNIT_MEASUREMENT = 'GET_UNIT_MEASUREMENT';
export const SET_UNIT_MEASUREMENT = 'SET_UNIT_MEASUREMENT';
export const PRODUCT_UPLOAD_ATTRIBUTE_TEMPLATES =
    'PRODUCT_UPLOAD_ATTRIBUTE_TEMPLATES';

/* Attribute */
export const GET_ATTRIBUTES = 'GET_ATTRIBUTES';
export const SET_ATTRIBUTE_OPTIONS = 'SET_ATTRIBUTE_OPTIONS';
export const GET_SINGLE_ATTRIBUTE = 'GET_SINGLATTRIBUTEND';
export const CLEAR_ATTRIBUTE = 'CLEAR_ATTRIBUTE';
export const ATTRIBUTE_INPUT_CHANGE = 'ATTRIBUTE_INPUT_CHANGE';
export const HANDLE_SELECT_TYPE = 'HANDLE_SELECT_TYPE';
export const HANDLE_INPUT_CHANGE_AT_OPTIONS = 'HANDLE_INPUT_CHANGE_AT_OPTIONS';
export const ADD_INPUT_OPTION = 'ADD_INPUT_OPTION';
export const REMOVE_INPUT_OPTION = 'REMOVE_INPUT_OPTION';
export const SET_ATTR_LIST = 'SET_ATTR_LIST';

/* Product */
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_SINGLE_PRODUCT = 'GET_SINGLE_PRODUCT';
export const HOVER_PRODUCT = 'HOVER_PRODUCT';
export const CLEAR_PRODUCT = 'CLEAR_PRODUCT';
export const PRODUCT_INPUT_CHANGE = 'PRODUCT_INPUT_CHANGE';
export const GET_SELECT_ALL = 'GET_SELECT_ALL';
export const PRODUCT_HANDLE_SELECT = 'PRODUCT_HANDLE_SELECT';
export const PRODUCT_ATTR_HANDLE_SELECT = 'PRODUCT_ATTR_HANDLE_SELECT';
export const HANDLE_UPDATE_ATTR_LIST = 'HANDLE_UPDATE_ATTR_LIST';
export const HANDLE_ATTR_CHECKBOX = 'HANDLE_ATTR_CHECKBOX';
export const RESET_ATTRIBUTE_LIST_VALUE = 'RESET_ATTRIBUTE_LIST_VALUE';
export const HANDLE_ITEM_INPUT_UPLOAD = 'HANDLE_ITEM_INPUT_UPLOAD';
export const HANDLE_ITEM_REMOVE_UPLOAD = 'HANDLE_ITEM_REMOVE_UPLOAD';
export const HANDLE_ITEM_FORM_DISABLE = 'HANDLE_ITEM_FORM_DISABLE';
export const HANDLE_ITEM_SELECT_SUPPLIER = 'HANDLE_ITEM_SELECT_SUPPLIER';
export const HANDLE_SUPPLIER_COST = 'HANDLE_SUPPLIER_COST';
export const HANDLE_ADD_VOLUME_PRICING = 'HANDLE_ADD_VOLUME_PRICING';
export const HANDLE_REMOVE_VOLUME_PRICING = 'HANDLE_REMOVE_VOLUME_PRICING';
export const HANDLE_INPUT_VOLUME_PRICING = 'HANDLE_INPUT_VOLUME_PRICING';
export const HANDLE_SELECT_DISCOUNT_TYPE_VOLUME_PRICING =
    'HANDLE_SELECT_DISCOUNT_TYPE_VOLUME_PRICING';
export const HANDLE_ON_CHANGE_ITEM_TAGS = 'HANDLE_ON_CHANGE_ITEM_TAGS';
export const HANDLE_REMOVE_ITEM_TAG = 'HANDLE_REMOVE_ITEM_TAG';
export const UPDATE_ITEM_TAGS = 'UPDATE_ITEM_TAGS';
export const COPY_SKU_TO_BARCODE = 'COPY_SKU_TO_BARCODE';
export const EDIT_BARCODE = 'EDIT_BARCODE';
export const IS_SALE_PRICE = 'IS_SALE_PRICE';
export const IS_TRACK = 'IS_TRACK';
export const SET_LOADING_TAG = 'SET_LOADING_TAG';
export const ADD_NEW_CREATED_TAG = 'ADD_NEW_CREATED_TAG';
export const UPDATE_SUPPLIER = 'UPDATE_SUPPLIER';

/* Purchase Order (PO) */
export const PO_TOGGLE_DISCOUNT_ROW = 'PO_TOGGLE_DISCOUNT_ROW';
export const GET_PURCHASE_ORDERS = 'GET_PURCHASE_ORDERS';
export const GET_SINGLE_PURCHASE_ORDER = 'GET_SINGLE_PURCHASE_ORDER';
export const CLEAR_PURCHASE_ORDER = 'CLEAR_PURCHASE_ORDER';
export const PURCHASE_ORDER_INPUT_CHANGE = 'PURCHASE_ORDER_INPUT_CHANGE';
export const GET_PO_SUPPLIERS = 'GET_PO_SUPPLIERS';
export const GET_PO_SUPPLIERS_DEFAULT = 'GET_PO_SUPPLIERS_DEFAULT';
export const GET_PO_PAYMENT_TERMS = 'GET_PO_PAYMENT_TERMS';
export const HANDLE_PO_SELECT_SUPPLIER = 'HANDLE_PO_SELECT_SUPPLIER';
export const PO_GET_SUPPLIER_DATA = 'PO_GET_SUPPLIER_DATA';
export const PO_GET_SUPPLIER_ITEMS = 'PO_GET_SUPPLIER_ITEMS';
export const HANDLE_PO_COST = 'HANDLE_PO_COST';
export const HANDLE_PO_QTY = 'HANDLE_PO_QTY';
export const HANDLE_PO_SELECT_ITEM = 'HANDLE_PO_SELECT_ITEM';
export const HANDLE_PO_ADD_ITEM = 'HANDLE_PO_ADD_ITEM';
export const HANDLE_PO_REMOVE_ITEM = 'HANDLE_PO_REMOVE_ITEM';
export const HANDLE_PO_INPUT_ATTR = 'HANDLE_PO_INPUT_ATTR';
export const HANDLE_PO_SUBMIT_ATTR = 'HANDLE_PO_SUBMIT_ATTR';
export const HANDLE_PO_TOGGLE_EDIT_BTN_ATTR = 'HANDLE_PO_TOGGLE_EDIT_BTN_ATTR';
export const HANDLE_PO_SELECT_PAYMENT_TERMS = 'HANDLE_PO_SELECT_PAYMENT_TERMS';
export const HANDLE_PO_TOGGLE_EDIT_ROW = 'HANDLE_PO_TOGGLE_EDIT_ROW';
export const HANDLE_PO_EDIT_INPUT_ROW = 'HANDLE_PO_EDIT_INPUT_ROW';
export const HANDLE_PO_EDIT_UPDATE_ROW = 'HANDLE_PO_EDIT_UPDATE_ROW';
export const HANDLE_PO_CHECK_VAT = 'HANDLE_PO_CHECK_VAT';
export const SET_PO_ITEM_ID = 'SET_PO_ITEM_ID';
export const HANDLE_PO_SELECT_PAYMENT_MODE = 'HANDLE_PO_SELECT_PAYMENT_MODE';
export const GET_PO_TEMPLATES = 'GET_PO_TEMPLATES';
export const HANDLE_SELECT_PO_TEMPLATE = 'HANDLE_SELECT_PO_TEMPLATE';
export const PO_UPDATE_FILE = 'PO_UPDATE_FILE';
export const PO_REMOVE_FILE = 'PO_REMOVE_FILE';
export const PO_UPLOADS_TO_REMOVE = 'PO_UPLOADS_TO_REMOVE';
export const PO_GET_UPLOADED_FILES = 'PO_GET_UPLOADED_FILES';
export const PO_CLEAR_UPLOAD = 'PO_CLEAR_UPLOAD';
export const PO_SELECT_TAX_TYPE = 'PO_SELECT_TAX_TYPE';
export const GET_PO_SUB_SETTING = 'GET_PO_SUB_SETTING';
export const PO_COMPUTATION_HANDLER = 'PO_COMPUTATION_HANDLER';
export const SET_PO_ITEMS_FROM_INFINITE_SCROLL =
    'SET_PO_ITEMS_FROM_INFINITE_SCROLL';
export const SET_PO_SEARCH_FROM_INFINITE_SCROLL =
    'SET_PO_SEARCH_FROM_INFINITE_SCROLL';

/* Quotation */
export const GET_QUOTATIONS = 'GET_QUOTATIONS';
export const GET_QUOTATION_STATUS = 'GET_QUOTATION_STATUS';
export const GET_DR_QUOTATION = 'GET_DR_QUOTATION';
export const GET_SINGLE_QUOTATION = 'GET_SINGLE_QUOTATION';
export const CLEAR_QUOTATION = 'CLEAR_QUOTATION';
export const QUOTATION_INPUT_CHANGE = 'QUOTATION_INPUT_CHANGE';
export const GET_QU_TERMS_CONDITIONS = 'GET_QU_TERMS_CONDITIONS';
export const HANDLE_QU_SELECT_CUSTOMER = 'HANDLE_QU_SELECT_CUSTOMER';
export const GET_QU_CUSTOMERS = 'GET_QU_CUSTOMERS';
export const GET_QU_PRODUCTS = 'GET_QU_PRODUCTS';
export const HANDLE_QU_SELECT_TERMS_CONDITIONS =
    'HANDLE_QU_SELECT_TERMS_CONDITIONS';
export const HANDLE_QU_SELECT_ITEM = 'HANDLE_QU_SELECT_ITEM';
export const HANDLE_QU_ADD_ITEM = 'HANDLE_QU_ADD_ITEM';
export const HANDLE_QU_REMOVE_ITEM = 'HANDLE_QU_REMOVE_ITEM';
export const HANDLE_QU_SRP = 'HANDLE_QU_SRP';
export const HANDLE_QU_QTY = 'HANDLE_QU_QTY';
export const HANDLE_QU_INPUT_ATTR = 'HANDLE_QU_INPUT_ATTR';
export const HANDLE_QU_TOGGLE_EDIT_BTN_ATTR = 'HANDLE_QU_TOGGLE_EDIT_BTN_ATTR';
export const HANDLE_QU_SUBMIT_ATTR = 'HANDLE_QU_SUBMIT_ATTR';
export const HANDLE_QU_CHECK_VAT = 'HANDLE_QU_CHECK_VAT';
export const HANDLE_QU_UPDATE_ATTR = 'HANDLE_QU_UPDATE_ATTR';
export const GET_QU_SI_TEMPLATES = 'GET_QU_SI_TEMPLATES';
export const GET_QU_SO_TEMPLATES = 'GET_QU_SO_TEMPLATES';
export const HANDLE_QU_SI_PAYMENT = 'HANDLE_QU_SI_PAYMENT';
export const HANDLE_QU_SELECT_RECEIPT_TYPE = 'HANDLE_QU_SELECT_RECEIPT_TYPE';
export const HANDLE_QU_TOGGLE_EDIT_ROW = 'HANDLE_QU_TOGGLE_EDIT_ROW';
export const HANDLE_QU_EDIT_INPUT_ROW = 'HANDLE_QU_EDIT_INPUT_ROW';
export const HANDLE_QU_EDIT_UPDATE_ROW = 'HANDLE_QU_EDIT_UPDATE_ROW';
export const HANDLE_QU_SELECT_PAYMENT_TYPE = 'HANDLE_QU_SELECT_PAYMENT_TYPE';
export const HANDLE_SELECT_QU_TEMPLATE = 'HANDLE_SELECT_QU_TEMPLATE';
export const GET_QU_TEMPLATES = 'GET_QU_TEMPLATES';
export const SET_QU_ITEM_ID = 'SET_QU_ITEM_ID';
export const QUOTATION_UPDATE_FILE = 'QUOTATION_UPDATE_FILE';
export const QUOTATION_REMOVE_FILE = 'QUOTATION_REMOVE_FILE';
export const QUOTATION_UPLOADS_TO_REMOVE = 'QUOTATION_UPLOADS_TO_REMOVE';
export const QUOTATION_GET_UPLOADED_FILES = 'QUOTATION_GET_UPLOADED_FILES';
export const QUOTATION_CLEAR_UPLOAD = 'QUOTATION_CLEAR_UPLOAD';
export const QU_SELECT_TAX_TYPE = 'QU_SELECT_TAX_TYPE';
export const GET_QU_SUB_SETTING = 'GET_QU_SUB_SETTING';
export const QU_COMPUTATION_HANDLER = 'QU_COMPUTATION_HANDLER';

/* Sales Order */
export const GET_SALES_ORDERS = 'GET_SALES_ORDERS';
export const GET_SINGLE_SALES_ORDER = 'GET_SINGLE_SALES_ORDER';
export const CLEAR_SALES_ORDER = 'CLEAR_SALES_ORDER';
export const SALES_ORDER_INPUT_CHANGE = 'SALES_ORDER_INPUT_CHANGE';
export const GET_SO_STATUS = 'GET_SO_STATUS';
export const GET_IN_STATUS = 'GET_IN_STATUS';
export const GET_PO_STATUS = 'GET_PO_STATUS';
export const GET_IR_STATUS = 'GET_IR_STATUS';

/* Returns */
export const GET_STOCK_LOGS = 'GET_STOCK_LOGS';
export const GET_RETURNS_DATA = 'GET_RETURNS_DATA';
export const RETURN_HANDLE_INPUT = 'RETURN_HANDLE_INPUT';
export const RETURN_UPDATE_QTY = 'RETURN_UPDATE_QTY';
export const RETURN_CLEAR = 'RETURN_CLEAR';
export const LOGS_DATE_INPUT_CLEAR = 'LOGS_DATE_INPUT_CLEAR';

/* Stock Transfer*/
export const CLEAR_STOCK_TRANSFER = 'CLEAR_STOCK_TRANSFER';
export const GET_SINGLE_STOCK_TRANSFER = 'GET_SINGLE_STOCK_TRANSFER';
export const GET_STOCK_TRANSFERS = 'GET_STOCK_TRANSFERS';
export const GET_STOCK_TRANSFER_HISTORY_PRODUCTS =
    'GET_STOCK_TRANSFER_HISTORY_PRODUCTS';
export const GET_STOCK_TRANSFER_HISTORY_SINGLE_PRODUCT =
    'GET_STOCK_TRANSFER_HISTORY_SINGLE_PRODUCT';
export const STOCK_TRANSFER_HISTORY_DATE_FILTER_INPUT =
    'STOCK_TRANSFER_HISTORY_DATE_FILTER_INPUT';
export const STOCK_TRANSFER_HISTORY_DATE_INPUT_CLEAR =
    'STOCK_TRANSFER_HISTORY_DATE_INPUT_CLEAR';
export const STOCK_TRANSFER_INPUT_CHANGE = 'STOCK_TRANSFER_INPUT_CHANGE';
export const STOCK_TRANSFER_UPDATE_QTY = 'STOCK_TRANSFER_UPDATE_QTY';
export const STOCK_TRANSFER_GET_SCAN = 'STOCK_TRANSFER_GET_SCAN';
export const STOCK_TRANSFER_GET_PRODUCTS = 'STOCK_TRANSFER_GET_PRODUCTS';
export const STOCK_TRANSACTION_EDIT = 'STOCK_TRANSACTION_EDIT';
export const STOCK_REMOVE_SCAN = 'STOCK_REMOVE_SCAN';

/* Sales Order Setting */
export const GET_SALES_ORDER_SETTING = 'GET_SALES_ORDER_SETTING';
export const SALES_ORDER_SETTING_INPUT = 'SALES_ORDER_SETTING_INPUT';
export const SALES_ORDER_SETTING_PREVIEW = 'SALES_ORDER_SETTING_PREVIEW';
export const CLEAR_SALES_ORDER_SETTING = 'CLEAR_SALES_ORDER_SETTING';
export const SALES_ORDER_SETTING_TOGGLE_EDIT =
    'SALES_ORDER_SETTING_TOGGLE_EDIT';
export const SALES_ORDER_SETTING_SELECT_SHOW =
    'SALES_ORDER_SETTING_SELECT_SHOW';
export const SALES_ORDER_SETTING_SELECT_TEMPLATE =
    'SALES_ORDER_SETTING_SELECT_TEMPLATE';
export const GET_CUSTOMER_HISTORY_SALES_ORDERS =
    'GET_CUSTOMER_HISTORY_SALES_ORDERS';
export const GET_SALES_ORDER_DATE_FILTER = 'GET_SALES_ORDER_DATE_FILTER';
export const GET_CUSTOMER_HISTORY_SINGLE_SALES_ORDER =
    'GET_CUSTOMER_HISTORY_SINGLE_SALES_ORDER';
export const GET_DR_SALES_ORDERS = 'GET_DR_SALES_ORDERS';
export const GET_DR_SINGLE_SALES_ORDER = 'GET_DR_SINGLE_SALES_ORDER';

/* Invoice */
export const GET_INVOICES = 'GET_INVOICES';
export const GET_SINGLE_INVOICE = 'GET_SINGLE_INVOICE';
export const INVOICE_INPUT_CHANGE = 'INVOICE_INPUT_CHANGE';
export const CLEAR_INVOICE = 'CLEAR_INVOICE';

/* Sales Invoice */
export const GET_SALES_INVOICES = 'GET_SALES_INVOICES';
export const GET_SINGLE_SALES_INVOICE = 'GET_SINGLE_SALES_INVOICE';
export const CLEAR_SALES_INVOICE = 'CLEAR_SALES_INVOICE';
export const SALES_INVOICE_INPUT_CHANGE = 'SALES_INVOICE_INPUT_CHANGE';
export const GET_SI_TERMS_CONDITIONS = 'GET_SI_TERMS_CONDITIONS';
export const HANDLE_SI_SELECT_CUSTOMER = 'HANDLE_SI_SELECT_CUSTOMER';
export const GET_SI_CUSTOMERS = 'GET_SI_CUSTOMERS';
export const GET_SI_PRODUCTS = 'GET_SI_PRODUCTS';
export const GET_QUOTATION_PRODUCTS = 'GET_QUOTATION_PRODUCTS';
export const HANDLE_SI_SELECT_TERMS_CONDITIONS =
    'HANDLE_SI_SELECT_TERMS_CONDITIONS';
export const HANDLE_SI_SELECT_ITEM = 'HANDLE_SI_SELECT_ITEM';
export const HANDLE_SI_ADD_ITEM = 'HANDLE_SI_ADD_ITEM';
export const HANDLE_SI_REMOVE_ITEM = 'HANDLE_SI_REMOVE_ITEM';
export const HANDLE_SI_SRP = 'HANDLE_SI_SRP';
export const HANDLE_SI_QTY = 'HANDLE_SI_QTY';
export const HANDLE_SI_INPUT_ATTR = 'HANDLE_SI_INPUT_ATTR';
export const HANDLE_SI_TOGGLE_EDIT_BTN_ATTR = 'HANDLE_SI_TOGGLE_EDIT_BTN_ATTR';
export const HANDLE_SI_SUBMIT_ATTR = 'HANDLE_SI_SUBMIT_ATTR';
export const HANDLE_SI_CHECK_VAT = 'HANDLE_SI_CHECK_VAT';
export const HANDLE_SI_UPDATE_ATTR = 'HANDLE_SI_UPDATE_ATTR';
export const GET_SI_TEMPLATES = 'GET_SI_TEMPLATES';
export const HANDLE_SELECT_SI_TEMPLATE = 'HANDLE_SELECT_SI_TEMPLATE';
export const HANDLE_SI_SELECT_TYPE_RECEIPT = 'HANDLE_SI_SELECT_TYPE_RECEIPT';
export const HANDLE_SI_TOGGLE_EDIT_ROW = 'HANDLE_SI_TOGGLE_EDIT_ROW';
export const HANDLE_SI_EDIT_INPUT_ROW = 'HANDLE_SI_EDIT_INPUT_ROW';
export const HANDLE_SI_EDIT_UPDATE_ROW = 'HANDLE_SI_EDIT_UPDATE_ROW';
export const HANDLE_SI_SELECT_PAYMENT = 'HANDLE_SI_SELECT_PAYMENT';
export const HANDLE_SI_SELECT_PAYMENT_TYPE = 'HANDLE_SI_SELECT_PAYMENT_TYPE';
export const SI_GET_QUOTATIONS = 'SI_GET_QUOTATIONS';
export const SO_GET_QUOTATIONS = 'SO_GET_QUOTATIONS';
export const INVOICE_GET_SO = 'INVOICE_GET_SO';
export const INVOICE_GET_DR = 'INVOICE_GET_DR';
export const HANDLE_SI_SELECT_QUOTATION = 'HANDLE_SI_SELECT_QUOTATION';
export const HANDLE_INVOICE_DATA = 'HANDLE_INVOICE_DATA';
export const HANDLE_SO_SELECT_QUOTATION = 'HANDLE_SO_SELECT_QUOTATION';
export const SET_SI_ITEM_ID = 'SET_SI_ITEM_ID';
export const SI_CHECK_IN_STOCK = 'SI_CHECK_IN_STOCK';
export const SI_UPDATE_FILE = 'SI_UPDATE_FILE';
export const SO_UPDATE_FILE = 'SO_UPDATE_FILE';
export const INVOICE_UPDATE_FILE = 'INVOICE_UPDATE_FILE';
export const SI_REMOVE_FILE = 'SI_REMOVE_FILE';
export const SO_REMOVE_FILE = 'SO_REMOVE_FILE';
export const INVOICE_REMOVE_FILE = 'INVOICE_REMOVE_FILE';
export const SI_UPLOADS_TO_REMOVE = 'SI_UPLOADS_TO_REMOVE';
export const SO_UPLOADS_TO_REMOVE = 'SO_UPLOADS_TO_REMOVE';
export const INVOICE_UPLOADS_TO_REMOVE = 'INVOICE_UPLOADS_TO_REMOVE';
export const SI_GET_UPLOADED_FILES = 'SI_GET_UPLOADED_FILES';
export const SO_GET_UPLOADED_FILES = 'SO_GET_UPLOADED_FILES';
export const INVOICE_GET_UPLOADED_FILES = 'INVOICE_GET_UPLOADED_FILES';
export const SI_SET_CUSTOMER_DATA = 'SI_SET_CUSTOMER_DATA';
export const SI_CLEAR_UPLOAD = 'SI_CLEAR_UPLOAD';
export const SO_CLEAR_UPLOAD = 'SO_CLEAR_UPLOAD';
export const INVOICE_CLEAR_UPLOAD = 'INVOICE_CLEAR_UPLOAD';
export const SI_SELECT_TAX_TYPE = 'SI_SELECT_TAX_TYPE';
export const GET_SI_SUB_SETTING = 'GET_SI_SUB_SETTING';
export const GET_SO_SUB_SETTING = 'GET_SO_SUB_SETTING';
export const SI_COMPUTATION_HANDLER = 'SI_COMPUTATION_HANDLER';

/* Product Unit */
export const GET_PRODUCT_UNITS = 'GET_PRODUCT_UNITS';
export const GET_SINGLE_PRODUCT_UNIT = 'GET_SINGLE_PRODUCT_UNIT';
export const CLEAR_PRODUCT_UNIT = 'CLEAR_PRODUCT_UNIT';
export const PRODUCT_UNIT_INPUT_CHANGE = 'PRODUCT_UNIT_INPUT_CHANGE';

/* Purchase Order Setting */
export const GET_PURCHASE_ORDER_SETTING = 'GET_PURCHASE_ORDER_SETTING';
export const PURCHASE_ORDER_SETTING_INPUT = 'PURCHASE_ORDER_SETTING_INPUT';
export const PURCHASE_ORDER_SETTING_PREVIEW = 'PURCHASE_ORDER_SETTING_PREVIEW';
export const CLEAR_PURCHASE_ORDER_SETTING = 'CLEAR_PURCHASE_ORDER_SETTING';
export const PURCHASE_ORDER_SETTING_TOGGLE_EDIT =
    'PURCHASE_ORDER_SETTING_TOGGLE_EDIT';
export const PURCHASE_ORDER_SETTING_SELECT_SHOW =
    'PURCHASE_ORDER_SETTING_SELECT_SHOW';
export const PURCHASE_ORDER_SETTING_SELECT_TEMPLATE =
    'PURCHASE_ORDER_SETTING_SELECT_TEMPLATE';

/* Sales Invoice Setting */
export const GET_SALES_INVOICE_SETTING = 'GET_SALES_INVOICE_SETTING';
export const SALES_INVOICE_SETTING_INPUT = 'SALES_INVOICE_SETTING_INPUT';
export const SALES_INVOICE_SETTING_PREVIEW = 'SALES_INVOICE_SETTING_PREVIEW';
export const CLEAR_SALES_INVOICE_SETTING = 'CLEAR_SALES_INVOICE_SETTING';
export const SALES_INVOICE_SETTING_TOGGLE_EDIT =
    'SALES_INVOICE_SETTING_TOGGLE_EDIT';
export const SALES_INVOICE_SETTING_SELECT_SHOW =
    'SALES_INVOICE_SETTING_SELECT_SHOW';
export const SALES_INVOICE_SETTING_SELECT_TEMPLATE =
    'SALES_INVOICE_SETTING_SELECT_TEMPLATE';

/* Quotation Setting */
export const GET_QUOTATION_SETTING = 'GET_QUOTATION_SETTING';
export const QUOTATION_SETTING_INPUT = 'QUOTATION_SETTING_INPUT';
export const QUOTATION_SETTING_PREVIEW = 'QUOTATION_SETTING_PREVIEW';
export const CLEAR_QUOTATION_SETTING = 'CLEAR_QUOTATION_SETTING';
export const QUOTATION_SETTING_TOGGLE_EDIT = 'QUOTATION_SETTING_TOGGLE_EDIT';
export const QUOTATION_SETTING_SELECT_SHOW = 'QUOTATION_SETTING_SELECT_SHOW';
export const QUOTATION_SETTING_SELECT_TEMPLATE =
    'QUOTATION_SETTING_SELECT_TEMPLATE';

/* Delivery Setting */
export const GET_DELIVERY_SETTING = 'GET_DELIVERY_SETTING';
export const DELIVERY_SETTING_INPUT = 'DELIVERY_SETTING_INPUT';
export const DELIVERY_SETTING_PREVIEW = 'DELIVERY_SETTING_PREVIEW';
export const CLEAR_DELIVERY_SETTING = 'CLEAR_DELIVERY_SETTING';
export const DELIVERY_SETTING_TOGGLE_EDIT = 'DELIVERY_SETTING_TOGGLE_EDIT';
export const DELIVERY_SETTING_SELECT_SHOW = 'DELIVERY_SETTING_SELECT_SHOW';
export const DELIVERY_SETTING_SELECT_TEMPLATE =
    'DELIVERY_SETTING_SELECT_TEMPLATE';

/* Logs */
export const GET_LOGS = 'GET_LOGS';
export const GET_SINGLE_LOG = 'GET_SINGLE_LOG';
export const CLEAR_LOG = 'CLEAR_LOG';

/* Users Logs (Clientside of Logs)*/
export const GET_USER_LOGS = 'GET_USER_LOGS';
export const GET_SINGLE_USER_LOG = 'GET_SINGLE_USER_LOG';
export const CLEAR_USER_LOG = 'CLEAR_USER_LOG';

/* Receivings */
export const GET_RECEIVINGS = 'GET_RECEIVINGS';
export const GET_PO = 'GET_PO';
export const GET_PO_DATA = 'GET_PO_DATA';
export const RECEIVING_INPUT_CHANGE = 'RECEIVING_INPUT_CHANGE';
export const RECEIVING_DATA = 'RECEIVING_DATA';
export const GET_UNRECEIVE_DATA = 'GET_UNRECEIVE_DATA';
export const LOAD_UNRECEIVE_DATA = 'LOAD_UNRECEIVE_DATA';
export const SUPPLIER_NAME = 'SUPPLIER_NAME';
export const BTN_SUB = 'BTN_SUB';
export const RECEIVING_UPDATE_FILE = 'RECEIVING_UPDATE_FILE';
export const RECEIVING_REMOVE_FILE = 'RECEIVING_REMOVE_FILE';
export const RECEIVING_UPLOADS_TO_REMOVE = 'RECEIVING_UPLOADS_TO_REMOVE';
export const RECEIVING_GET_UPLOADED_FILES = 'RECEIVING_GET_UPLOADED_FILES';
export const EDIT_COST = 'EDIT_COST';
export const EDIT_SRP = 'EDIT_SRP';
export const RECEIVING_CLEAR_UPLOAD = 'RECEIVING_CLEAR_UPLOAD';
export const RECEIVING_REDIRECT = 'RECEIVING_REDIRECT';
export const GET_RECEIVING_TEMPLATES = 'GET_RECEIVING_TEMPLATES';
export const SELECT_RECEIVING_TEMPLATE = 'SELECT_RECEIVING_TEMPLATE';
export const GET_IR_ROWS_TO_DELETE = 'GET_IR_ROWS_TO_DELETE';

// DASHBOARD DAILY SALES
export const GET_DAILY_SALES_DATA = 'GET_DAILY_SALES_DATA';
export const DAILY_SALES_VIEW = 'DAILY_SALES_VIEW';
export const PRODUCTS_DAILY_SALES = 'PRODUCTS_DAILY_SALES';
export const DAILY_SALES_DATE_INPUT_CLEAR = 'DAILY_SALES_DATE_INPUT_CLEAR';
export const DAILY_SALES_CHECKER = 'DAILY_SALES_CHECKER';
export const SET_MODAL_LOADING = 'SET_MODAL_LOADING';
export const HANDLE_SALES_OPTIONS = 'HANDLE_SALES_OPTIONS';

// DASHBOARD DAILY MOVEMENT
export const GET_DAILY_MOVEMENT_DATA = 'GET_DAILY_MOVEMENT_DATA';
export const DAILY_MOVEMENT_VIEW = 'DAILY_MOVEMENT_VIEW';
export const PRODUCTS_DAILY_MOVEMENT = 'PRODUCTS_DAILY_MOVEMENT';
export const DAILY_MOVEMENT_DATE_INPUT_CLEAR =
    'DAILY_MOVEMENT_DATE_INPUT_CLEAR';
export const DAILY_MOVEMENT_CHECKER = 'DAILY_MOVEMENT_CHECKER';
export const HANDLE_MOVEMENT_OPTIONS = 'HANDLE_MOVEMENT_OPTIONS';

// PICK AND PACK
export const GET_PICK_AND_PACK_DATA = 'GET_PICK_AND_PACK_DATA';
export const PICK_AND_PACK_VIEW = 'PICK_AND_PACK_VIEW';
export const BATCH_PICK_AND_PACK = 'BATCH_PICK_AND_PACK';
export const PICK_AND_PACK_DATE_INPUT_CLEAR = 'PICK_AND_PACK_DATE_INPUT_CLEAR';
export const CLEAR_BATCH = 'CLEAR_BATCH';
export const SCAN_BATCH = 'SCAN_BATCH';
export const SCAN_SO = 'SCAN_SO';
export const SCAN_HANDLE_INPUT = 'SCAN_HANDLE_INPUT';
export const BATCH_CHECKER = 'BATCH_CHECKER';
export const SO_CHECKER = 'SO_CHECKER';
export const SCAN_PRODUCT = 'SCAN_PRODUCT';
export const SUBMIT_COMPLETE = 'SUBMIT_COMPLETE';

// Lot No Data
export const GET_LOT_NO_DATA = 'GET_LOT_NO_DATA';

// Stock Logs
export const GET_STOCK_LOGS_DATA = 'GET_STOCK_LOGS_DATA';
export const GET_SINGLE_STOCK_LOG = 'GET_SINGLE_STOCK_LOG';

/* INVENTORY */
export const INVENTORY_DATE_INPUT_CLEAR = 'INVENTORY_DATE_INPUT_CLEAR';
export const GET_STOCK_VIEW = 'GET_STOCK_VIEW';
export const GET_INVENTORY_LIST = 'GET_INVENTORY_LIST';
export const GET_INVENTORY_ITEM = 'GET_INVENTORY_ITEM';
export const SET_SEARCH = 'SET_SEARCH';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_SEARCH_LIST = 'SET_SEARCH_LIST';
export const SET_LOADING_INV = 'SET_LOADING_INV';
export const BOOLEAN_VAR = 'BOOLEAN_VAR';
export const RECEIVING_LOAD_ITEM_SUP = 'RECEIVING_LOAD_ITEM_SUP';
export const MANUAL_ITEM = 'MANUAL_ITEM';
export const INVENTORY_LIST = 'INVENTORY_LIST';
export const PO_REQUEST = 'PO_REQUEST';
export const PO_REQUEST_COUNT = 'PO_REQUEST_COUNT'; //string only, if max to 99 display to "99+""
export const INV_INDEX = 'INV_INDEX';
export const INV_PER_SUPPLIER = 'INV_PER_SUPPLIER';
export const INV_FILTER_BRAND = 'INV_FILTER_BRAND';

// USER ROLES
export const SET_NODES = 'SET_NODES';
export const SET_CHECKED = 'SET_CHECKED';
export const SET_EXPANDED = 'SET_EXPANDED';
export const SET_REDIRECT = 'SET_REDIRECT';
export const SET_ROLES = 'SET_ROLES';
export const SET_ROLES_SEARCH = 'SET_ROLES_SEARCH';
export const SET_SINGLE_ROLE = 'SET_SINGLE_ROLE';
export const SET_ROLES_DROPDOWN = 'SET_ROLES_DROPDOWN';
export const SET_USER_FORM = 'SET_USER_FORM';
export const SET_ACCOUNTs = 'SET_ACCOUNTs';
export const AVATAR_MODAL = 'AVATAR_MODAL';

/* Deliveries */
export const SI_DATA = 'SI_DATA';
export const GET_DR = 'GET_DR';
export const GET_DR_STATUS = 'GET_DR_STATUS';
export const MANUAL = 'MANUAL';
export const ADD_ITEM = 'ADD_ITEM';
export const TOGGLE_DISCOUNT_ROW = 'TOGGLE_DISCOUNT_ROW';
export const SET_LAST_ADDED_DROPDOWN_INDEX = 'SET_LAST_ADDED_DROPDOWN_INDEX';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const SET_ITEM = 'SET_ITEM';
export const COMPUTE_DR = 'COMPUTE_DR';
export const DISCOUNT_TYPE = 'DISCOUNT_TYPE';
export const MODAL_LOADING = 'MODAL_LOADING';
export const CLEAR_DELIVERY = 'CLEAR_DELIVERY';
export const DELIVERY_UPDATE_FILE = 'DELIVERY_UPDATE_FILE';
export const DELIVERY_REMOVE_FILE = 'DELIVERY_REMOVE_FILE';
export const DELIVERY_UPLOADS_TO_REMOVE = 'DELIVERY_UPLOADS_TO_REMOVE';
export const DELIVERY_GET_UPLOADED_FILES = 'DELIVERY_GET_UPLOADED_FILES';
export const DELIVERY_CLEAR_UPLOAD = 'DELIVERY_CLEAR_UPLOAD';
export const DELIVERY_TAX_SELECT_TYPE = 'DELIVERY_TAX_SELECT_TYPE';
export const DELIVERY_COMPUTATION_HANDLER = 'DELIVERY_COMPUTATION_HANDLER';
export const GET_DELIVERY_SUB_SETTING = 'GET_DELIVERY_SUB_SETTING';
export const DELIVERY_INPUT_CHANGE = 'DELIVERY_INPUT_CHANGE';
export const PRE_LOAD = 'PRE_LOAD';
export const DR_REDIRECT = 'DR_REDIRECT';
export const GET_DR_TEMPLATES = 'GET_DR_TEMPLATES';
export const SELECT_DR_TEMPLATE = 'SELECT_DR_TEMPLATE';
export const GET_DR_ROWS_TO_DELETE = 'GET_DR_ROWS_TO_DELETE';
export const SET_CREATE_SO = 'SET_CREATE_SO';

/* AUTH GLOBAL - PLEASE DONT REMOVE AUTH LOADING ITS DIFFERENT FROM SET_LOADING */
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_USER = 'AUTH_USER';
export const AUTH_ROLE = 'AUTH_ROLE';
export const AUTH_ACCESS = 'AUTH_ACCESS';
export const AUTH_PERMISSIONS = 'AUTH_PERMISSIONS';
export const EDIT_AUTH_USER = 'EDIT_AUTH_USER';
export const AUTH_FORGOT = 'AUTH_FORGOT';
export const AUTH_REGISTER = 'AUTH_REGISTER';
export const AUTH_INPUTS = 'AUTH_INPUTS';

/* POS */
export const POS_GET_PRODUCTS = 'POS_GET_PRODUCTS';
export const POS_GET_CUSTOMERS = 'POS_GET_CUSTOMERS';
export const POS_HANDLE_INPUT = 'POS_HANDLE_INPUT';
export const POS_GET_SCAN = 'POS_GET_SCAN';
export const POS_CLEAR = 'POS_CLEAR';
export const POS_DISCOUNT_TYPE = 'POS_DISCOUNT_TYPE';
export const POS_SELECT_CUSTOMER = 'POS_SELECT_CUSTOMER';
export const POS_ADD_TO_LIST = 'POS_ADD_TO_LIST';
export const POS_REMOVE_FROM_LIST = 'POS_REMOVE_FROM_LIST';
export const POS_COMPUTE_TOTAL = 'POS_COMPUTE_TOTAL';
export const POS_HANDLE_INPUT_LIST = 'POS_HANDLE_INPUT_LIST';
export const POS_COMPUTE_OVER_ALL_POS = 'POS_COMPUTE_OVER_ALL_POS';
export const POS_REMOVE_SCAN = 'POS_REMOVE_SCAN';
export const POS_UPDATE_QTY = 'POS_UPDATE_QTY';
export const POS_UPDATE_LIST = 'POS_UPDATE_LIST';
export const POS_DUPLICATE_ITEM = 'POS_DUPLICATE_ITEM';
export const POS_REMOVE_TRANSACTION = 'POS_REMOVE_TRANSACTION';
export const POS_SALES_LIST = 'POS_SALES_LIST';
export const POS_SALES_DAILY = 'POS_SALES_DAILY';
export const POS_LOADING = 'POS_LOADING';
export const POS_TRANSACTION_VIEW = 'POS_TRANSACTION_VIEW';
export const POS_CANCEL_TRANSACTION_VIEW = 'POS_CANCEL_TRANSACTION_VIEW';
export const POS_TRANSACTION_CHECKER = 'POS_TRANSACTION_CHECKER';
export const POS_EDIT_TRANSACTION_ITEM = 'POS_EDIT_TRANSACTION_ITEM';
export const POS_ITEM_CLEAR = 'POS_ITEM_CLEAR';
export const POS_ITEM_UPDATE = 'POS_ITEM_UPDATE';
export const POS_ADD_DISCOUNT = 'POS_ADD_DISCOUNT';
export const POS_TOGGLE_LIST = 'POS_TOGGLE_LIST';
export const POS_TRANSACTION_EDIT = 'POS_TRANSACTION_EDIT';

/* SETTING => App */
export const GET_SETTING_APP = 'GET_SETTING_APP';
export const SETTING_APP_INPUT_CHANGE = 'SETTING_APP_INPUT_CHANGE';
export const SETTING_APP_UPLOAD_LOGO = 'SETTING_APP_UPLOAD';
export const SETTING_APP_REMOVE_LOGO = 'SETTING_APP_REMOVE_LOGO';
export const GET_CURRENCIES = 'GET_CURRENCIES';
export const SELECT_CURRENCY_RADIO = 'SELECT_CURRENCY_RADIO';
export const GET_LANGUAGES = 'GET_LANGUAGES';
export const SA_PAYMENT_TERMS = 'SA_PAYMENT_TERMS';
export const SETTING_APP_GLOBAL_SELECT = 'SETTING_APP_GLOBAL_SELECT';
export const SET_SUB_SETTING = 'SET_SUB_SETTING';
export const SHOW_CROP = 'SHOW_CROP';

/* HOME => Dashbooard */
export const DASHBOARD_DATA = 'DASHBOARD_DATA';
export const BOOLEAN_PROFILE = 'BOOLEAN_PROFILE';
export const PROFILE_BTN = 'PROFILE_BTN';
export const PASSWORD_TOGGLE = 'PASSWORD_TOGGLE';

export const EMAIL_SETTING_DATA = 'EMAIL_SETTING_DATA';
export const EMAIL_SETTING_TOGGLE_TEST = 'EMAIL_SETTING_TOGGLE_TEST';
export const EMAIL_SETTING_TEST_RESULT = 'EMAIL_SETTING_TEST_RESULT';
export const EMAIL_SETTING_LOGS = 'EMAIL_SETTING_LOGS';
export const EMAIL_SETTING_SINGLE_LOG = 'EMAIL_SETTING_SINGLE_LOG';
export const EMAIL_SETTING_CLEAR_LOG = 'EMAIL_SETTING_CLEAR_LOG';

export const STATUS = 'STATUS';
export const SEND_EMAIL = 'SEND_EMAIL';
export const RESEND_EMAIL = 'RESEND_EMAIL';
export const DYNAMIC_SEND_EMAIL = 'DYNAMIC_SEND_EMAIL';

export const DELETE_GENERAL = 'DELETE_GENERAL';
export const SELECTED_ROWS = 'SELECTED_ROWS';

export const DOWNLOAD_BTN = 'DOWNLOAD_BTN';
export const PASSWORD_VERIFY = 'PASSWORD_VERIFY';
export const PASSWORD_VERIFY_MODAL = 'PASSWORD_VERIFY_MODAL';

export const DATE_RANGE = 'DATE_RANGE';
export const PAGINATION = 'PAGINATION';
export const TRANSACTION_SEARCH = 'TRANSACTION_SEARCH';

/* Purchase Order Template Setting */
export const GET_PO_TEMPLATE_SETTING = 'GET_PO_TEMPLATE_SETTING';
export const HANDLE_PO_TEMPLATE_INPUT = 'HANDLE_PO_TEMPLATE_INPUT';
export const SET_PO_COPY_TEMP_MODAL = 'SET_PO_COPY_TEMP_MODAL';
export const SET_PO_TS_COMPONENT = 'SET_PO_TS_COMPONENT';
export const HANDLE_SET_PO_DEFAULT_TEMPLATE = 'HANDLE_SET_PO_DEFAULT_TEMPLATE';
export const CLEAR_PO_TEMPLATE_SETTING = 'CLEAR_PO_TEMPLATE_SETTING';
export const PO_TS_SHOW_CROP = 'PO_TS_SHOW_CROP';
export const PO_TS_REMOVE_LOGO = 'PO_TS_REMOVE_LOGO';
export const PO_TS_LOGO_PREVIEW = 'PO_TS_LOGO_PREVIEW';
export const PO_TS_CROP_LOGO = 'PO_TS_CROP_LOGO';
export const PO_TS_CROP_TRIGGER = 'PO_TS_CROP_TRIGGER';
export const GET_SINGLE_PO_TS = 'GET_SINGLE_PO_TS';
export const PO_TS_CROP_ISSET = 'PO_TS_CROP_ISSET';

/* Quotation Template Setting */
export const GET_QU_TEMPLATE_SETTING = 'GET_QU_TEMPLATE_SETTING';
export const HANDLE_QU_TEMPLATE_INPUT = 'HANDLE_QU_TEMPLATE_INPUT';
export const SET_QU_TS_COMPONENT = 'SET_QU_TS_COMPONENT';
export const HANDLE_SET_QU_DEFAULT_TEMPLATE = 'HANDLE_SET_QU_DEFAULT_TEMPLATE';
export const CLEAR_QU_TEMPLATE_SETTING = 'CLEAR_QU_TEMPLATE_SETTING';
export const QU_TS_SHOW_CROP = 'QU_TS_SHOW_CROP';
export const QU_TS_REMOVE_LOGO = 'QU_TS_REMOVE_LOGO';
export const QU_TS_LOGO_PREVIEW = 'QU_TS_LOGO_PREVIEW';
export const QU_TS_CROP_LOGO = 'QU_TS_CROP_LOGO';
export const QU_TS_CROP_TRIGGER = 'QU_TS_CROP_TRIGGER';
export const GET_SINGLE_QU_TS = 'GET_SINGLE_QU_TS';
export const QU_TS_CROP_ISSET = 'QU_TS_CROP_ISSET';

/* Sales Invoice Template Setting */
export const GET_SI_TEMPLATE_SETTING = 'GET_SI_TEMPLATE_SETTING';
export const HANDLE_SI_TEMPLATE_INPUT = 'HANDLE_SI_TEMPLATE_INPUT';
export const SET_SI_TS_COMPONENT = 'SET_SI_TS_COMPONENT';
export const HANDLE_SET_SI_DEFAULT_TEMPLATE = 'HANDLE_SET_SI_DEFAULT_TEMPLATE';
export const CLEAR_SI_TEMPLATE_SETTING = 'CLEAR_SI_TEMPLATE_SETTING';
export const SI_TS_SHOW_CROP = 'SI_TS_SHOW_CROP';
export const SI_TS_REMOVE_LOGO = 'SI_TS_REMOVE_LOGO';
export const SI_TS_LOGO_PREVIEW = 'SI_TS_LOGO_PREVIEW';
export const SI_TS_CROP_LOGO = 'SI_TS_CROP_LOGO';
export const SI_TS_CROP_TRIGGER = 'SI_TS_CROP_TRIGGER';
export const GET_SINGLE_SI_TS = 'GET_SINGLE_SI_TS';
export const SI_TS_CROP_ISSET = 'SI_TS_CROP_ISSET';

/* Item Receipt Template Setting */
export const GET_IR_TEMPLATE_SETTING = 'GET_IR_TEMPLATE_SETTING';
export const HANDLE_IR_TEMPLATE_INPUT = 'HANDLE_IR_TEMPLATE_INPUT';
export const SET_IR_TS_COMPONENT = 'SET_IR_TS_COMPONENT';
export const HANDLE_SET_IR_DEFAULT_TEMPLATE = 'HANDLE_SET_IR_DEFAULT_TEMPLATE';
export const CLEAR_IR_TEMPLATE_SETTING = 'CLEAR_IR_TEMPLATE_SETTING';
export const IR_TS_SHOW_CROP = 'IR_TS_SHOW_CROP';
export const IR_TS_REMOVE_LOGO = 'IR_TS_REMOVE_LOGO';
export const IR_TS_LOGO_PREVIEW = 'IR_TS_LOGO_PREVIEW';
export const IR_TS_CROP_LOGO = 'IR_TS_CROP_LOGO';
export const IR_TS_CROP_TRIGGER = 'IR_TS_CROP_TRIGGER';
export const GET_SINGLE_IR_TS = 'GET_SINGLE_IR_TS';
export const IR_TS_CROP_ISSET = 'IR_TS_CROP_ISSET';
export const FILE_COUNT = 'FILE_COUNT';

/* Delivery Template Setting */
export const GET_DR_TEMPLATE_SETTING = 'GET_DR_TEMPLATE_SETTING';
export const HANDLE_DR_TEMPLATE_INPUT = 'HANDLE_DR_TEMPLATE_INPUT';
export const SET_DR_TS_COMPONENT = 'SET_DR_TS_COMPONENT';
export const HANDLE_SET_DR_DEFAULT_TEMPLATE = 'HANDLE_SET_DR_DEFAULT_TEMPLATE';
export const CLEAR_DR_TEMPLATE_SETTING = 'CLEAR_DR_TEMPLATE_SETTING';
export const DR_TS_SHOW_CROP = 'DR_TS_SHOW_CROP';
export const DR_TS_REMOVE_LOGO = 'DR_TS_REMOVE_LOGO';
export const DR_TS_LOGO_PREVIEW = 'DR_TS_LOGO_PREVIEW';
export const DR_TS_CROP_LOGO = 'DR_TS_CROP_LOGO';
export const DR_TS_CROP_TRIGGER = 'DR_TS_CROP_TRIGGER';
export const GET_SINGLE_DR_TS = 'GET_SINGLE_DR_TS';
export const DR_TS_CROP_ISSET = 'DR_TS_CROP_ISSET';

/* Create Global Template Setting */
export const HANDLE_GL_TEMPLATE_INPUT = 'HANDLE_GL_TEMPLATE_INPUT';
export const SET_GL_TS_COMPONENT = 'SET_GL_TS_COMPONENT';
export const HANDLE_SET_GL_DEFAULT_TEMPLATE = 'HANDLE_SET_GL_DEFAULT_TEMPLATE';
export const CLEAR_GL_TEMPLATE_SETTING = 'CLEAR_GL_TEMPLATE_SETTING';
export const GL_TS_SHOW_CROP = 'GL_TS_SHOW_CROP';
export const GL_TS_REMOVE_LOGO = 'GL_TS_REMOVE_LOGO';
export const GL_TS_LOGO_PREVIEW = 'GL_TS_LOGO_PREVIEW';
export const GL_TS_CROP_LOGO = 'GL_TS_CROP_LOGO';
export const GL_TS_CROP_TRIGGER = 'GL_TS_CROP_TRIGGER';
export const GL_TS_CROP_ISSET = 'GL_TS_CROP_ISSET';
export const GET_GL_SUB_TEMPLATES = 'GET_GL_SUB_TEMPLATES';
export const SELECT_GL_SUB_TEMPLATES = 'SELECT_GL_SUB_TEMPLATES';
export const SELECT_GL_MULTI_TEMPLATES = 'SELECT_GL_MULTI_TEMPLATES';
export const CREATE_TEMPLATE_REDIRECT = 'CREATE_TEMPLATE_REDIRECT';

export const IMPORT_MODAL_PRODUCT = 'IMPORT_MODAL_PRODUCT';
export const IMPORT_PRODUCT = 'IMPORT_PRODUCT';
export const DOWNLOADING_TEMPLATE = 'DOWNLOADING_TEMPLATE';
export const IMPORTED_PRODUCT = 'IMPORTED_PRODUCT';
export const FINISH_IMPORT = 'FINISH_IMPORT';
export const STOCK_LOGS = 'STOCK_LOGS';
export const SELECT_ATTRIBUTE_TEMPLATE = 'SELECT_ATTRIBUTE_TEMPLATE';

export const CLEAR_DATA = 'CLEAR_DATA';
export const BTN_FORM = 'BTN_FORM';

export const RECEIVING_PRINT = 'RECEIVING_PRINT';

export const SHIPPING_PO_LOADING = 'SHIPPING_PO_LOADING';
export const SHIPPING_PO = 'SHIPPING_PO';
export const SHIPPING_PO_DATA = 'SHIPPING_PO_DATA';
export const SHIPPING_PO_SELECTED = 'SHIPPING_PO_SELECTED';
export const FREIGHT_COST = 'FREIGHT_COST';
export const POP_TOGGLE = 'POP_TOGGLE';
export const SHIPPING_INPUTS = 'SHIPPING_INPUTS';
export const SHIPPING_OTHER_FEE = 'SHIPPING_OTHER_FEE';
export const IS_SHIP_LOADING = 'IS_SHIP_LOADING';
export const SHIPPING_DATA = 'SHIPPING_DATA';
export const SHIPPING_ROW_DELETE = 'SHIPPING_ROW_DELETE';
export const SHIPPING_SET_ROW_CHECKED = 'SHIPPING_SET_ROW_CHECKED';
export const SHIPPING_NO = 'SHIPPING_NO';
export const SHIPPING_PO_NO = 'SHIPPING_PO_NO';
export const SHIPPING_ITEMS = 'SHIPPING_ITEMS';
export const SHIPPING = 'SHIPPING';

//** create product in form */
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
