import React from 'react';
import { Show, useAccess } from 'react-redux-permission';
import DailyMovementTable from './dailyMovement.table';

function DailyMovement() {
    return (
        <Show when="inv.dashboard.daily-movement">
            <div
                role="tabpanel"
                className="tab-pane w-100"
                id="tabs-stocklogs"
                aria-expanded="true"
                aria-labelledby="base-tab2"
            >
                <DailyMovementTable />
            </div>
        </Show>
    );
}

export default DailyMovement;
