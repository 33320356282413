import { getNumRows } from '../../../Utils/Common.js';
import * as TYPES from '../../types.js';
import {
    ToastSuccess,
    ToastDanger,
    ToastWarning,
    SwalWarningHtml,
    SwalWarning,
} from '../../../Services/_toast.service';
import { SwalWarningStock } from '../../../Services/_swal.service';
import $ from 'jquery';
import { blockRoute, setMUIPage } from '../helper/helper.actions';
import { StockTransferService } from './_service.stock_transfer.js';

const spinLG =
    '<span class="spinner-border" role="status" aria-hidden="true"></span>';

// handle Inputs
export const handleInputChange = (e) => async (dispatch) => {
    if (e.target.value) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    dispatch({
        type: TYPES.STOCK_TRANSFER_INPUT_CHANGE,
        payload: { name: e.target.name, value: e.target.value },
    });
};

// handle search
export const handleSearchInput = (e) => async (dispatch) => {
    dispatch({ type: TYPES.SEARCH_INPUT, payload: e.target.value });
};

// loading
export const setLoading = (status) => async (dispatch) =>
    dispatch({ type: TYPES.SET_LOADING, payload: status });
export const setMiniLoading = (status) => async (dispatch) =>
    dispatch({ type: TYPES.SET_MINI_LOADING, payload: status });

// clear data
export const clearStockTransfer = () => async (dispatch) =>
    dispatch({ type: TYPES.CLEAR_STOCK_TRANSFER });

// country select
export const handleSelectCountry = (selectOption) => async (dispatch) => {
    if (selectOption) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    dispatch({ type: TYPES.SELECT_COUNTRY, payload: selectOption });
};

// handle mobile
export const handleMobile = (mobile) => async (dispatch) => {
    dispatch({ type: TYPES.HANDLE_MOBILE, payload: mobile });
};

// set page
export const setPage =
    (page, status = true) =>
    async (dispatch) => {
        dispatch({ type: TYPES.SET_PAGE, payload: { page, status } });
    };

// fetch stock_transfers
export const getStockTransfers =
    (pageNumber, rows_per_page = null) =>
    async (dispatch, getState) => {
        dispatch(setLoading(true));
        let { search, pagination, sort_order_name, sort_order_direction } =
            getState().stock_transfer;
        let rows_per_page_val =
            rows_per_page != null
                ? rows_per_page
                : getNumRows('stock_transfer');

        let searchPostParams = {
            // search: search,
            // activePage: pagination.activePage,
            // page: pageNumber,
            // rows_per_page: rows_per_page_val,
            // sort_order_name: sort_order_name,
            // sort_order_direction: sort_order_direction
            search,
            options: {
                page: pageNumber ? pageNumber : pagination.activePage,
                limit: rows_per_page_val ? rows_per_page_val : 10,
                sort_by: sort_order_name,
                sort_order: sort_order_direction,
            },
        };

        StockTransferService.getStockTransfers(searchPostParams)
            .then((res) => {
                dispatch({
                    type: TYPES.GET_STOCK_TRANSFERS,
                    payload: {
                        stock_transfers: res.data.docs,
                        pagination: {
                            //   totalCount: res.data.stock_transfers.total,
                            //   activePage: res.data.stock_transfers.current_page,
                            //   itemsCountPerPage: res.data.stock_transfers.per_page,
                            //   totalItemsCount: res.data.stock_transfers.total,
                            totalCount: res.data.total_docs,
                            activePage: res.data.page,
                            itemsCountPerPage: res.data.limit,
                            totalItemsCount: res.data.total_docs,
                        },
                    },
                });
                dispatch(setLoading(false));
            })
            .catch((error) => {
                // if (error.data.errors.length > 0) {
                //   error.data.errors.map((err) => ToastDanger(err));
                // } else {
                //   ToastDanger("Network error, please reload the page.");
                // }
                dispatch(setLoading(false));

                // $(".btn-save").removeAttr("disabled").html("Save");
                console.log(error);
            });
    };

// fetch single stock_transfers
export const getSingleStockTransfer =
    (id, status = null) =>
    async (dispatch) => {
        if (!status) {
            dispatch(setLoading(true));
        }

        StockTransferService.getSingleStockTransfer(id)
            .then((res) => {
                dispatch({
                    type: TYPES.GET_SINGLE_SUPPLIER,
                    payload: res.data.supplier,
                });
                dispatch({
                    type: TYPES.SET_PAYMENT_DETAILS,
                    payload: res.data.supplier.account_details
                        ? res.data.supplier.account_details
                        : [],
                });

                // if (status == 'delete') {
                //     dispatch(archive());
                // }

                if (status == 'history') {
                    let id_params = { supplier_id: id };
                    // dispatch(getPurchaseOrders(1, id_params));
                }

                if (!status) {
                    dispatch(setLoading(false));
                }
            })
            .catch((error) => {
                if (error.data.errors.length > 0) {
                    error.data.errors.map((err) => ToastDanger(err));
                } else {
                    ToastDanger('Network error, please reload the page.');
                }

                // $(".btn-save").removeAttr("disabled").html("Save");
                console.log(error);
                // ToastDanger("Network error, please reload the page.");
                // console.log(error);
                if (!status) {
                    dispatch(setLoading(false));
                }
            });
    };

/**************************** =============================== *****************************/

/**************************** STOCK_TRANSFER HISTORY ACTIONS BELOW *****************************/

/**************************** =============================== *****************************/

// // TOGGLE TABS [ PUCHASE ORDERS, PRODUCTS ]
// export const handleChangeTab = (tab, id) => async (dispatch, getState) => {
//     // let { display_tab } = getState().stock_transfer

//     let id_params = { stock_transfer_id: id };

//     switch (tab) {
//         case 'purchase_orders':
//             // dispatch(getPurchaseOrders(1, id_params));
//             break;
//         case 'items':
//             // dispatch(getProductsPaginate(1, id));
//             break;
//         default:
//             break;
//     }
// };

// date input onchange
export const dateFilterInput = (e) => async (dispatch) => {
    dispatch({
        type: TYPES.STOCK_TRANSFER_HISTORY_DATE_FILTER_INPUT,
        payload: { key: [e.target.name], value: e.target.value },
    });
};

// HANDLE SUBMIT DATE FILTER FOR [ PURCHASE ORDER, PRODUCTS ]
export const handleDateFilterSubmit =
    (e, filterBy) => async (dispatch, getState) => {
        e.preventDefault();

        let { date_to, date_from, single_stock_transfer } =
            getState().stock_transfer;

        const today = new Date();

        try {
            if (
                new Date(date_from).getTime() >= today.getTime() ||
                new Date(date_to).getTime() >= today.getTime()
            ) {
                ToastDanger('Your Date value can not be greater than today.');
            } else {
                console.log('stock_transfer_id:', single_stock_transfer._id);

                let pageOrigin = {
                    stock_transfer_id: single_stock_transfer._id,
                    origin: 'stock_transfer_date_filter',
                };
                let date_filter = { date_from: date_from, date_to: date_to };

                // // CHECK TYPE OF INVOICE
                // switch (filterBy) {
                //     case 'purchase_orders':
                //         dispatch(
                //             getPurchaseOrders(1, null, pageOrigin, date_filter)
                //         );
                //         break;
                //     default:
                //         break;
                // }
            }
        } catch (error) {
            console.log(`Error: ${error}`);
            ToastDanger('Something went wrong please contact your admin...');
        }
    };

// DATE INPUT CLEAR
export const dateFilterClear = (type, id) => async (dispatch) => {
    dispatch({
        type: TYPES.STOCK_TRANSFER_HISTORY_DATE_INPUT_CLEAR,
        payload: type,
    });
    let id_params = { stock_transfer_id: id };

    switch (type) {
        case 'purchase_orders':
            // dispatch(getPurchaseOrders(1, id_params ));
            break;
        case 'items':
            break;
        default:
            break;
    }
};

export const handleAddPayment =
    (payment_details, default_payment_details_) =>
    async (dispatch, getState) => {
        let new_payment_details = payment_details.concat({
            bank_name: '',
            bank_address: '',
            account_number: '',
            account_name: '',
            swift_code: '',
            mode: 'edit',
            has_saved: 0,
        });
        dispatch({
            type: TYPES.SET_PAYMENT_DETAILS,
            payload: new_payment_details,
        });
    };

export const handleRemovePayment =
    (payment_details, key) => async (dispatch, getState) => {
        payment_details = payment_details.filter(
            (item, item_key) => key !== item_key
        );
        dispatch({ type: TYPES.SET_PAYMENT_DETAILS, payload: payment_details });
    };

export const handChangePaymentInput =
    (key, e, payment_details) => async (dispatch, getState) => {
        payment_details[key][e.target.name] = e.target.value;
        dispatch({ type: TYPES.SET_PAYMENT_DETAILS, payload: payment_details });
    };

export const savePayment =
    (e, payment_details, key, mode) => async (dispatch, getState) => {
        e.preventDefault();
        payment_details[key].mode = mode;
        payment_details[key].has_saved = 1;
        dispatch({ type: TYPES.SET_PAYMENT_DETAILS, payload: payment_details });
    };

export const handleRemovePaymentAPI =
    (payment_details, key) => async (dispatch, getState) => {
        if (payment_details[key]._id) {
            dispatch(setLoading(true));
            StockTransferService.supplieAcountDelete({
                _id: payment_details[key]._id,
            })
                .then((res) => {
                    payment_details = payment_details.filter(
                        (item, item_key) => key !== item_key
                    );
                    dispatch({
                        type: TYPES.SET_PAYMENT_DETAILS,
                        payload: payment_details,
                    });
                    dispatch(setLoading(false));
                })
                .catch((error) => {
                    if (error.data.errors.length > 0) {
                        error.data.errors.map((err) => ToastDanger(err));
                    } else {
                        ToastDanger('Network error, please reload the page.');
                    }

                    // $(".btn-save").removeAttr("disabled").html("Save");
                    console.log(error);
                    dispatch(setLoading(false));
                });
        } else {
            payment_details = payment_details.filter(
                (item, item_key) => key !== item_key
            );
            dispatch({
                type: TYPES.SET_PAYMENT_DETAILS,
                payload: payment_details,
            });
        }
    };

export const savePaymentApi =
    (e, payment_details, key, mode, stock_transfer_id) =>
    async (dispatch, getState) => {
        e.preventDefault();
        payment_details[key].mode = mode;
        payment_details[key].has_saved = 1;
        payment_details[key].stock_transfer_id = stock_transfer_id;

        StockTransferService.supplieAcountUpdate(payment_details[key])
            .then((res) => {
                // payment_details  = payment_details.filter((item, item_key) => key !== item_key);
                dispatch({
                    type: TYPES.SET_PAYMENT_DETAILS,
                    payload: payment_details,
                });
                dispatch(setLoading(false));
            })
            .catch((error) => {
                if (error.data.errors.length > 0) {
                    error.data.errors.map((err) => ToastDanger(err));
                } else {
                    ToastDanger('Network error, please reload the page.');
                }

                // $(".btn-save").removeAttr("disabled").html("Save");
                console.log(error);
                // ToastDanger("Network error, please reload the page.");
                // console.log(error);
                dispatch(setLoading(false));
            });

        // dispatch({ type: SET_PAYMENT_DETAILS, payload: payment_details });
    };

// returns selected rows only
export const getSelectedRows =
    (selectedRows, currentRows) => async (dispatch) => {
        // console.log(selectedRows, currentRows)
        let newArray = [];

        currentRows.map((current, i) => {
            selectedRows.map((selected, _i) => {
                if (selected.index == current.index) {
                    let selected = {
                        id: current.data[0],
                        name: current.data[1],
                    };
                    newArray.push(selected);
                }
            });
        });
        // console.log(newArray);
        dispatch({ type: TYPES.GET_ROWS_TO_DELETE, payload: newArray });
    };

// export const confirmDeleteDialogBox = () => async (dispatch, getState) => {
//     let { rows_to_delete } = getState().customer;
//     let names = '';
//     let name_arr = [];
//     rows_to_delete.map((rows, i) => {
//         name_arr.push('<b>' + rows.name + '</b>');
//     });
//     names = name_arr.join(', ');

//     SwalWarningHtml(
//         'Warning!',
//         `Do you want to remove the StockTransfer(s): ${names}?`,
//         () => dispatch(moveToArchives())
//     );
// };

// Sort by column name and direction
export const sortTableByColumn =
    (sort_order_name, sort_order_direction) => async (dispatch) => {
        let sortingParams = {
            sort_order_name: sort_order_name,
            sort_order_direction: sort_order_direction,
        };

        dispatch({ type: TYPES.SORT_BY_COLUMN, payload: sortingParams });
        dispatch(getStockTransfers());
    };

export const handleSearchInputMUI = (e) => async (dispatch) => {
    dispatch({ type: TYPES.SEARCH_INPUT, payload: e.target.value });
    if (e.key === 'Enter') {
        console.log('enter');
        // dispatch(getPurchaseOrders(1));
    }
};

// set the rows checked box
export const setRowChecked = () => async (dispatch) =>
    dispatch({ type: TYPES.SET_ROW_CHECKED });

// Set Modal
export const setModal = (modal, status) => async (dispatch) => {
    dispatch({
        type: TYPES.SET_MODAL,
        payload: { modal, status },
    });
};

export const resetModalInputs = () => (dispatch) => {
    dispatch({
        type: TYPES.RETURN_CLEAR,
        payload: {
            qty: 0,
            remarks: '',
        },
    });
};

// handle qty item stock_transfer
export const updateQty = (status) => async (dispatch, getState) => {
    const { qty } = getState().stock_transfer;
    let payload = status == 'inc' ? qty + 1 : qty == 0 ? qty : qty - 1;

    dispatch({ type: TYPES.STOCK_TRANSFER_UPDATE_QTY, payload });
};

// search single item
export const searchSubmit =
    (e, status = null) =>
    async (dispatch, getState) => {
        e.preventDefault();

        const { search } = getState().stock_transfer;

        // Validations
        if (search == '') {
            return ToastDanger('Search field is required');
        }

        const params = { search };

        dispatch(searchProduct(params));
    };

export const getProducts = (products) => async (dispatch) => {
    dispatch({
        type: TYPES.STOCK_TRANSFER_GET_PRODUCTS,
        payload: { products: products },
    });
    dispatch(setModal('stock-transfer_item_modal'));
};

// search dynamic values on product
export const searchProduct =
    (postParams, origin = null) =>
    async (dispatch, getState) => {
        // loading show depends on input or from modal
        origin == 'item-modal'
            ? dispatch(setLoading('item-modal'))
            : dispatch(setLoading('transfer-search'));

        let { transfer_item } = getState().stock_transfer;

        StockTransferService.scanBarcode(postParams)
            .then((res) => {
                if (res.status == 200) {
                    dispatch(removeScan());
                    const transfer_data = res.data;

                    // if search result shows multiple products
                    if (transfer_data.products) {
                        dispatch(getProducts(transfer_data.products));
                        dispatch(setModal('transfer_item_modal', true));
                        return false;
                    }

                    // Check if in stock
                    if (!transfer_data.inStock) {
                        dispatch(setLoading('#'));
                        return ToastDanger(
                            `${transfer_data.product_name} is out of stock`
                        );
                    }

                    if (transfer_data.product_image == null) {
                        transfer_data['product_image'] = '/noimage.jpg';
                    }

                    transfer_data['_total_item_price'] = transfer_data['srp']; // add another object for total price
                    let transfer_item_arr = transfer_item.concat(transfer_data);

                    console.log('transfer_item_arr', transfer_item_arr);
                    dispatch({
                        type: TYPES.STOCK_TRANSFER_GET_SCAN,
                        payload: transfer_item_arr,
                    });
                    dispatch(setModal('stock-transfer_item_modal', false));
                    transfer_item.length > 0
                        ? dispatch(setLoading('stock-transfer-scan'))
                        : dispatch(setLoading('#'));
                }
            }) // add another object for total price
            .catch((error) => {
                console.log('error', error);
                dispatch(setLoading('#'));
                if (error.data.message) {
                    return ToastDanger(error.data.message);
                }
                console.log(error);
            });
    };

//  REMOVE SCAN DATA
export const removeScan = () => async (dispatch, getState) => {
    let { transfer_item } = getState().stock_transfer;

    if (transfer_item.length > 0) {
        transfer_item.shift(); // if the transfer_item has value remove the first
    }

    dispatch({ type: TYPES.STOCK_REMOVE_SCAN, payload: transfer_item });
};

//** SUBMIT TRANSFER */
export const submitStockTransfer =
    (movement, callback = () => {}) =>
    async (dispatch, getState) => {
        const { transfer_item, qty, remarks } = getState().stock_transfer;

        // console.log('getState().pos;', getState().pos);
        try {
            $('.btn-trans').attr('disabled', 'disabled').html(spinLG);

            let params = {
                product_id: transfer_item._id,
                movement: movement,
                qty: qty,
                remarks: remarks,
                transfer_item: transfer_item[0],
            };

            const res = await StockTransferService.submitStockTransfer(params);

            ToastSuccess(res.data.message);
            dispatch(getStockTransfers(1));
            $('.btn-in').removeAttr('disabled').html('In');
            $('.btn-out').removeAttr('disabled').html('Out');
            dispatch(removeScan());

            callback();
        } catch (err) {
            if (err.data?.errors) {
                ToastDanger(err.data.errors);
            }
            console.log(err);
            $('.btn-in').removeAttr('disabled').html('In');
            $('.btn-out').removeAttr('disabled').html('Out');

            callback();
        }
    };
