import {
    SET_LOADING,
    SORT_BY_COLUMN,
    GET_ROWS_TO_DELETE,
    SEARCH_INPUT,
    GET_DR_QUOTATION,
    SI_DATA,
    BOOLEAN_VAR,
    GET_DR,
    HANDLE_SI_SELECT_PAYMENT_TYPE,
    GET_SI_TERMS_CONDITIONS,
    HANDLE_SI_SELECT_TYPE_RECEIPT,
    HANDLE_SI_SELECT_TERMS_CONDITIONS,
    MANUAL,
    HANDLE_SELECT_SI_TEMPLATE,
    GET_SI_TEMPLATES,
    GET_QUOTATION_PRODUCTS,
    GET_SI_CUSTOMERS,
    HANDLE_SI_SELECT_CUSTOMER,
    HANDLE_SI_SELECT_ITEM,
    ADD_ITEM,
    TOGGLE_DISCOUNT_ROW,
    SET_LAST_ADDED_DROPDOWN_INDEX,
    REMOVE_ITEM,
    COMPUTE_DR,
    SET_MODAL,
    MODAL_LOADING,
    CLEAR_DELIVERY,
    DELIVERY_UPDATE_FILE,
    DELIVERY_REMOVE_FILE,
    DELIVERY_UPLOADS_TO_REMOVE,
    DELIVERY_GET_UPLOADED_FILES,
    DELIVERY_CLEAR_UPLOAD,
    DELIVERY_TAX_SELECT_TYPE,
    DELIVERY_COMPUTATION_HANDLER,
    GET_DELIVERY_SUB_SETTING,
    DELIVERY_INPUT_CHANGE,
    SEND_EMAIL,
    RESEND_EMAIL,
    DYNAMIC_SEND_EMAIL,
    PRE_LOAD,
    DOWNLOAD_BTN,
    EMAIL_SETTING_DATA,
    DR_REDIRECT,
    GET_DR_TEMPLATES,
    SELECT_DR_TEMPLATE,
    GET_DR_ROWS_TO_DELETE,
    BTN_FORM,
    CLEAR_DATA,
    GET_STATUS,
    GET_DR_STATUS,
    SET_CREATE_SO,
    HANDLE_ITEM_FORM_DISABLE,
    CREATE_PRODUCT,
} from '../types';

// state
const initialState = {
    pagination: {
        totalCount: 0,
        activePage: 1,
        itemsCountPerPage: 1,
        totalItemsCount: 10,
        pageRangeDisplayed: 8,
    },
    main_url: '#',
    create_url: '#',
    edit_url: '/deliveries/edit/',
    edit_url_so: '/sales/sales-orders/edit',
    rows_to_delete: null,
    sort_order_name: null,
    sort_order_direction: null,
    search: '',
    quotations: [],
    customer_modal: false,
    dr_print_modal: false,
    print_modal_si: false,
    si_data: {
        items: [],

        customer: '',
        customer_id: null,
        lastAddedDropdownIndex: null,
        customer_street1: '',
        customer_city: '',
        customer_country: '',
        sub_total: 0,
        total: 0,
        discount: 0,
        discount_type: { value: 'none', label: 'None' },
        showDiscountRow: false,
        vat_amount: 0,
        tax: 0,
        default_tax: 0,
        tax_type: { value: 'none', label: 'None' },
        delivery_fee: 0,
        remakrs: '',
        total_qty: 0,
        payment_terms: { value: 'none', label: 'None' },
        receipt_type: { value: 'delivery receipt', label: 'Delivery Receipt' },
        payment_mode: { value: '', label: 'None' },
        customer_data: { value: '', label: '' },
        status: '',
        reference_no: '',
        manual_input: false,
        is_selected: false,
        generate_so: null,
        template_name: '',
        dr_date: new Date(),
        delivery_receipt_no: '',
        dr_status: '',
        dr_id: '',
        subs_setting: [],
        uploads: [],
        email_sent: false,
        serial_no: '',
        delivery_template_id: '',
    },
    new_uploads: [],
    files_to_remove: [],
    quotation_id: null,
    enable_submit: true,
    dr_data: [],
    status_view: 'all',
    pagination_list: { 0: [], 1: [], 2: [], all: [] },
    payment_mode_options: [
        { value: 'cash', label: 'Cash' },
        { value: 'credit card', label: 'Credit Card' },
        { value: 'cod', label: 'COD' },
        { value: 'check', label: 'Check' },
        { value: 'bank transfer', label: 'Bank Transfer' },
    ],
    isSearchable: true,
    terms_conditions: [],
    receipt_type_options: [
        { value: 'invoice', label: 'Invoice' },
        { value: 'delivery receipt', label: 'Delivery Receipt' },
    ],
    // inv_templates: [],
    // inv_default_template: [],
    customers: [],
    items: [],

    default_customer_value: { value: '', label: 'Choose Customer' },
    default_product_value: { value: '', label: 'Choose Product' },
    itemSelected: {
        _id: '',
        amount: 0.0,
        srp: 0.0,
        product_id: '',
        product_name: '',
        product_unit_id: '',
        product_unit_name: '',
        qty: 0,
        sku: '',
    },
    modal_loading: false,
    tax_types: [
        { value: 'none', label: 'None' },
        { value: 'exclusive', label: 'Exclusive' },
        { value: 'inclusive', label: 'Inclusive' },
    ],
    sending_email: false,
    pre_load: false,
    item_template: {
        _id: '',
        sku: '',
        product_id: '',
        product_name: '',
        product_unit_id: '',
        product_unit_name: '',
        amount: 0.0,
        srp: 0.0,
        unit_price: 0.0,
        qty: 1,
        items: [],
        new: true,
        description: '',
        barcode: '',
        selected: false,
        non_inventory: false,
    },
    download_pdf: false,
    form_disable: false,
    email_config: [],
    redirect: false,
    dr_default_template: {},
    delivery_templates: [],
    btn_form: {
        save: false,
        draft: false,
        email_save: false,
        cancel: false,
        approve: false,
    },
};

const deliveryReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_INPUT:
            return {
                ...state,
                search: action.payload,
            };
        case GET_STATUS:
            return {
                ...state,
                status_view: action.payload,
            };
        case GET_DR_STATUS:
            return {
                ...state,
                dr_data: action.payload.dr_data,
                pagination: action.payload.pagination,
                pagination_list: action.payload.pagination_list,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case SORT_BY_COLUMN:
            return {
                ...state,
                sort_order_name: action.payload.sort_order_name,
                sort_order_direction: action.payload.sort_order_direction,
            };
        case GET_DR_ROWS_TO_DELETE:
            return {
                ...state,
                rows_to_delete: action.payload,
            };
        case GET_DR_QUOTATION:
            return {
                ...state,
                quotations: action.payload,
            };
        case SI_DATA:
            return {
                ...state,
                si_data: { ...state.si_data, ...action.payload.data },
                quotation_id: action.payload._id,
            };
        case BOOLEAN_VAR:
            return {
                ...state,
                enable_submit: action.payload,
            };
        case GET_DR:
            return {
                ...state,
                dr_data: action.payload.dr_data,
                pagination: action.payload.pagination,
            };
        case GET_SI_TERMS_CONDITIONS:
            return {
                ...state,
                terms_conditions: action.payload,
            };
        case HANDLE_SI_SELECT_TYPE_RECEIPT:
            return {
                ...state,
                si_data: { ...state.si_data, receipt_type: action.payload },
            };
        case HANDLE_SI_SELECT_TERMS_CONDITIONS:
            return {
                ...state,
                si_data: { ...state.si_data, payment_terms: action.payload },
            };
        case HANDLE_SI_SELECT_PAYMENT_TYPE:
            return {
                ...state,
                si_data: { ...state.si_data, payment_mode: action.payload },
            };
        case MANUAL:
            return {
                ...state,
                si_data: {
                    ...state.si_data,
                    manual_input: action.payload.check,
                    items: action.payload.items,
                    customer: '',
                    customer_id: null,
                    remarks: '',
                    payment_terms: { value: 'none', label: 'None' },
                    receipt_type: {
                        value: 'delivery receipt',
                        label: 'Delivery Receipt',
                    },
                    payment_mode: { value: '', label: 'None' },
                    discount_type: { value: 'none', label: 'None' },
                    discount: 0,
                    delivery_fee: 0,
                },
            };
        // case GET_SI_TEMPLATES:
        //     return {
        //         ...state,
        //         si_data: { ...state.si_data, template_id: action.payload.invoice_template_id },
        //         inv_templates: action.payload.inv_templates,
        //         inv_default_template: action.payload.inv_default_template
        //     }
        case GET_QUOTATION_PRODUCTS:
            return {
                ...state,
                items: action.payload,
                item_template: {
                    ...state.item_template,
                    items: action.payload,
                },
            };
        case GET_SI_CUSTOMERS:
            return {
                ...state,
                customers: action.payload,
            };
        case HANDLE_SI_SELECT_CUSTOMER:
            return {
                ...state,
                si_data: {
                    ...state.si_data,
                    customer_id: action.payload.customer_id,
                    customer: action.payload.customer,
                },
                default_customer_value: action.payload.default_customer_value,
            };
        case HANDLE_SELECT_SI_TEMPLATE:
            return {
                ...state,
                si_data: {
                    ...state.si_data,
                    template_id: action.payload.invoice_template_id,
                },
                inv_default_template: action.payload.inv_default_template,
            };
        case HANDLE_SI_SELECT_ITEM:
            return {
                ...state,
                si_data: { ...state.si_data, items: action.payload },
            };
        case ADD_ITEM:
            return {
                ...state,
                si_data: { ...state.si_data, items: action.payload },
            };
        case TOGGLE_DISCOUNT_ROW:
            const updatedItems = state.si_data.items.map((item, key) => {
                if (key === action.payload.key) {
                    return { ...item, showDiscountRow: !action.payload.remove };
                } else {
                    return item;
                }
            });

            return {
                ...state,
                si_data: {
                    ...state.si_data,
                    items: updatedItems,
                },
            };
        case SET_LAST_ADDED_DROPDOWN_INDEX:
            return {
                ...state,
                si_data: action.payload,
            };
        case REMOVE_ITEM:
            return {
                ...state,
                si_data: { ...state.si_data, items: action.payload.items },
            };

        case COMPUTE_DR:
            return {
                ...state,
                si_data: { ...state.si_data, ...action.payload },
            };
        case SET_MODAL:
            return {
                ...state,
                [action.payload.modal]: action.payload.status,
            };
        case MODAL_LOADING:
            return {
                ...state,
                modal_loading: action.payload,
            };
        case CLEAR_DELIVERY:
            return {
                ...state,
                pagination: {
                    totalCount: 0,
                    activePage: 1,
                    itemsCountPerPage: 1,
                    totalItemsCount: 10,
                    pageRangeDisplayed: 8,
                },
                main_url: '#',
                create_url: '#',
                edit_url: '/deliveries/edit/',
                rows_to_delete: null,
                sort_order_name: null,
                sort_order_direction: null,
                search: '',
                quotations: [],
                customer_modal: false,
                dr_print_modal: false,
                print_modal_si: false,
                si_data: {
                    items: [],

                    customer: '',
                    customer_id: null,
                    lastAddedDropdownIndex: null,
                    customer_street1: '',
                    customer_city: '',
                    customer_country: '',
                    sub_total: 0,
                    total: 0,
                    discount: 0,
                    discount_type: { value: 'none', label: 'None' },
                    showDiscountRow: false,
                    vat_amount: 0,
                    tax: 0,
                    default_tax: 0,
                    tax_type: { value: 'none', label: 'None' },
                    delivery_fee: 0,
                    remakrs: '',
                    total_qty: 0,
                    payment_terms: { value: 'none', label: 'None' },
                    receipt_type: {
                        value: 'delivery receipt',
                        label: 'Delivery Receipt',
                    },
                    payment_mode: { value: '', label: 'None' },
                    customer_data: { value: '', label: '' },
                    status: '',
                    reference_no: '',
                    manual_input: false,
                    is_selected: false,
                    generate_so: null,
                    template_name: '',
                    dr_date: new Date(),
                    delivery_receipt_no: '',
                    dr_status: '',
                    dr_id: '',
                    subs_setting: [],
                    uploads: [],
                    email_sent: false,
                    serial_no: '',
                    delivery_template_id: '',
                },
                new_uploads: [],
                files_to_remove: [],
                quotation_id: null,
                enable_submit: true,
                dr_data: [],
                payment_mode_options: [
                    { value: 'cash', label: 'Cash' },
                    { value: 'credit card', label: 'Credit Card' },
                    { value: 'cod', label: 'COD' },
                    { value: 'check', label: 'Check' },
                    { value: 'bank transfer', label: 'Bank Transfer' },
                ],
                isSearchable: true,
                terms_conditions: [],
                receipt_type_options: [
                    { value: 'invoice', label: 'Invoice' },
                    { value: 'delivery receipt', label: 'Delivery Receipt' },
                ],
                // inv_templates: [],
                // inv_default_template: [],
                customers: [],
                items: [],

                default_customer_value: { value: '', label: 'Choose Customer' },
                default_product_value: { value: '', label: 'Choose Product' },
                itemSelected: {
                    _id: '',
                    amount: 0.0,
                    srp: 0.0,
                    product_id: '',
                    product_name: '',
                    product_unit_id: '',
                    product_unit_name: '',
                    qty: 0,
                    sku: '',
                },
                modal_loading: false,
                tax_types: [
                    { value: 'none', label: 'None' },
                    { value: 'exclusive', label: 'Exclusive' },
                    { value: 'inclusive', label: 'Inclusive' },
                ],
                sending_email: false,
                pre_load: false,
                download_pdf: false,
                form_disable: false,
                email_config: [],
                redirect: false,
                dr_default_template: {},
                delivery_templates: [],
            };
        case DELIVERY_UPDATE_FILE:
            return {
                ...state,
                new_uploads: [...state.new_uploads, ...action.payload],
            };
        case DELIVERY_REMOVE_FILE:
            if (action.payload.status == 'old') {
                return {
                    ...state,
                    si_data: {
                        ...state.si_data,
                        uploads: [
                            ...state.si_data.uploads.filter(
                                (file) => file.name !== action.payload.name
                            ),
                        ],
                    },
                };
            } else {
                return {
                    ...state,
                    new_uploads: [
                        ...state.new_uploads.filter(
                            (file) => file.name !== action.payload.name
                        ),
                    ],
                };
            }
        case DELIVERY_UPLOADS_TO_REMOVE:
            return {
                ...state,
                files_to_remove: [...state.files_to_remove, action.payload],
            };
        case DELIVERY_GET_UPLOADED_FILES:
            return {
                ...state,
                si_data: { ...state.si_data, uploads: action.payload },
                new_uploads: [],
                files_to_remove: [],
            };
        case DELIVERY_CLEAR_UPLOAD:
            return {
                ...state,
                new_uploads: [],
            };
        case DELIVERY_TAX_SELECT_TYPE:
            return {
                ...state,
                si_data: {
                    ...state.si_data,
                    tax: action.payload.tax,
                    tax_type: action.payload.tax_type,
                },
            };
        case GET_DELIVERY_SUB_SETTING:
            return {
                ...state,
                si_data: { ...state.si_data, ...action.payload },
            };
        case DELIVERY_COMPUTATION_HANDLER:
            return {
                ...state,
                si_data: { ...state.si_data, ...action.payload },
            };
        case DELIVERY_INPUT_CHANGE:
            return {
                ...state,
                si_data: {
                    ...state.si_data,
                    [action.payload.key]: action.payload.value,
                },
            };
        case SEND_EMAIL:
            return {
                ...state,
                sending_email: action.payload,
            };
        case RESEND_EMAIL:
            return {
                ...state,
                si_data: { ...state.si_data, email_sent: action.payload },
            };
        case PRE_LOAD:
            return {
                ...state,
                pre_load: action.payload,
            };
        case DOWNLOAD_BTN:
            return {
                ...state,
                download_pdf: action.payload,
            };
        case EMAIL_SETTING_DATA:
            return {
                ...state,
                email_config: action.payload,
            };
        case DR_REDIRECT:
            return {
                ...state,
                redirect: action.payload,
            };
        case DYNAMIC_SEND_EMAIL:
            return {
                ...state,
                dr_data: action.payload,
            };
        case GET_DR_TEMPLATES:
            return {
                ...state,
                si_data: {
                    ...state.si_data,
                    delivery_template_id: action.payload.delivery_template_id,
                },
                dr_default_template: action.payload.dr_default_template,
                delivery_templates: action.payload.delivery_templates,
            };
        case SELECT_DR_TEMPLATE:
            return {
                ...state,
                si_data: {
                    ...state.si_data,
                    delivery_template_id: action.payload.delivery_template_id,
                    dr_default_template: action.payload.dr_default_template,
                },
                dr_default_template: action.payload.dr_default_template,
            };
        case SET_CREATE_SO:
            return {
                ...state,
                si_data: {
                    ...state.si_data,
                    is_selected: action.payload.is_selected, // Set is_selected to true
                    generate_so: action.payload.generate_so, // Update generate_so based on the dropdown selection
                },
            };

        case CLEAR_DATA:
            return {
                ...state,
                si_data: {
                    items: [],

                    customer: '',
                    customer_id: null,
                    lastAddedDropdownIndex: null,
                    customer_street1: '',
                    customer_city: '',
                    customer_country: '',
                    sub_total: 0,
                    total: 0,
                    discount: 0,
                    discount_type: { value: 'none', label: 'None' },
                    showDiscountRow: false,
                    vat_amount: 0,
                    tax: 0,
                    default_tax: 0,
                    tax_type: { value: 'none', label: 'None' },
                    delivery_fee: 0,
                    remakrs: '',
                    total_qty: 0,
                    payment_terms: { value: 'none', label: 'None' },
                    receipt_type: {
                        value: 'delivery receipt',
                        label: 'Delivery Receipt',
                    },
                    payment_mode: { value: '', label: 'None' },
                    customer_data: { value: '', label: '' },
                    status: '',
                    reference_no: '',
                    manual_input: false,
                    is_selected: false,
                    generate_so: null,
                    template_name: '',
                    dr_date: new Date(),
                    delivery_receipt_no: '',
                    dr_status: '',
                    dr_id: '',
                    subs_setting: [],
                    uploads: [],
                    email_sent: false,
                    serial_no: '',
                    delivery_template_id: '',
                },
                new_uploads: [],
                files_to_remove: [],
                quotation_id: null,
                enable_submit: true,
                dr_data: [],
                payment_mode_options: [
                    { value: 'cash', label: 'Cash' },
                    { value: 'credit card', label: 'Credit Card' },
                    { value: 'cod', label: 'COD' },
                    { value: 'check', label: 'Check' },
                    { value: 'bank transfer', label: 'Bank Transfer' },
                ],
                isSearchable: true,
                terms_conditions: [],
                receipt_type_options: [
                    { value: 'invoice', label: 'Invoice' },
                    { value: 'delivery receipt', label: 'Delivery Receipt' },
                ],
                // inv_templates: [],
                // inv_default_template: [],
                customers: [],
                items: [],
                default_customer_value: { value: '', label: 'Choose Customer' },
                default_product_value: { value: '', label: 'Choose Product' },
                itemSelected: {
                    _id: '',
                    amount: 0.0,
                    srp: 0.0,
                    product_id: '',
                    product_name: '',
                    product_unit_id: '',
                    product_unit_name: '',
                    qty: 0,
                    sku: '',
                },
            };
        case BTN_FORM:
            return {
                ...state,
                btn_form: action.payload,
            };
        case HANDLE_ITEM_FORM_DISABLE:
            return {
                ...state,
                form_disable: action.payload,
            };
        case CREATE_PRODUCT:
            const updatedDeliveryItems = state.si_data.items.map(
                (item, index) =>
                    index === action.payload.index
                        ? {
                              ...item,
                              non_inventory: action.payload.non_inventory,
                          }
                        : item
            );

            return {
                ...state,
                si_data: {
                    ...state.si_data,
                    items: updatedDeliveryItems,
                },
            };
        default:
            return state;
            break;
    }
};

export default deliveryReducer;
