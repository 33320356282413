import * as TYPES from '../types';

const initialState = {
    pagination: {
        totalCount: 0,
        activePage: 1,
        itemsCountPerPage: 1,
        totalItemsCount: 10,
        pageRangeDisplayed: 8,
    },
    stock_transfers: [],
    single_stock_transfer: {
        name: '',
        business_name: '',
        street1: '',
        street2: '',
        city: '',
        state: '',
        country: {},
        fax: '',
        email: '',
        landline: '',
        mobile: '',
        contact_person: '',
    },
    default_payment_details_: {
        bank_name: '',
        bank_address: '',
        account_number: '',
        account_name: '',
        swift_code: '',
        mode: 'edit',
        has_saved: 0,
    },
    payment_details: [],
    single_purchase_order: [],
    single_product: [],
    purchase_orders: [],
    display_tab: { purchase_orders: false, items: false },
    date_from: new Date(),
    date_to: new Date(),
    loading: false,
    mini_loading: false,
    isSearchable: true,
    main_page: false,
    edit_page: false,
    delete_modal: false,
    history_page: false,
    purchase_order_page: false,
    product_page: false,
    print_modal: false,
    main_url: '/inventory/stock_transfers',
    create_url: '/inventory/stock_transfers/create',
    edit_url: '/inventory/stock_transfers/edit',
    history_url: '/inventory/stock_transfers/history',
    product_url: '/inventory/stock_transfers/history/product',
    rows_to_delete: null,
    sort_order_name: null,
    sort_order_direction: null,
    set_row_checked: false,
    multi_products: false,
    transfer_item: [],
    qty: 0,
    remarks: '',
    search: '',
    products: [],
};

const StockTransferReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case TYPES.SET_MINI_LOADING:
            return {
                ...state,
                mini_loading: action.payload,
            };
        case TYPES.GET_STOCK_TRANSFERS:
            return {
                ...state,
                stock_transfers: action.payload.stock_transfers,
                pagination: action.payload.pagination,
                set_row_checked: false,
            };
        case TYPES.GET_SINGLE_STOCK_TRANSFER:
            return {
                ...state,
                single_stock_transfer: action.payload,
            };
        case TYPES.SET_PAYMENT_DETAILS:
            return {
                ...state,
                payment_details: action.payload,
            };
        case TYPES.STOCK_TRANSFER_INPUT_CHANGE:
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            };
        case TYPES.SEARCH_INPUT:
            return {
                ...state,
                search: action.payload,
            };
        case TYPES.SELECT_COUNTRY:
            return {
                ...state,
                single_stock_transfer: {
                    ...state.single_stock_transfer,
                    country: action.payload,
                },
            };
        case TYPES.HANDLE_MOBILE:
            return {
                ...state,
                single_stock_transfer: {
                    ...state.single_stock_transfer,
                    mobile: action.payload,
                },
            };
        case TYPES.SET_PAGE:
            return {
                ...state,
                [action.payload.page]: action.payload.status,
            };
        case TYPES.STOCK_TRANSFER_HISTORY_DATE_FILTER_INPUT:
            return {
                ...state,
                [action.payload.key]: action.payload.value,
            };
        case TYPES.STOCK_TRANSFER_HISTORY_DATE_INPUT_CLEAR:
            return {
                ...state,
                date_from: new Date(),
                date_to: new Date(),
                date_filter: false,
                display_tab: { ...state.display_tab, [action.payload]: true },
            };
        case TYPES.GET_STOCK_TRANSFER_HISTORY_PRODUCTS:
            return {
                ...state,
                products: action.payload.products,
                pagination: action.payload.pagination,
                display_tab: { purchase_orders: false, products: true },
            };
        case TYPES.GET_STOCK_TRANSFER_HISTORY_SINGLE_PRODUCT:
            return {
                ...state,
                single_product: action.payload,
            };
        case TYPES.CLEAR_STOCK_TRANSFER:
            return {
                ...state,
                single_stock_transfer: [],
                payment_details: [], // clear payment details as well after clearing single supplier
                display_tab: { purchase_orders: false, products: false },
                search: '',
                loadingl: false,
                main_page: false,
                edit_page: false,
                delete_modal: false,
                history_page: false,
                purchase_order_page: false,
                product_page: false,
                print_modal: false,
                main_url: '/inventory/stock_transfers',
                create_url: '/inventory/stock_transfers/create',
                edit_url: '/inventory/stock_transfers/edit',
                history_url: '/inventory/stock_transfers/history',
                product_url: '/inventory/stock_transfers/history/product',
                rows_to_delete: null,
            };
        case TYPES.SORT_BY_COLUMN:
            return {
                ...state,
                sort_order_name: action.payload.sort_order_name,
                sort_order_direction: action.payload.sort_order_direction,
            };
        case TYPES.GET_ROWS_TO_DELETE:
            return {
                ...state,
                rows_to_delete: action.payload,
            };
        case TYPES.SET_ROW_CHECKED:
            return {
                ...state,
                set_row_checked: true,
            };
        case TYPES.SET_MODAL:
            return {
                ...state,
                [action.payload.modal]: action.payload.status,
            };
        case TYPES.RETURN_CLEAR:
            return {
                ...state,
                qty: action.payload.qty,
                remarks: action.payload.remarks,
            };
        case TYPES.STOCK_TRANSFER_UPDATE_QTY:
            return {
                ...state,
                qty: action.payload,
            };
        case TYPES.STOCK_TRANSFER_GET_SCAN:
            return {
                ...state,
                search: '',
                transfer_item: action.payload,
                multi_products: false,
            };
        case TYPES.STOCK_TRANSFER_GET_PRODUCTS:
            return {
                ...state,
                products: action.payload.products,
                multi_products: true,
            };
        case TYPES.STOCK_REMOVE_SCAN:
            return {
                ...state,
                pos_item: action.payload,
                search: '',
                qty: 1,
                remarks: '',
            };
        default:
            return state;
            break;
    }
};

export default StockTransferReducer;
