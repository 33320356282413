import React, { Fragment, useEffect, useState, useMemo } from 'react';
import Spinner from '../layouts/Spinner';
import { connect } from 'react-redux';
import { MiniSpinner } from '../layouts/Spinner';
import ReactTooltip from 'react-tooltip';
import AsyncSelect from 'react-select/async';
import Select, { components } from 'react-select';
import DatePicker from 'react-datepicker';
import {
    getQuotations,
    searchQuotation,
    getQuotationItems,
    update_delivery,
    handleSelectPaymentType,
    getTermsConditions,
    handleSelectTermsConditions,
    handleSelectReceiptType,
    checkBoxManual,
    handleSelectDRTemplate,
    getDeliveryTemplates,
    getCustomers,
    getProducts,
    handleSelectCustomer,
    handleSelectItem,
    changeQty,
    addItem,
    remItem,
    handleDiscountItem,
    handleSelectDiscountTypeProduct,
    handleInputDiscountProduct,
    vat_change,
    deliveryFee,
    discountType,
    discountInput,
    refNo,
    remarksInput,
    viewDr,
    searchProducts,
    handleInputChangeDiscount,
    handleSelectDiscountType,
    handleTaxType,
    computationHandler,
    ShowComboBox,
    cancelDr,
    handleCreateSO,
    dynamicInputChange,
    createProduct,
    skuSkipValidation,
    generateSku,
} from '../../redux/actions/delivery/delivery.actions';
import { numberFormat, stockClass } from '../../Utils/Common';
import { SwalWarning } from '../../Services/_swal.service';
import { Link, Redirect } from 'react-router-dom';
import { Show } from 'react-redux-permission';
import { SwalFormWarning } from '../../Services/_swal.service';
import BeforeUnloadComponent from 'react-beforeunload-component';
import { useHistory, useLocation } from 'react-router-dom';
import { blockRoute } from '../../redux/actions/helper/helper.actions';
import DynamicFont from 'react-dynamic-font';
import debounce from 'debounce-promise';
import { subscriberSetting } from '../../redux/actions/settings_app/setting_app.actions';
import { ToastWarning } from '../../Services/_toast.service';

function DeliveryEdit({
    delivery: {
        quotations,
        search,
        si_data,
        loading,
        enable_submit,
        quotation_id,
        payment_mode_options,
        isSearchable,
        terms_conditions,
        receipt_type_options,
        delivery_templates,
        dr_default_template,
        customers,
        items,
        default_customer_value,
        itemSelected,
        tax_types,
        redirect,
        btn_form,
    },
    getQuotations,
    searchQuotation,
    getQuotationItems,
    update_delivery,
    handleSelectPaymentType,
    getTermsConditions,
    handleSelectTermsConditions,
    handleSelectReceiptType,
    checkBoxManual,
    handleSelectDRTemplate,
    getDeliveryTemplates,
    getCustomers,
    getProducts,
    handleSelectCustomer,
    handleSelectItem,
    changeQty,
    addItem,
    remItem,
    handleDiscountItem,
    handleSelectDiscountTypeProduct,
    handleInputDiscountProduct,
    vat_change,
    deliveryFee,
    discountType,
    discountInput,
    refNo,
    remarksInput,
    match,
    viewDr,
    block_route,
    blockRoute,
    searchProducts,
    handleInputChangeDiscount,
    handleSelectDiscountType,
    handleTaxType,
    computationHandler,
    subscriberSetting,
    ShowComboBox,
    cancelDr,
    handleCreateSO,
    dynamicInputChange,
    createProduct,
    skuSkipValidation,
    generateSku,
}) {
    // console.log("si_data", si_data);
    const [loadingData, setLoadingData] = useState(true);
    const history = useHistory();
    const [delivery_date, setdatePick] = useState(si_data.dr_date);
    useEffect(() => {
        blockRoute(false);
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoadingData(true);

                await getDeliveryTemplates();
                await getQuotations();
                await getTermsConditions();
                await getCustomers();
                await getProducts();
                await subscriberSetting('delivery');
                await viewDr(match.params.id);

                setLoadingData(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoadingData(false);
            }
        };

        fetchData();
    }, [search]);

    // si_data.manual_input && si_data.status == 'draft'
    const { discount, discount_type, tax, tax_type, other } = si_data;
    useEffect(() => {
        computationHandler();
    }, [
        JSON.stringify(si_data.items),
        discount,
        discount_type,
        tax,
        tax_type,
        other,
    ]);

    useMemo(() => {
        // if (si_data.manual_input && si_data.status == "draft") {
        //   subscriberSetting("delivery");
        // }
    }, [si_data.manual_input]);

    useEffect(() => {
        // When onClick navbar prevent another dialog box
        window.addEventListener('beforeunload', function (e) {
            // the absence of a returnValue property on the event will guarantee the browser unload happens
            delete e['returnValue'];
            blockRoute(false);
            console.log('01');
        });

        if (block_route) {
            window.history.pushState(null, null, window.location.pathname);
            window.addEventListener('popstate', onBackButtonEvent);
            console.log('02');
        }

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [block_route]);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if (block_route) {
            SwalFormWarning(
                'Warning',
                'Do you want to leave without saving',
                () => goLastLocation(),
                () => blockAgain()
            );
        }
    };

    const goLastLocation = () => {
        history.goBack();
        blockRoute(false);
    };

    const blockAgain = () => {
        window.history.pushState(null, null, window.location.pathname);
        console.log('03');
    };

    const submitClick = (status) => {
        let stat_arr = status.split('-');
        let stats = stat_arr[0];
        let actions = stat_arr[1] !== undefined ? stat_arr[1] : null;
        if (si_data.is_selected === true) {
            if (default_customer_value.value === '') {
                ToastWarning('Please Select Customer');
            } else {
                const formdata = {
                    dr_id: match.params.id,
                    date: delivery_date,
                    dr_status: stats,
                    si_data: si_data,
                };
                // if(status == 'draft')
                // {
                //     update_delivery(formdata);
                // }
                // else
                // {
                //     SwalWarning('Warning!', "You are about to save Delivery Receipt, saving this will stop you from editing any other changes. Continue?", () => update_delivery(formdata))
                // }
                if (status == 'saved' || status == 'saved-print') {
                    SwalWarning(
                        'Warning!',
                        'You are about to save Delivery Receipt, saving this will stop you from editing any other changes. Continue?',
                        () => update_delivery(formdata, actions)
                    );
                } else if (status == 'saved-email') {
                    SwalWarning(
                        'Warning!',
                        'You are about to save and email Delivery Receipt, saving this will stop you from editing any other changes. Continue?',
                        () => update_delivery(formdata, actions)
                    );
                } else if (status == 'draft-email') {
                    SwalWarning(
                        'Warning!',
                        'You are about to draft and email Delivery Receipt, saving this will stop you from editing any other changes. Continue?',
                        () => update_delivery(formdata, actions)
                    );
                } else {
                    update_delivery(formdata, actions);
                }
            }
        } else {
            const formdata = {
                dr_id: match.params.id,
                date: delivery_date,
                dr_status: stats,
                si_data: si_data,
            };
            if (status == 'saved' || status == 'saved-print') {
                SwalWarning(
                    'Warning!',
                    'You are about to save Delivery Receipt, saving this will stop you from editing any other changes. Continue?',
                    () => update_delivery(formdata)
                );
            } else if (status == 'saved-email') {
                SwalWarning(
                    'Warning!',
                    'You are about to save and email Delivery Receipt, saving this will stop you from editing any other changes. Continue?',
                    () => update_delivery(formdata)
                );
            } else if (status == 'draft-email') {
                SwalWarning(
                    'Warning!',
                    'You are about to draft and email Delivery Receipt, saving this will stop you from editing any other changes. Continue?',
                    () => update_delivery(formdata)
                );
            } else {
                update_delivery(formdata);
            }
        }
    };
    const Option = (props) => {
        return (
            <components.Option {...props}>
                <div>
                    {props.data.label} [{props.data.brand}]
                </div>
                <div style={{ fontSize: 11 }}>{props.data.sku}</div>
                <div style={{ fontSize: 11 }}>{props.data.description}</div>
            </components.Option>
        );
    };

    const customFilter = (option, searchText) => {
        if (
            option.data.label
                .toLowerCase()
                .includes(searchText.toLowerCase()) ||
            option.data.barcode.toLowerCase().includes(searchText.toLowerCase())
        ) {
            return true;
        } else {
            return false;
        }
    };

    const status_lbl = (
        <span className={`lbl-status mr-1 lbl-${si_data.status}`}>
            {si_data.status}
        </span>
    );
    const payment_status =
        si_data.payment_mode.value != '' ? (
            <span
                className={`lbl-status mr-1 lbl-payment-type lbl-${si_data.payment_mode.label}`}
            >
                {si_data.payment_mode.label}
            </span>
        ) : (
            ''
        );

    // let percent_value = [
    //   { value: 0, label: "None" },
    //   { value: 1, label: "Percent" },
    //   { value: 2, label: "Amount" },
    // ];

    let percent_value = [
        { value: 'none', label: 'None' },
        { value: 'percent', label: 'Percent' },
        { value: 'amount', label: 'Amount' },
    ];

    // console.log('sub', si_data)

    // console.log("dr_default_template", dr_default_template);
    if (redirect) {
        return <Redirect to={'/deliveries'} />;
    }

    // console.log("si_data", si_data);

    return (
        <BeforeUnloadComponent
            ignoreBeforeunloadDocument={true}
            blockRoute={block_route}
            modalComponentHandler={({
                handleModalLeave,
                handleModalCancel,
            }) => {
                SwalFormWarning(
                    'Warning',
                    'Do you want to leave without saving',
                    () => handleModalLeave(),
                    () => handleModalCancel()
                );
            }}
        >
            <div className="app-content content">
                <ReactTooltip effect="solid" event="click" />
                <div className="content-wrapper max-1920 mx-auto">
                    <div className="content-wrapper-before"></div>
                    <div className="content-header row">
                        <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                            <h3 className="content-header-title mb-0 d-inline-block">
                                Delivery Receipt No.{' '}
                            </h3>

                            <div className="breadcrumbs-top d-inline-block">
                                <div className="breadcrumb-wrapper mr-1">
                                    <li className="breadcrumb f-regular">
                                        <li className="breadcrumb-item ">
                                            <a href="#" className="text-white">
                                                {si_data.delivery_receipt_no}
                                            </a>
                                        </li>
                                    </li>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-body margin-top-5">
                        <section className="row f-regular">
                            <div className="col-sm-12">
                                <div id="what-is" className="card ">
                                    <div className="card-header">
                                        <Link to="/deliveries">
                                            <i className="ft ft-arrow-left"></i>{' '}
                                            Back
                                        </Link>
                                        <a className="heading-elements-toggle">
                                            <i className="la la-ellipsis-v font-medium-3"></i>
                                        </a>
                                    </div>
                                    <div>
                                        {loading ? (
                                            <MiniSpinner />
                                        ) : (
                                            <div className="card-content collapse show f-regular">
                                                <div className="card-body pb-10m">
                                                    <div className="row">
                                                        <div className="col-md-8"></div>
                                                        <div className="col-md-4">
                                                            <div className="form-group row">
                                                                <label className="col-md-12">
                                                                    Create Sales
                                                                    Order{' '}
                                                                    <span className="red">
                                                                        *
                                                                    </span>
                                                                </label>

                                                                <div className="col-md-10 pr-0">
                                                                    {/* Show dropdown if generate_so is false (or "No") */}
                                                                    {si_data.generate_sales_order ===
                                                                    false ? (
                                                                        <Select
                                                                            value={{
                                                                                value: si_data.generate_so
                                                                                    ? 'yes'
                                                                                    : 'no',
                                                                                label: si_data.generate_so
                                                                                    ? 'Yes'
                                                                                    : 'No',
                                                                            }}
                                                                            onChange={
                                                                                handleCreateSO
                                                                            } // Dynamic value handler
                                                                            options={[
                                                                                {
                                                                                    value: 'yes',
                                                                                    label: 'Yes',
                                                                                },
                                                                                {
                                                                                    value: 'no',
                                                                                    label: 'No',
                                                                                },
                                                                            ]}
                                                                            placeholder="Select"
                                                                            required
                                                                        />
                                                                    ) : (
                                                                        // Show value when generate_so is true (or "Yes")
                                                                        <div className="form-control f-regular">
                                                                            {si_data.generate_sales_order
                                                                                ? 'Yes'
                                                                                : 'No'}
                                                                        </div>
                                                                    )}
                                                                </div>

                                                                <div className="col-md-2 pl-0 text-right">
                                                                    <span
                                                                        className="tool-tip text-primary"
                                                                        data-tip="Choose Yes or No to generate Sales Order"
                                                                    >
                                                                        <i className="la la-question-circle"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            {si_data.status ==
                                                            'draft' ? (
                                                                <section>
                                                                    <div className="form-group">
                                                                        <label>
                                                                            Template{' '}
                                                                            <span className="text-danger">
                                                                                *
                                                                            </span>
                                                                        </label>
                                                                        <div className="input-group input-group-full">
                                                                            <Select
                                                                                options={
                                                                                    delivery_templates
                                                                                }
                                                                                onChange={
                                                                                    handleSelectDRTemplate
                                                                                }
                                                                                value={
                                                                                    si_data.dr_default_template
                                                                                }
                                                                                isSearchable={
                                                                                    isSearchable
                                                                                }
                                                                                required
                                                                            />
                                                                            <div className="input-group-prepend">
                                                                                <span
                                                                                    href="#"
                                                                                    className="tool-tip text-primary"
                                                                                    data-tip="this is a tool tip"
                                                                                >
                                                                                    <i className="la la-question-circle"></i>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>
                                                                            Customer{' '}
                                                                            <span className="text-danger">
                                                                                *
                                                                            </span>{' '}
                                                                        </label>
                                                                        <div className="input-group input-group-full">
                                                                            {/* <Select
                                  options={customers}
                                  isSearchable={isSearchable}
                                  value={default_customer_value}
                                  onChange={handleSelectCustomer}
                                  placeholder='Select Customer...'
                                  required
                                /> */}
                                                                            <div className="form-control f-regular">
                                                                                {
                                                                                    default_customer_value.label
                                                                                }
                                                                            </div>
                                                                            <div className="input-group-prepend">
                                                                                <span
                                                                                    href="#"
                                                                                    className="tool-tip text-primary"
                                                                                    data-tip="this is a tool tip"
                                                                                >
                                                                                    <i className="la la-question-circle"></i>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                            ) : (
                                                                <section>
                                                                    <div className="form-group">
                                                                        <label>
                                                                            Template
                                                                        </label>
                                                                        <div className="input-group input-group-full">
                                                                            {si_data.status ===
                                                                            'draft' ? (
                                                                                <Select
                                                                                    options={
                                                                                        delivery_templates
                                                                                    }
                                                                                    onChange={
                                                                                        handleSelectDRTemplate
                                                                                    }
                                                                                    value={
                                                                                        si_data.dr_default_template
                                                                                    }
                                                                                    isSearchable={
                                                                                        isSearchable
                                                                                    }
                                                                                    required
                                                                                />
                                                                            ) : (
                                                                                <div className="form-control f-regular">
                                                                                    {si_data.dr_default_template
                                                                                        ? si_data
                                                                                              .dr_default_template
                                                                                              .label
                                                                                        : ''}
                                                                                </div>
                                                                            )}
                                                                            <div className="input-group-prepend">
                                                                                <span
                                                                                    href="#"
                                                                                    className="tool-tip text-primary"
                                                                                    data-tip="this is a tool tip"
                                                                                >
                                                                                    <i className="la la-question-circle"></i>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label>
                                                                            Customer
                                                                            Name
                                                                        </label>
                                                                        <div className="form-control f-regular">
                                                                            {
                                                                                si_data
                                                                                    .customer
                                                                                    .name
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                            )}
                                                        </div>
                                                        <div className="col-md-4"></div>
                                                        <div className="col-md-4">
                                                            <section>
                                                                <div className="form-group row">
                                                                    <label className="col-md-12">
                                                                        Reference
                                                                        No.
                                                                        <span className="red">
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <div className="col-md-10 col-10 pr-0">
                                                                        <div className="form-control f-regular">
                                                                            {
                                                                                si_data.reference_no
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="float-right pl-0 col-md-2 col-2">
                                                                        <div className="input-group-prepend">
                                                                            <span
                                                                                href="#"
                                                                                className="tool-tip text-primary"
                                                                                data-tip="this is a tool tip"
                                                                            >
                                                                                <i className="la la-question-circle"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </section>

                                                            <div className="form-group row">
                                                                <label className="col-md-12">
                                                                    Received
                                                                    Date
                                                                </label>
                                                                <div className="col-md-10 col-10 pr-0">
                                                                    {si_data.status ==
                                                                    'draft' ? (
                                                                        <div className="date-picker-100">
                                                                            <DatePicker
                                                                                onChange={(
                                                                                    date
                                                                                ) =>
                                                                                    setdatePick(
                                                                                        date
                                                                                    )
                                                                                }
                                                                                selected={
                                                                                    delivery_date
                                                                                }
                                                                                className="form-control f-regular"
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        <div className="form-control f-regular">
                                                                            {
                                                                                si_data.delivery_date_string
                                                                            }
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className="float-right pl-0 col-md-2 col-2">
                                                                    <div className="input-group-prepend">
                                                                        <span
                                                                            href="#"
                                                                            className="tool-tip text-primary"
                                                                            data-tip="this is a tool tip"
                                                                        >
                                                                            <i className="la la-question-circle"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="table-responsive">
                                                                <table className="table table-condensed table-bordered f-regular">
                                                                    <thead>
                                                                        {/* <tr>
                                  <th className='text-center td-16m'>SKU</th>
                                  <th className='text-center'>Product</th>
                                  <th className='text-center'>Qty</th>
                                  <th className='text-center'>Unit</th>
                                  <th className='text-center'>SRP</th>
                                  <th className='text-center'>Unit/Price</th>
                                  <th className='text-center'>Amount</th>
                                  {si_data.manual_input &&
                                  si_data.status == "draft" ? (
                                    <th width='100'></th>
                                  ) : (
                                    <></>
                                  )}
                                </tr> */}

                                                                        <tr>
                                                                            <th className="text-left td-16m">
                                                                                SKU
                                                                            </th>
                                                                            <th className="text-center">
                                                                                Product
                                                                            </th>
                                                                            <th className="text-center">
                                                                                Qty
                                                                            </th>
                                                                            <th className="text-center">
                                                                                Unit
                                                                            </th>
                                                                            <th className="text-center">
                                                                                SRP
                                                                            </th>
                                                                            <th className="text-center">
                                                                                Unit/Price
                                                                            </th>
                                                                            <th className="text-center min-width-80">
                                                                                Amount
                                                                            </th>
                                                                            {si_data.status ==
                                                                            'draft' ? (
                                                                                <th width="100">
                                                                                    Action
                                                                                </th>
                                                                            ) : (
                                                                                <>

                                                                                </>
                                                                            )}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {si_data
                                                                            .items
                                                                            .length <=
                                                                        0 ? (
                                                                            <tr className="bg-warning bg-accent-2">
                                                                                <td
                                                                                    colSpan={
                                                                                        si_data.is_selected &&
                                                                                        si_data.status ==
                                                                                            'draft'
                                                                                            ? 7
                                                                                            : 6
                                                                                    }
                                                                                    className="text-center"
                                                                                >
                                                                                    --Please
                                                                                    select
                                                                                    if
                                                                                    you
                                                                                    want
                                                                                    to
                                                                                    Generate
                                                                                    Sales
                                                                                    Order.--
                                                                                </td>
                                                                            </tr>
                                                                        ) : loading ? (
                                                                            <tr>
                                                                                <td
                                                                                    colSpan={
                                                                                        si_data.is_selected &&
                                                                                        si_data.status ==
                                                                                            'draft'
                                                                                            ? 7
                                                                                            : 6
                                                                                    }
                                                                                    className="text-center"
                                                                                >
                                                                                    <Spinner />
                                                                                </td>
                                                                            </tr>
                                                                        ) : // !si_data.generate_so &&
                                                                        si_data.status ==
                                                                          'draft' ? (
                                                                            si_data.items.map(
                                                                                (
                                                                                    data,
                                                                                    key
                                                                                ) => {
                                                                                    return (
                                                                                        <Fragment
                                                                                            key={
                                                                                                key
                                                                                            }
                                                                                        >
                                                                                            {' '}
                                                                                            {/* This is the key part */}
                                                                                            <tr
                                                                                                className={stockClass(
                                                                                                    data.status_code
                                                                                                )}
                                                                                            >
                                                                                                {/* <tr
                                      key={key}
                                      className={stockClass(data.status_code)}
                                    > */}
                                                                                                <td className="table-data__sku ">
                                                                                                    {data.non_inventory ? (
                                                                                                        <>
                                                                                                            <div className="input-group">
                                                                                                                {/* SKU Input Field */}
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    className="form-control text-left"
                                                                                                                    id="sku"
                                                                                                                    name="sku"
                                                                                                                    value={
                                                                                                                        data.sku
                                                                                                                    }
                                                                                                                    step="any"
                                                                                                                    onBlur={(
                                                                                                                        e
                                                                                                                    ) =>
                                                                                                                        skuSkipValidation(
                                                                                                                            e,
                                                                                                                            key
                                                                                                                        )
                                                                                                                    }
                                                                                                                    onChange={(
                                                                                                                        e
                                                                                                                    ) =>
                                                                                                                        dynamicInputChange(
                                                                                                                            e,
                                                                                                                            key
                                                                                                                        )
                                                                                                                    }
                                                                                                                    required
                                                                                                                />
                                                                                                                {/* Generate Button */}
                                                                                                                {data.sku ? (
                                                                                                                    <>

                                                                                                                    </>
                                                                                                                ) : (
                                                                                                                    <button
                                                                                                                        type="button"
                                                                                                                        className="border border-0 bg-white"
                                                                                                                        onClick={(
                                                                                                                            e
                                                                                                                        ) =>
                                                                                                                            generateSku(
                                                                                                                                e,
                                                                                                                                key
                                                                                                                            )
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {' '}
                                                                                                                        <i className="la la-cogs text-primary"></i>
                                                                                                                    </button>
                                                                                                                )}
                                                                                                            </div>
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    fontSize:
                                                                                                                        '10px',
                                                                                                                }}
                                                                                                            >
                                                                                                                <i
                                                                                                                    className="la la-check-square text-primary"
                                                                                                                    style={{
                                                                                                                        fontSize:
                                                                                                                            '10px',
                                                                                                                    }}
                                                                                                                ></i>{' '}
                                                                                                                Non-Inv
                                                                                                            </div>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            {
                                                                                                                data.sku
                                                                                                            }
                                                                                                        </>
                                                                                                    )}
                                                                                                </td>
                                                                                                <td className="min-wdth-300 max-wdth-300">
                                                                                                    {data.new ? (
                                                                                                        <div>
                                                                                                            <div className="mw-90">
                                                                                                                <Link
                                                                                                                    to={`/inventory/view/${data._id}`}
                                                                                                                    target="_blank"
                                                                                                                    onClick={(
                                                                                                                        e
                                                                                                                    ) =>
                                                                                                                        e.stopPropagation()
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        data.product_name
                                                                                                                    }
                                                                                                                </Link>
                                                                                                            </div>
                                                                                                            <div className="f-12 text-dark">
                                                                                                                {
                                                                                                                    data.barcode
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className="f-12 text-dark">
                                                                                                                {
                                                                                                                    data.description
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className="f-12 text-dark">
                                                                                                                {
                                                                                                                    data.brand
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    ) : data.non_inventory ? (
                                                                                                        <div className="d-flex">
                                                                                                            <input
                                                                                                                type="text"
                                                                                                                className="form-control text-left"
                                                                                                                name="product_name"
                                                                                                                value={
                                                                                                                    data.product_name
                                                                                                                }
                                                                                                                step="any"
                                                                                                                onChange={(
                                                                                                                    e
                                                                                                                ) =>
                                                                                                                    dynamicInputChange(
                                                                                                                        e,
                                                                                                                        key
                                                                                                                    )
                                                                                                                }
                                                                                                                required
                                                                                                            />

                                                                                                            <div className="d-flex align-items-center ml-1">
                                                                                                                <Show when="inv.receiving.itemReceipts.cost">
                                                                                                                    <a
                                                                                                                        className="ml-10"
                                                                                                                        onClick={() =>
                                                                                                                            createProduct(
                                                                                                                                key,
                                                                                                                                false
                                                                                                                            )
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <i className="ft ft-x-circle text-warning"></i>
                                                                                                                    </a>
                                                                                                                </Show>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    ) : data.selected ? (
                                                                                                        <div>
                                                                                                            {/* <div className='f-right'  onClick={() => ShowComboBox(key)}>
                                              <i className='ft ft-chevron-down c-pointer'></i>
                                            </div> */}
                                                                                                            <div className="mw-90">
                                                                                                                <Link
                                                                                                                    to={`/inventory/view/${data._id}`}
                                                                                                                    target="_blank"
                                                                                                                    onClick={(
                                                                                                                        e
                                                                                                                    ) =>
                                                                                                                        e.stopPropagation()
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        data.product_name
                                                                                                                    }
                                                                                                                </Link>
                                                                                                            </div>
                                                                                                            <div className="f-12 text-dark">
                                                                                                                {
                                                                                                                    data.barcode
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className="f-12 text-dark">
                                                                                                                {
                                                                                                                    data.description
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className="f-12 text-dark">
                                                                                                                {
                                                                                                                    data.brand
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    ) : (
                                                                                                        <div className="d-flex">
                                                                                                            <AsyncSelect
                                                                                                                className="w-100"
                                                                                                                value={
                                                                                                                    data.product_value
                                                                                                                }
                                                                                                                isLoading={
                                                                                                                    loading
                                                                                                                }
                                                                                                                components={{
                                                                                                                    Option,
                                                                                                                }}
                                                                                                                classNamePrefix="lp-copy-sel"
                                                                                                                loadingMessage={() =>
                                                                                                                    'searching...'
                                                                                                                }
                                                                                                                noOptionsMessage={({
                                                                                                                    inputValue,
                                                                                                                }) =>
                                                                                                                    inputValue
                                                                                                                        ? 'No Products'
                                                                                                                        : 'Search product'
                                                                                                                }
                                                                                                                defaultOptions={
                                                                                                                    data.items
                                                                                                                }
                                                                                                                loadOptions={debounce(
                                                                                                                    (
                                                                                                                        value
                                                                                                                    ) =>
                                                                                                                        searchProducts(
                                                                                                                            value,
                                                                                                                            key
                                                                                                                        ),
                                                                                                                    1000
                                                                                                                )}
                                                                                                                isSearchable={
                                                                                                                    true
                                                                                                                }
                                                                                                                cacheOptions
                                                                                                                onChange={(
                                                                                                                    val
                                                                                                                ) =>
                                                                                                                    handleSelectItem(
                                                                                                                        val,
                                                                                                                        key
                                                                                                                    )
                                                                                                                }
                                                                                                                placeholder="Select Products..."
                                                                                                                required
                                                                                                            />{' '}
                                                                                                            <div className="d-flex align-items-center ml-1">
                                                                                                                <a
                                                                                                                    className="ml-10"
                                                                                                                    onClick={() =>
                                                                                                                        createProduct(
                                                                                                                            key,
                                                                                                                            true
                                                                                                                        )
                                                                                                                    }
                                                                                                                >
                                                                                                                    <i className="la la-cart-plus text-primary"></i>
                                                                                                                </a>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </td>
                                                                                                <td
                                                                                                    className="mw-6m text-center"
                                                                                                    align="center"
                                                                                                >
                                                                                                    {data.non_inventory ? (
                                                                                                        <input
                                                                                                            value={
                                                                                                                data.qty
                                                                                                            }
                                                                                                            onChange={(
                                                                                                                e
                                                                                                            ) =>
                                                                                                                dynamicInputChange(
                                                                                                                    e,
                                                                                                                    key
                                                                                                                )
                                                                                                            }
                                                                                                            className="form-control text-center"
                                                                                                            type="number"
                                                                                                            name="qty"
                                                                                                            min={
                                                                                                                0
                                                                                                            }
                                                                                                        />
                                                                                                    ) : (
                                                                                                        <input
                                                                                                            value={
                                                                                                                data.qty
                                                                                                            }
                                                                                                            onChange={(
                                                                                                                e
                                                                                                            ) =>
                                                                                                                changeQty(
                                                                                                                    e,
                                                                                                                    key
                                                                                                                )
                                                                                                            }
                                                                                                            className="form-control text-center"
                                                                                                            type="number"
                                                                                                            name="qty"
                                                                                                            min={
                                                                                                                0
                                                                                                            }
                                                                                                        />
                                                                                                    )}
                                                                                                </td>
                                                                                                <td className="min-wdth-100 max-wdth-100 text-center">
                                                                                                    {data.non_inventory ? (
                                                                                                        <>
                                                                                                            <input
                                                                                                                type="text"
                                                                                                                className="form-control text-left"
                                                                                                                name="product_unit_name"
                                                                                                                value={
                                                                                                                    data.product_unit_name
                                                                                                                }
                                                                                                                step="any"
                                                                                                                onChange={(
                                                                                                                    e
                                                                                                                ) =>
                                                                                                                    dynamicInputChange(
                                                                                                                        e,
                                                                                                                        key
                                                                                                                    )
                                                                                                                }
                                                                                                                required
                                                                                                            />
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            {
                                                                                                                data.product_unit_name
                                                                                                            }
                                                                                                        </>
                                                                                                    )}
                                                                                                </td>
                                                                                                <td className="mw-8m text-right">
                                                                                                    {data.non_inventory ? (
                                                                                                        <input
                                                                                                            value={
                                                                                                                data.srp
                                                                                                            }
                                                                                                            onChange={(
                                                                                                                e
                                                                                                            ) =>
                                                                                                                dynamicInputChange(
                                                                                                                    e,
                                                                                                                    key
                                                                                                                )
                                                                                                            }
                                                                                                            className="form-control text-right"
                                                                                                            type="number"
                                                                                                            name="srp"
                                                                                                            min={
                                                                                                                0
                                                                                                            }
                                                                                                        />
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            {numberFormat(
                                                                                                                data.srp
                                                                                                            )}
                                                                                                        </>
                                                                                                    )}
                                                                                                </td>
                                                                                                <td className="mw-8m text-right">
                                                                                                    {data.unit_price
                                                                                                        ? numberFormat(
                                                                                                              data.unit_price
                                                                                                          )
                                                                                                        : numberFormat(
                                                                                                              data.srp
                                                                                                          )}
                                                                                                </td>
                                                                                                <td className="min-wdth-150 max-wdth-150 text-right">
                                                                                                    {numberFormat(
                                                                                                        data.amount
                                                                                                    )}
                                                                                                </td>
                                                                                                <td className="text-center mw-2m">
                                                                                                    {!data.non_inventory ? (
                                                                                                        <>
                                                                                                            <span className="m-1">
                                                                                                                <button
                                                                                                                    className="btn btn-sm btn-primary"
                                                                                                                    onClick={(
                                                                                                                        e
                                                                                                                    ) =>
                                                                                                                        handleDiscountItem(
                                                                                                                            key,
                                                                                                                            false
                                                                                                                        )
                                                                                                                    }
                                                                                                                    type="button"
                                                                                                                >
                                                                                                                    <i className="ft ft-tag"></i>
                                                                                                                </button>
                                                                                                            </span>

                                                                                                            <span>
                                                                                                                <button
                                                                                                                    className="btn btn-danger btn-sm"
                                                                                                                    onClick={(
                                                                                                                        e
                                                                                                                    ) =>
                                                                                                                        remItem(
                                                                                                                            key
                                                                                                                        )
                                                                                                                    }
                                                                                                                >
                                                                                                                    <i className="ft-trash-2"></i>
                                                                                                                </button>
                                                                                                            </span>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            <span>
                                                                                                                <button
                                                                                                                    className="btn btn-danger btn-sm"
                                                                                                                    onClick={(
                                                                                                                        e
                                                                                                                    ) =>
                                                                                                                        remItem(
                                                                                                                            key
                                                                                                                        )
                                                                                                                    }
                                                                                                                >
                                                                                                                    <i className="ft-trash-2"></i>
                                                                                                                </button>
                                                                                                            </span>
                                                                                                        </>
                                                                                                    )}
                                                                                                </td>
                                                                                            </tr>
                                                                                            {(data.discount_type &&
                                                                                                data
                                                                                                    .discount_type
                                                                                                    .value !==
                                                                                                    'none') ||
                                                                                            data.showDiscountRow ? (
                                                                                                <tr>
                                                                                                    <td
                                                                                                        colSpan={
                                                                                                            1
                                                                                                        }
                                                                                                    ></td>
                                                                                                    <td
                                                                                                        className="d-flex ali justify-content-center align-items-center"
                                                                                                        style={{
                                                                                                            gap: '5%',
                                                                                                        }}
                                                                                                    >
                                                                                                        <span>
                                                                                                            Discount:
                                                                                                        </span>

                                                                                                        <span className="w-20 table-data__discount">
                                                                                                            {(() => {
                                                                                                                switch (
                                                                                                                    data
                                                                                                                        .discount_type
                                                                                                                        .value
                                                                                                                ) {
                                                                                                                    case 'percent':
                                                                                                                        return (
                                                                                                                            <div class="input-group">
                                                                                                                                <input
                                                                                                                                    onChange={(
                                                                                                                                        e
                                                                                                                                    ) =>
                                                                                                                                        handleInputDiscountProduct(
                                                                                                                                            e,
                                                                                                                                            key
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                    value={
                                                                                                                                        data.discount
                                                                                                                                    }
                                                                                                                                    name="discount"
                                                                                                                                    className="form-control text-right f-regular"
                                                                                                                                    type="number"
                                                                                                                                />
                                                                                                                                <div class="input-group-append">
                                                                                                                                    <span className="input-group-text py-0 f-regular discount-1">
                                                                                                                                        %
                                                                                                                                    </span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        );
                                                                                                                    case 'amount':
                                                                                                                        return (
                                                                                                                            <input
                                                                                                                                onChange={(
                                                                                                                                    e
                                                                                                                                ) =>
                                                                                                                                    handleInputDiscountProduct(
                                                                                                                                        e,
                                                                                                                                        key
                                                                                                                                    )
                                                                                                                                }
                                                                                                                                value={
                                                                                                                                    data.discount
                                                                                                                                }
                                                                                                                                name="discount"
                                                                                                                                className="form-control text-right f-regular"
                                                                                                                                type="number"
                                                                                                                            />
                                                                                                                        );
                                                                                                                    default: // 0 disabled
                                                                                                                        return (
                                                                                                                            <input
                                                                                                                                value={
                                                                                                                                    data.discount
                                                                                                                                }
                                                                                                                                disabled
                                                                                                                                name="discount"
                                                                                                                                className="form-control text-right f-regular"
                                                                                                                                type="number"
                                                                                                                            />
                                                                                                                        );
                                                                                                                }
                                                                                                            })()}
                                                                                                        </span>
                                                                                                        <span className="table-data__discount-dropdown">
                                                                                                            <Select
                                                                                                                name="discount_type"
                                                                                                                onChange={(
                                                                                                                    selectOption
                                                                                                                ) =>
                                                                                                                    handleSelectDiscountTypeProduct(
                                                                                                                        selectOption,
                                                                                                                        key
                                                                                                                    )
                                                                                                                }
                                                                                                                menuPortalTarget={
                                                                                                                    document.body
                                                                                                                }
                                                                                                                value={percent_value.find(
                                                                                                                    (
                                                                                                                        option
                                                                                                                    ) =>
                                                                                                                        option.value ===
                                                                                                                        data
                                                                                                                            .discount_type
                                                                                                                            .value
                                                                                                                )}
                                                                                                                defaultValue={percent_value.find(
                                                                                                                    (
                                                                                                                        option
                                                                                                                    ) =>
                                                                                                                        option.value ===
                                                                                                                        data
                                                                                                                            .discount_type
                                                                                                                            .value
                                                                                                                )}
                                                                                                                label="Single select"
                                                                                                                options={
                                                                                                                    percent_value
                                                                                                                }
                                                                                                                isSearchable={
                                                                                                                    false
                                                                                                                }
                                                                                                            />
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td></td>
                                                                                                    <td></td>
                                                                                                    <td></td>
                                                                                                    <td></td>

                                                                                                    <td className="min-wdth-100 max-wdth-100 text-right text-danger">
                                                                                                        -{' '}
                                                                                                        {numberFormat(
                                                                                                            Number(
                                                                                                                data.discount_amount
                                                                                                            ).toFixed(
                                                                                                                2
                                                                                                            )
                                                                                                        )}
                                                                                                    </td>

                                                                                                    <td
                                                                                                        align="center"
                                                                                                        className="mw-2m"
                                                                                                    >
                                                                                                        <button
                                                                                                            className="btn btn-sm btn-danger"
                                                                                                            onClick={(
                                                                                                                e
                                                                                                            ) =>
                                                                                                                handleDiscountItem(
                                                                                                                    key,
                                                                                                                    true
                                                                                                                )
                                                                                                            }
                                                                                                            type="button"
                                                                                                        >
                                                                                                            <i className="ft ft-trash"></i>
                                                                                                        </button>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ) : null}
                                                                                        </Fragment>
                                                                                    );
                                                                                }
                                                                            )
                                                                        ) : //si_data.manual_input &&
                                                                        si_data.status ==
                                                                          'draft' ? (
                                                                            si_data.items.map(
                                                                                (
                                                                                    data,
                                                                                    key
                                                                                ) => {
                                                                                    return (
                                                                                        <Fragment
                                                                                            key={
                                                                                                key
                                                                                            }
                                                                                        >
                                                                                            {' '}
                                                                                            {/* This is the key part */}
                                                                                            <tr
                                                                                                className={stockClass(
                                                                                                    data.status_code
                                                                                                )}
                                                                                            >
                                                                                                {/* <tr
                                      key={key}
                                      className={stockClass(data.status_code)}
                                    > */}
                                                                                                <td className="table-data__sku ">
                                                                                                    {
                                                                                                        data.sku
                                                                                                    }
                                                                                                </td>
                                                                                                <td className="min-wdth-300 max-wdth-300">
                                                                                                    {data.new ? (
                                                                                                        <div>
                                                                                                            <div className="mw-90">
                                                                                                                <Link
                                                                                                                    to={`/inventory/view/${data._id}`}
                                                                                                                    target="_blank"
                                                                                                                    onClick={(
                                                                                                                        e
                                                                                                                    ) =>
                                                                                                                        e.stopPropagation()
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        data.product_name
                                                                                                                    }
                                                                                                                </Link>
                                                                                                            </div>
                                                                                                            <div className="f-12 text-dark">
                                                                                                                {
                                                                                                                    data.barcode
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className="f-12 text-dark">
                                                                                                                {
                                                                                                                    data.description
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className="f-12 text-dark">
                                                                                                                {
                                                                                                                    data.brand
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    ) : data.selected ? (
                                                                                                        <div
                                                                                                            onClick={() =>
                                                                                                                ShowComboBox(
                                                                                                                    key
                                                                                                                )
                                                                                                            }
                                                                                                            className={
                                                                                                                key <
                                                                                                                si_data.lastAddedDropdownIndex
                                                                                                                    ? 'unclickable'
                                                                                                                    : 'c-pointer'
                                                                                                            }
                                                                                                        >
                                                                                                            <div className="f-right">
                                                                                                                {/* <i className='ft ft-chevron-down'></i> */}
                                                                                                            </div>
                                                                                                            <div className="mw-90">
                                                                                                                <Link
                                                                                                                    to={`/inventory/view/${data._id}`}
                                                                                                                    target="_blank"
                                                                                                                    onClick={(
                                                                                                                        e
                                                                                                                    ) =>
                                                                                                                        e.stopPropagation()
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        data.product_name
                                                                                                                    }
                                                                                                                </Link>
                                                                                                            </div>
                                                                                                            <div className="f-12 text-dark">
                                                                                                                {
                                                                                                                    data.barcode
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className="f-12 text-dark">
                                                                                                                {
                                                                                                                    data.description
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className="f-12 text-dark">
                                                                                                                {
                                                                                                                    data.brand
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    ) : (
                                                                                                        <AsyncSelect
                                                                                                            value={
                                                                                                                data.product_value
                                                                                                            }
                                                                                                            isLoading={
                                                                                                                loading
                                                                                                            }
                                                                                                            components={{
                                                                                                                Option,
                                                                                                            }}
                                                                                                            classNamePrefix="lp-copy-sel"
                                                                                                            loadingMessage={() =>
                                                                                                                'searching...'
                                                                                                            }
                                                                                                            noOptionsMessage={({
                                                                                                                inputValue,
                                                                                                            }) =>
                                                                                                                inputValue
                                                                                                                    ? 'No Products'
                                                                                                                    : 'Search product'
                                                                                                            }
                                                                                                            defaultOptions={
                                                                                                                data.items
                                                                                                            }
                                                                                                            loadOptions={debounce(
                                                                                                                (
                                                                                                                    value
                                                                                                                ) =>
                                                                                                                    searchProducts(
                                                                                                                        value,
                                                                                                                        key
                                                                                                                    ),
                                                                                                                1000
                                                                                                            )}
                                                                                                            isSearchable={
                                                                                                                true
                                                                                                            }
                                                                                                            cacheOptions
                                                                                                            onChange={(
                                                                                                                val
                                                                                                            ) =>
                                                                                                                handleSelectItem(
                                                                                                                    val,
                                                                                                                    key
                                                                                                                )
                                                                                                            }
                                                                                                            placeholder="Select Products..."
                                                                                                            required
                                                                                                        />
                                                                                                    )}
                                                                                                </td>
                                                                                                <td
                                                                                                    className="mw-6m text-center"
                                                                                                    align="center"
                                                                                                >
                                                                                                    <input
                                                                                                        value={
                                                                                                            data.qty
                                                                                                        }
                                                                                                        onChange={(
                                                                                                            e
                                                                                                        ) =>
                                                                                                            changeQty(
                                                                                                                e,
                                                                                                                key
                                                                                                            )
                                                                                                        }
                                                                                                        className="form-control text-center"
                                                                                                        type="number"
                                                                                                        name="qty"
                                                                                                        min={
                                                                                                            0
                                                                                                        }
                                                                                                    />
                                                                                                </td>
                                                                                                <td className="min-wdth-100 max-wdth-100 text-center">
                                                                                                    {
                                                                                                        data.product_unit_name
                                                                                                    }
                                                                                                </td>
                                                                                                <td className="mw-8m text-right">
                                                                                                    {numberFormat(
                                                                                                        data.srp
                                                                                                    )}
                                                                                                </td>
                                                                                                <td className="mw-8m text-right">
                                                                                                    {data.unit_price
                                                                                                        ? numberFormat(
                                                                                                              data.unit_price
                                                                                                          )
                                                                                                        : numberFormat(
                                                                                                              data.srp
                                                                                                          )}
                                                                                                </td>
                                                                                                <td className="min-wdth-150 max-wdth-150 text-right">
                                                                                                    {numberFormat(
                                                                                                        data.amount
                                                                                                    )}
                                                                                                </td>
                                                                                                <td className="text-center mw-2m">
                                                                                                    {!data.non_inventory ? (
                                                                                                        <>
                                                                                                            <span className="m-1">
                                                                                                                <button
                                                                                                                    className="btn btn-sm btn-primary"
                                                                                                                    onClick={(
                                                                                                                        e
                                                                                                                    ) =>
                                                                                                                        handleDiscountItem(
                                                                                                                            key,
                                                                                                                            false
                                                                                                                        )
                                                                                                                    }
                                                                                                                    type="button"
                                                                                                                >
                                                                                                                    <i className="ft ft-tag"></i>
                                                                                                                </button>
                                                                                                            </span>

                                                                                                            <span>
                                                                                                                <button
                                                                                                                    className="btn btn-danger btn-sm"
                                                                                                                    onClick={(
                                                                                                                        e
                                                                                                                    ) =>
                                                                                                                        remItem(
                                                                                                                            key
                                                                                                                        )
                                                                                                                    }
                                                                                                                >
                                                                                                                    <i className="ft-trash-2"></i>
                                                                                                                </button>
                                                                                                            </span>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            <span>
                                                                                                                <button
                                                                                                                    className="btn btn-danger btn-sm"
                                                                                                                    onClick={(
                                                                                                                        e
                                                                                                                    ) =>
                                                                                                                        remItem(
                                                                                                                            key
                                                                                                                        )
                                                                                                                    }
                                                                                                                >
                                                                                                                    <i className="ft-trash-2"></i>
                                                                                                                </button>
                                                                                                            </span>
                                                                                                        </>
                                                                                                    )}
                                                                                                </td>
                                                                                            </tr>
                                                                                            {(data.discount_type &&
                                                                                                data
                                                                                                    .discount_type
                                                                                                    .value !==
                                                                                                    'none') ||
                                                                                            data.showDiscountRow ? (
                                                                                                <tr>
                                                                                                    <td
                                                                                                        colSpan={
                                                                                                            1
                                                                                                        }
                                                                                                    ></td>
                                                                                                    <td
                                                                                                        className="d-flex ali justify-content-center align-items-center"
                                                                                                        style={{
                                                                                                            gap: '5%',
                                                                                                        }}
                                                                                                    >
                                                                                                        <span>
                                                                                                            Discount:
                                                                                                        </span>

                                                                                                        <span className="w-20 table-data__discount">
                                                                                                            {(() => {
                                                                                                                switch (
                                                                                                                    data.discount_type
                                                                                                                        ? data
                                                                                                                              .discount_type
                                                                                                                              .value
                                                                                                                        : null
                                                                                                                ) {
                                                                                                                    case 'percent':
                                                                                                                        return (
                                                                                                                            <div class="input-group">
                                                                                                                                <input
                                                                                                                                    onChange={(
                                                                                                                                        e
                                                                                                                                    ) =>
                                                                                                                                        handleInputDiscountProduct(
                                                                                                                                            e,
                                                                                                                                            key
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                    value={
                                                                                                                                        data.discount
                                                                                                                                    }
                                                                                                                                    name="discount"
                                                                                                                                    className="form-control text-right f-regular"
                                                                                                                                    type="number"
                                                                                                                                />
                                                                                                                                <div class="input-group-append">
                                                                                                                                    <span className="input-group-text py-0 f-regular discount-1">
                                                                                                                                        %
                                                                                                                                    </span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        );
                                                                                                                    case 'amount':
                                                                                                                        return (
                                                                                                                            <input
                                                                                                                                onChange={(
                                                                                                                                    e
                                                                                                                                ) =>
                                                                                                                                    handleInputDiscountProduct(
                                                                                                                                        e,
                                                                                                                                        key
                                                                                                                                    )
                                                                                                                                }
                                                                                                                                value={
                                                                                                                                    data.discount
                                                                                                                                }
                                                                                                                                name="discount"
                                                                                                                                className="form-control text-right f-regular"
                                                                                                                                type="number"
                                                                                                                            />
                                                                                                                        );
                                                                                                                    default: // 0 disabled
                                                                                                                        return (
                                                                                                                            <input
                                                                                                                                value={
                                                                                                                                    data.discount
                                                                                                                                }
                                                                                                                                disabled
                                                                                                                                name="discount"
                                                                                                                                className="form-control text-right f-regular"
                                                                                                                                type="number"
                                                                                                                            />
                                                                                                                        );
                                                                                                                }
                                                                                                            })()}
                                                                                                        </span>
                                                                                                        <span className="table-data__discount-dropdown">
                                                                                                            <Select
                                                                                                                name="discount_type"
                                                                                                                onChange={(
                                                                                                                    selectOption
                                                                                                                ) =>
                                                                                                                    handleSelectDiscountTypeProduct(
                                                                                                                        selectOption,
                                                                                                                        key
                                                                                                                    )
                                                                                                                }
                                                                                                                menuPortalTarget={
                                                                                                                    document.body
                                                                                                                }
                                                                                                                value={percent_value.find(
                                                                                                                    (
                                                                                                                        option
                                                                                                                    ) =>
                                                                                                                        option.value ===
                                                                                                                        data
                                                                                                                            .discount_type
                                                                                                                            .value
                                                                                                                )}
                                                                                                                defaultValue={percent_value.find(
                                                                                                                    (
                                                                                                                        option
                                                                                                                    ) =>
                                                                                                                        option.value ===
                                                                                                                        data
                                                                                                                            .discount_type
                                                                                                                            .value
                                                                                                                )}
                                                                                                                label="Single select"
                                                                                                                options={
                                                                                                                    percent_value
                                                                                                                }
                                                                                                                isSearchable={
                                                                                                                    false
                                                                                                                }
                                                                                                            />
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td></td>
                                                                                                    <td></td>
                                                                                                    <td></td>
                                                                                                    <td></td>

                                                                                                    <td className="min-wdth-100 max-wdth-100 text-right text-danger">
                                                                                                        -{' '}
                                                                                                        {numberFormat(
                                                                                                            Number(
                                                                                                                data.discount_amount
                                                                                                            ).toFixed(
                                                                                                                2
                                                                                                            )
                                                                                                        )}
                                                                                                    </td>

                                                                                                    <td
                                                                                                        align="center"
                                                                                                        className="mw-2m"
                                                                                                    >
                                                                                                        <button
                                                                                                            className="btn btn-sm btn-danger"
                                                                                                            onClick={(
                                                                                                                e
                                                                                                            ) =>
                                                                                                                handleDiscountItem(
                                                                                                                    key,
                                                                                                                    true
                                                                                                                )
                                                                                                            }
                                                                                                            type="button"
                                                                                                        >
                                                                                                            <i className="ft ft-trash"></i>
                                                                                                        </button>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ) : null}
                                                                                        </Fragment>
                                                                                    );
                                                                                }
                                                                            )
                                                                        ) : (
                                                                            si_data.items.map(
                                                                                (
                                                                                    data,
                                                                                    key
                                                                                ) => {
                                                                                    return (
                                                                                        // <tr
                                                                                        //   key={key}
                                                                                        //   className={stockClass(data.status_code)}
                                                                                        // >
                                                                                        <Fragment
                                                                                            key={
                                                                                                key
                                                                                            }
                                                                                        >
                                                                                            {' '}
                                                                                            {/* This is the key part */}
                                                                                            <tr
                                                                                                className={stockClass(
                                                                                                    data.status_code
                                                                                                )}
                                                                                            >
                                                                                                <td>
                                                                                                    {
                                                                                                        data.sku
                                                                                                    }
                                                                                                </td>
                                                                                                <td>
                                                                                                    {
                                                                                                        data.product_name
                                                                                                    }{' '}
                                                                                                    <br />{' '}
                                                                                                    <div className="text-secondary f-10">
                                                                                                        {
                                                                                                            data.barcode
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className="text-secondary f-10">
                                                                                                        {
                                                                                                            data.description
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className="text-secondary f-10">
                                                                                                        {
                                                                                                            data.brand
                                                                                                        }
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td
                                                                                                    className="text-center"
                                                                                                    style={{
                                                                                                        width: '8%',
                                                                                                    }}
                                                                                                >
                                                                                                    {numberFormat(
                                                                                                        data.qty,
                                                                                                        0
                                                                                                    )}
                                                                                                </td>
                                                                                                <td
                                                                                                    className="text-center"
                                                                                                    style={{
                                                                                                        width: '8%',
                                                                                                    }}
                                                                                                >
                                                                                                    {
                                                                                                        data.product_unit_name
                                                                                                    }
                                                                                                </td>
                                                                                                <td
                                                                                                    className="text-right"
                                                                                                    style={{
                                                                                                        width: '12%',
                                                                                                    }}
                                                                                                >
                                                                                                    {numberFormat(
                                                                                                        data.srp
                                                                                                    )}
                                                                                                </td>
                                                                                                <td
                                                                                                    className="text-right"
                                                                                                    style={{
                                                                                                        width: '12%',
                                                                                                    }}
                                                                                                >
                                                                                                    {data.unit_price
                                                                                                        ? numberFormat(
                                                                                                              data.unit_price
                                                                                                          )
                                                                                                        : numberFormat(
                                                                                                              data.srp
                                                                                                          )}
                                                                                                </td>
                                                                                                <td
                                                                                                    className="text-right"
                                                                                                    style={{
                                                                                                        width: '12%',
                                                                                                    }}
                                                                                                >
                                                                                                    {numberFormat(
                                                                                                        data.amount
                                                                                                    )}
                                                                                                </td>
                                                                                            </tr>
                                                                                            {data.discount_type &&
                                                                                                data
                                                                                                    .discount_type
                                                                                                    .value !==
                                                                                                    'none' && (
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            colSpan={
                                                                                                                1
                                                                                                            }
                                                                                                        ></td>
                                                                                                        <td className="d-flex justify-content-left ">
                                                                                                            <div className="w-50">
                                                                                                                <span>
                                                                                                                    Discount:{' '}
                                                                                                                </span>
                                                                                                                <span className="product-discount">
                                                                                                                    {
                                                                                                                        data.discount
                                                                                                                    }
                                                                                                                    {data
                                                                                                                        .discount_type
                                                                                                                        .label ===
                                                                                                                    'Percent'
                                                                                                                        ? ' %'
                                                                                                                        : ''}
                                                                                                                </span>
                                                                                                            </div>

                                                                                                            <div className="w-50">
                                                                                                                <span>
                                                                                                                    Discount
                                                                                                                    type:{' '}
                                                                                                                </span>
                                                                                                                <span className="product-discount">
                                                                                                                    {
                                                                                                                        data
                                                                                                                            .discount_type
                                                                                                                            .label
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td></td>
                                                                                                        <td></td>
                                                                                                        <td></td>
                                                                                                        <td></td>
                                                                                                        <td className="min-wdth-100 max-wdth-100 text-right text-danger">
                                                                                                            -{' '}
                                                                                                            {numberFormat(
                                                                                                                Number(
                                                                                                                    data.discount_amount
                                                                                                                ).toFixed(
                                                                                                                    2
                                                                                                                )
                                                                                                            )}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                )}
                                                                                        </Fragment>
                                                                                    );
                                                                                }
                                                                            )
                                                                        )}
                                                                        {
                                                                            //si_data.manual_input &&
                                                                            si_data.status ==
                                                                            'draft' ? (
                                                                                <tr>
                                                                                    <td
                                                                                        colSpan="8"
                                                                                        className="text-left"
                                                                                    >
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-primary"
                                                                                            onClick={(
                                                                                                e
                                                                                            ) =>
                                                                                                addItem(
                                                                                                    e
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <i className="la la-plus"></i>
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            ) : (
                                                                                <tr>
                                                                                    <td colSpan="8">
                                                                                        &nbsp;
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        }

                                                                        {si_data.status ==
                                                                        'draft' ? (
                                                                            <tr>
                                                                                <td colSpan="5"></td>
                                                                                <td className="text-right">
                                                                                    Sub
                                                                                    Total
                                                                                </td>
                                                                                <td className="text-right">
                                                                                    {numberFormat(
                                                                                        si_data.sub_total
                                                                                    )}
                                                                                </td>
                                                                                <td></td>
                                                                            </tr>
                                                                        ) : (
                                                                            <tr>
                                                                                <td colSpan="5"></td>
                                                                                <td className="text-right">
                                                                                    Sub
                                                                                    Total
                                                                                </td>
                                                                                <td className="text-right">
                                                                                    {numberFormat(
                                                                                        si_data.sub_total
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        )}

                                                                        {
                                                                            // si_data.manual_input &&
                                                                            si_data.status ==
                                                                            'draft' ? (
                                                                                <tr>
                                                                                    <td
                                                                                        colSpan={
                                                                                            5
                                                                                        }
                                                                                    ></td>
                                                                                    <td>
                                                                                        <div align="right">
                                                                                            Discount
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        {(() => {
                                                                                            switch (
                                                                                                si_data
                                                                                                    .discount_type
                                                                                                    .value
                                                                                            ) {
                                                                                                case 'percent':
                                                                                                    return (
                                                                                                        <div class="input-group">
                                                                                                            <input
                                                                                                                onChange={
                                                                                                                    handleInputChangeDiscount
                                                                                                                }
                                                                                                                value={
                                                                                                                    si_data.discount
                                                                                                                }
                                                                                                                name="discount"
                                                                                                                className="form-control text-right f-regular"
                                                                                                                type="number"
                                                                                                            />
                                                                                                            <div class="input-group-append">
                                                                                                                <span className="input-group-text py-0 f-regular discount-1">
                                                                                                                    %
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    );
                                                                                                case 'amount':
                                                                                                    return (
                                                                                                        <input
                                                                                                            onChange={
                                                                                                                handleInputChangeDiscount
                                                                                                            }
                                                                                                            value={
                                                                                                                si_data.discount
                                                                                                            }
                                                                                                            name="discount"
                                                                                                            className="form-control text-right f-regular"
                                                                                                            type="number"
                                                                                                        />
                                                                                                    );
                                                                                                default: // 0 disabled
                                                                                                    return (
                                                                                                        <input
                                                                                                            value={
                                                                                                                si_data.discount
                                                                                                            }
                                                                                                            disabled
                                                                                                            name="discount"
                                                                                                            className="form-control text-right f-regular"
                                                                                                            type="number"
                                                                                                        />
                                                                                                    );
                                                                                            }
                                                                                        })()}
                                                                                    </td>
                                                                                    <td
                                                                                        width="10%"
                                                                                        className="text-center padding-td-5"
                                                                                    >
                                                                                        <Select
                                                                                            name="discount_type"
                                                                                            onChange={
                                                                                                handleSelectDiscountType
                                                                                            }
                                                                                            menuPortalTarget={
                                                                                                document.body
                                                                                            }
                                                                                            value={percent_value.find(
                                                                                                (
                                                                                                    option
                                                                                                ) =>
                                                                                                    option.value ===
                                                                                                    si_data
                                                                                                        .discount_type
                                                                                                        .value
                                                                                            )}
                                                                                            defaultValue={percent_value.find(
                                                                                                (
                                                                                                    option
                                                                                                ) =>
                                                                                                    option.value ===
                                                                                                    si_data
                                                                                                        .discount_type
                                                                                                        .value
                                                                                            )}
                                                                                            label="Single select"
                                                                                            options={
                                                                                                percent_value
                                                                                            }
                                                                                            isSearchable={
                                                                                                false
                                                                                            }
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            ) : (
                                                                                <tr>
                                                                                    <td colSpan="5"></td>
                                                                                    <td className="text-right">
                                                                                        Discount
                                                                                    </td>
                                                                                    <td align="right">
                                                                                        {si_data
                                                                                            .discount_type
                                                                                            .value ===
                                                                                        'percent'
                                                                                            ? `${si_data.discount}  %`
                                                                                            : numberFormat(
                                                                                                  si_data.discount
                                                                                              )}
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        }

                                                                        {si_data.status ==
                                                                        'draft' ? (
                                                                            <tr>
                                                                                <td
                                                                                    colSpan={
                                                                                        5
                                                                                    }
                                                                                ></td>
                                                                                <td>
                                                                                    <div align="right">
                                                                                        VAT
                                                                                        (
                                                                                        {
                                                                                            si_data.tax
                                                                                        }{' '}
                                                                                        %)
                                                                                    </div>
                                                                                </td>
                                                                                <td align="right">
                                                                                    {numberFormat(
                                                                                        si_data.vat_amount.toFixed(
                                                                                            2
                                                                                        )
                                                                                    )}
                                                                                </td>
                                                                                <td className="padding-td-5 min-width-150">
                                                                                    <Select
                                                                                        name="tax_type"
                                                                                        onChange={
                                                                                            handleTaxType
                                                                                        }
                                                                                        value={
                                                                                            si_data.tax_type
                                                                                        }
                                                                                        menuPortalTarget={
                                                                                            document.body
                                                                                        }
                                                                                        options={
                                                                                            tax_types
                                                                                        }
                                                                                        isSearchable={
                                                                                            false
                                                                                        }
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        ) : (
                                                                            <tr>
                                                                                <td
                                                                                    colSpan={
                                                                                        5
                                                                                    }
                                                                                ></td>
                                                                                <td>
                                                                                    <div align="right">
                                                                                        <span className="f-16">
                                                                                            {si_data.tax_type
                                                                                                ? si_data
                                                                                                      .tax_type
                                                                                                      .value ===
                                                                                                  'none'
                                                                                                    ? null
                                                                                                    : si_data
                                                                                                          .tax_type
                                                                                                          .value +
                                                                                                      ':'
                                                                                                : ''}
                                                                                        </span>{' '}
                                                                                        VAT
                                                                                        (
                                                                                        {si_data.tax
                                                                                            ? si_data.tax
                                                                                            : 0}{' '}
                                                                                        %)
                                                                                    </div>
                                                                                </td>
                                                                                <td align="right">
                                                                                    {numberFormat(
                                                                                        si_data.vat_amount.toFixed(
                                                                                            2
                                                                                        )
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                            //   <tr>
                                                                            //   <td colSpan={4}></td>
                                                                            //   <td>
                                                                            //     <div align='right'>
                                                                            //       VAT ({si_data.tax} %)
                                                                            //     </div>
                                                                            //   </td>
                                                                            //   <td align='right'>
                                                                            //     {numberFormat(
                                                                            //       si_data.vat_amount.toFixed(2)
                                                                            //     )}
                                                                            //   </td>
                                                                            //   <td className='padding-td-5 min-width-150'>
                                                                            //     <Select
                                                                            //       name='tax_type'
                                                                            //       onChange={handleTaxType}
                                                                            //       value={si_data.tax_type}
                                                                            //       menuPortalTarget={document.body}
                                                                            //       options={tax_types}
                                                                            //       isSearchable={false}
                                                                            //     />
                                                                            //   </td>
                                                                            // </tr>
                                                                        )}

                                                                        {
                                                                            // si_data.manual_input &&
                                                                            si_data.status ==
                                                                            'draft' ? (
                                                                                <tr>
                                                                                    <td colSpan="5"></td>
                                                                                    <td className="text-right">
                                                                                        Delivery
                                                                                        Fee
                                                                                    </td>
                                                                                    <td className="text-right">
                                                                                        <input
                                                                                            type="number"
                                                                                            className="form-control f-regular text-right"
                                                                                            value={
                                                                                                si_data.delivery_fee
                                                                                            }
                                                                                            onChange={
                                                                                                deliveryFee
                                                                                            }
                                                                                        />
                                                                                    </td>
                                                                                    <td></td>
                                                                                </tr>
                                                                            ) : (
                                                                                <tr>
                                                                                    <td colSpan="5"></td>
                                                                                    <td className="text-right">
                                                                                        Delivery
                                                                                        Fee
                                                                                    </td>
                                                                                    <td className="text-right">
                                                                                        {numberFormat(
                                                                                            si_data.delivery_fee
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        }

                                                                        {si_data.status ==
                                                                        'draft' ? (
                                                                            <tr>
                                                                                <td></td>
                                                                                <td className="text-right"></td>
                                                                                <td className="text-right"></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td className="text-right">
                                                                                    <strong>
                                                                                        Total
                                                                                    </strong>
                                                                                </td>
                                                                                <td className="text-right">
                                                                                    {numberFormat(
                                                                                        si_data.total.toFixed(
                                                                                            2
                                                                                        )
                                                                                    )}
                                                                                </td>
                                                                                <td></td>
                                                                            </tr>
                                                                        ) : (
                                                                            <tr>
                                                                                <td></td>
                                                                                <td className="text-right"></td>
                                                                                <td className="text-right"></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td className="text-right">
                                                                                    <strong>
                                                                                        Total
                                                                                    </strong>
                                                                                </td>
                                                                                <td className="text-right">
                                                                                    {numberFormat(
                                                                                        si_data.total.toFixed(
                                                                                            2
                                                                                        )
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            {
                                                                //!si_data.manual_input &&
                                                                si_data.status ==
                                                                'draft' ? (
                                                                    <div className="form-group">
                                                                        <label>
                                                                            Remarks
                                                                        </label>
                                                                        <textarea
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                remarksInput(
                                                                                    e
                                                                                )
                                                                            }
                                                                            className="form-control f-regular"
                                                                            value={
                                                                                si_data.remarks
                                                                            }
                                                                        ></textarea>
                                                                    </div>
                                                                ) : (
                                                                    <div className="form-group">
                                                                        <label>
                                                                            Remarks
                                                                            from
                                                                            Sales
                                                                            Invoice
                                                                        </label>
                                                                        <textarea
                                                                            className="form-control f-regular"
                                                                            value={
                                                                                si_data.remarks
                                                                            }
                                                                            disabled
                                                                        ></textarea>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>
                                                                    Payment
                                                                    Mode:
                                                                </label>
                                                                <div className="input-group input-group-full">
                                                                    {
                                                                        //si_data.manual_input &&
                                                                        si_data.status ==
                                                                        'draft' ? (
                                                                            <Select
                                                                                options={
                                                                                    payment_mode_options
                                                                                }
                                                                                onChange={
                                                                                    handleSelectPaymentType
                                                                                }
                                                                                value={
                                                                                    si_data.payment_mode
                                                                                }
                                                                                isSearchable={
                                                                                    isSearchable
                                                                                }
                                                                            />
                                                                        ) : (
                                                                            <div className="form-control f-regular">
                                                                                {
                                                                                    si_data
                                                                                        .payment_mode
                                                                                        .label
                                                                                }
                                                                            </div>
                                                                        )
                                                                    }

                                                                    <div className="input-group-prepend">
                                                                        <span
                                                                            href="#"
                                                                            className="tool-tip text-primary"
                                                                            data-tip="this is a tool tip"
                                                                        >
                                                                            <i className="la la-question-circle"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>
                                                                    Payment
                                                                    Terms:{' '}
                                                                </label>
                                                                {
                                                                    //si_data.manual_input &&
                                                                    si_data.status ==
                                                                    'draft' ? (
                                                                        <section>
                                                                            <div className="input-group input-group-full">
                                                                                {terms_conditions.length >
                                                                                    0 && (
                                                                                    <Select
                                                                                        options={
                                                                                            terms_conditions
                                                                                        }
                                                                                        onChange={
                                                                                            handleSelectTermsConditions
                                                                                        }
                                                                                        value={
                                                                                            si_data.payment_terms
                                                                                        }
                                                                                        isSearchable={
                                                                                            isSearchable
                                                                                        }
                                                                                    />
                                                                                )}
                                                                                <div className="input-group-prepend">
                                                                                    <span
                                                                                        href="#"
                                                                                        className="tool-tip text-primary"
                                                                                        data-tip="this is a tool tip"
                                                                                    >
                                                                                        <i className="la la-question-circle"></i>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            {terms_conditions.length ==
                                                                                0 && (
                                                                                <div>
                                                                                    {' '}
                                                                                    No
                                                                                    payment_terms
                                                                                    please
                                                                                    add{' '}
                                                                                    <a href="/setting/term-condition">
                                                                                        here
                                                                                    </a>
                                                                                </div>
                                                                            )}
                                                                        </section>
                                                                    ) : (
                                                                        <section>
                                                                            <div className="input-group input-group-full">
                                                                                <div className="form-control f-regular">
                                                                                    {
                                                                                        si_data
                                                                                            .payment_terms
                                                                                            .label
                                                                                    }
                                                                                </div>
                                                                                <div className="input-group-prepend">
                                                                                    <span
                                                                                        href="#"
                                                                                        className="tool-tip text-primary"
                                                                                        data-tip="this is a tool tip"
                                                                                    >
                                                                                        <i className="la la-question-circle"></i>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </section>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>
                                                                    Receipt Type{' '}
                                                                    <span className="text-danger">
                                                                        *
                                                                    </span>
                                                                </label>
                                                                <div className="input-group input-group-full">
                                                                    {
                                                                        //si_data.manual_input &&
                                                                        si_data.status ==
                                                                        'draft' ? (
                                                                            <Select
                                                                                options={
                                                                                    receipt_type_options
                                                                                }
                                                                                onChange={
                                                                                    handleSelectReceiptType
                                                                                }
                                                                                value={
                                                                                    si_data.receipt_type
                                                                                }
                                                                                isSearchable={
                                                                                    isSearchable
                                                                                }
                                                                                required
                                                                            />
                                                                        ) : (
                                                                            <div className="form-control f-regular">
                                                                                {
                                                                                    si_data
                                                                                        .receipt_type
                                                                                        .label
                                                                                }
                                                                            </div>
                                                                        )
                                                                    }

                                                                    <div className="input-group-prepend">
                                                                        <span
                                                                            href="#"
                                                                            className="tool-tip text-primary"
                                                                            data-tip="this is a tool tip"
                                                                        >
                                                                            <i className="la la-question-circle"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="card-footer">
                                        <div className="row justify-content-end mt-3">
                                            <div className="mr-2">
                                                <div className="form-group">
                                                    {si_data.dr_status ==
                                                    'draft' ? (
                                                        <>
                                                            {/* <Show when="inv.delivery.receipts.draft">
                                                            <button className="btn btn-outline-light text-dark " type="submit" onClick={() => submitClick('draft')}>Draft</button>
                                                        </Show>
                                                        <button className="btn btn-primary text-white margin-l-5" type="submit" onClick={() => submitClick('saved')} disabled={enable_submit}>Save</button> */}
                                                            <Show when="inv.receiving.itemReceipts.draft">
                                                                <div className="btn-group btn-dpd">
                                                                    <button
                                                                        className="btn btn-default btn-outline-light text-dark btn-main"
                                                                        onClick={() =>
                                                                            submitClick(
                                                                                'draft'
                                                                            )
                                                                        }
                                                                        type="button"
                                                                        disabled={
                                                                            loading
                                                                        }
                                                                    >
                                                                        Save as
                                                                        Draft
                                                                    </button>
                                                                    <button
                                                                        data-toggle="dropdown"
                                                                        className="btn btn-default btn-outline-light text-dark dropdown-toggle btn-carret"
                                                                        type="button"
                                                                        disabled={
                                                                            loading
                                                                        }
                                                                    >
                                                                        <span className="caret"></span>
                                                                    </button>
                                                                    <ul className="dropdown-menu">
                                                                        {/* <li><a className="dpd-menu" onClick={() => submitClick('draft')}>Daft</a></li> */}
                                                                        <li>
                                                                            <a
                                                                                className="dpd-menu"
                                                                                onClick={() =>
                                                                                    submitClick(
                                                                                        'draft-email'
                                                                                    )
                                                                                }
                                                                            >
                                                                                Draft
                                                                                &
                                                                                Email
                                                                            </a>
                                                                        </li>
                                                                        <li>
                                                                            <a
                                                                                className="dpd-menu"
                                                                                onClick={() =>
                                                                                    submitClick(
                                                                                        'draft-print'
                                                                                    )
                                                                                }
                                                                            >
                                                                                Draft
                                                                                &
                                                                                Print
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </Show>{' '}
                                                            <div className="btn-group btn-dpd">
                                                                <button
                                                                    className="btn btn-primary  btn-main"
                                                                    onClick={() =>
                                                                        submitClick(
                                                                            'saved'
                                                                        )
                                                                    }
                                                                    // disabled={enable_submit}
                                                                    type="button"
                                                                    disabled={
                                                                        loading
                                                                    }
                                                                >
                                                                    Save
                                                                    Delivery
                                                                </button>
                                                                <button
                                                                    data-toggle="dropdown"
                                                                    className="btn btn-primary dropdown-toggle btn-carret"
                                                                    type="button"
                                                                    // disabled={enable_submit}
                                                                    disabled={
                                                                        loading
                                                                    }
                                                                >
                                                                    <span className="caret"></span>
                                                                </button>
                                                                <ul className="dropdown-menu">
                                                                    {/* <li><a className="dpd-menu" href="#" onClick={() => submitClick('saved')} disabled={enable_submit}>Save</a></li> */}
                                                                    <li>
                                                                        <a
                                                                            className="dpd-menu"
                                                                            onClick={() =>
                                                                                submitClick(
                                                                                    'saved-email'
                                                                                )
                                                                            }
                                                                        >
                                                                            Save
                                                                            &
                                                                            Email
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="dpd-menu"
                                                                            onClick={() =>
                                                                                submitClick(
                                                                                    'saved-print'
                                                                                )
                                                                            }
                                                                        >
                                                                            Save
                                                                            &
                                                                            Print
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </>
                                                    ) : si_data.dr_status !=
                                                          'draft' &&
                                                      si_data.dr_status !=
                                                          'cancelled' ? (
                                                        <Fragment>
                                                            <Show when="inv.delivery.receipts.cancel">
                                                                <button
                                                                    className="btn btn-danger"
                                                                    onClick={
                                                                        cancelDr
                                                                    }
                                                                    type="button"
                                                                    disabled={
                                                                        loading
                                                                    }
                                                                >
                                                                    {btn_form.cancel ? (
                                                                        <i className="icon-loading-custom"></i>
                                                                    ) : (
                                                                        <span>
                                                                            Cancel
                                                                        </span>
                                                                    )}
                                                                </button>
                                                            </Show>
                                                        </Fragment>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </BeforeUnloadComponent>
    );
}

const mapStateToProps = (state) => ({
    delivery: state.delivery,
    block_route: state.helper.block_route,
});

export default connect(mapStateToProps, {
    getQuotations,
    searchQuotation,
    getQuotationItems,
    update_delivery,
    handleSelectPaymentType,
    getTermsConditions,
    handleSelectTermsConditions,
    handleSelectReceiptType,
    checkBoxManual,
    handleSelectDRTemplate,
    getDeliveryTemplates,
    getCustomers,
    getProducts,
    handleSelectCustomer,
    handleSelectItem,
    changeQty,
    addItem,
    remItem,
    handleDiscountItem,
    handleSelectDiscountTypeProduct,
    handleInputDiscountProduct,
    vat_change,
    deliveryFee,
    discountType,
    discountInput,
    refNo,
    remarksInput,
    viewDr,
    blockRoute,
    searchProducts,
    handleInputChangeDiscount,
    handleSelectDiscountType,
    handleTaxType,
    computationHandler,
    subscriberSetting,
    ShowComboBox,
    cancelDr,
    handleCreateSO,
    dynamicInputChange,
    createProduct,
    skuSkipValidation,
    generateSku,
})(DeliveryEdit);
