import React, {
    useState,
    useEffect,
    Fragment,
    useRef,
    useCallback,
} from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { MiniSpinner } from '../../layouts/Spinner';
import {
    viewReceiving,
    loadUnReceived,
    goPage,
    sendEmail,
    reSendEmail,
    getPdf,
} from '../../../redux/actions/receiving/receiving.actions';
import ReceivingPrintContent from './ReceivingPrintContent';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { globalDropDown } from '../../../redux/actions/helper/helper.actions';
import DropDownAttachFileGlobal from '../../layouts/DropDownAttachFile.global.js';
import ReceivingCustomTemplate from '../custom_template/ReceivingCustomTemplate';

function ReceivingPrintModal({
    receiving: {
        loading,
        po_items,
        receivingData,
        deliveryDate,
        single_receiving,
        unreceive_items,
        sending_email,
        download_pdf,
    },
    show,
    onHide,
    goPage,
    globalDropDown,
    new_uploads,
    files_to_remove,
    subs_setting,
    sendEmail,
    reSendEmail,
    getPdf,
}) {
    // console.log("receivingData", receivingData);
    const componentRef = useRef();
    const status_lbl = (
        <span className={`lbl-status mr-1 lbl-${receivingData.status}`}>
            {receivingData.status}
        </span>
    );

    // previous button
    const previousBtn = (
        // <> { receivingData.latest_lot_no != receivingData.lot_no && <div className="mdl-arrow-left arr-link"><Link onClick={() => goPage('prev')}><i className="la la-angle-left mdl-arrow"></i></Link></div>}</>
        <>
            {' '}
            {receivingData.latest_ir_no != receivingData.item_receipt_no && (
                <div className="mdl-arrow-left arr-link">
                    <Link onClick={() => goPage('prev')}>
                        <i className="la la-angle-left mdl-arrow"></i>
                    </Link>
                </div>
            )}
        </>
    );

    // next button
    const nextBtn = (
        // <>{ receivingData.oldest_lot_no != receivingData.lot_no && <div className="mdl-arrow-right arr-link"><Link onClick={() => goPage('next')}><i className="la la-angle-right mdl-arrow"></i></Link></div> }</>
        <>
            {receivingData.oldest_ir_no != receivingData.item_receipt_no && (
                <div className="mdl-arrow-right arr-link">
                    <Link onClick={() => goPage('next')}>
                        <i className="la la-angle-right mdl-arrow"></i>
                    </Link>
                </div>
            )}
        </>
    );

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    let file_counter =
        (receivingData.uploads ? receivingData.uploads.length : 0) +
        new_uploads.length -
        files_to_remove.length;

    return (
        <Modal
            centered
            dialogClassName="modal-wdth"
            show={show}
            onHide={onHide}
            size="lg"
            animation={true}
        >
            <Modal.Header className="inv-bg-header">
                <div className="col-4 pl-0">
                    <div className="modal-status">{status_lbl}</div>
                </div>
                <div className="col-8 pr-0">
                    <div className="float-right">
                        <Link
                            to={`receivings/edit/${receivingData._id}`}
                            className="btn btn-circle btn-inv btn-grey mr-1 icon-edit-c"
                        >
                            <i className="ft ft-edit cursor-pointer"></i>
                        </Link>
                        <button
                            onClick={(e) => globalDropDown(e, 'show')}
                            className="btn btn-circle btn-inv btn-grey mr-1 icon-paper-c relative"
                        >
                            <i className="ft ft-paperclip cursor-pointer"></i>
                            {file_counter > 0 ? (
                                <span className="badge badge-pill badge-sm badge-danger badge-up badge-file">
                                    {file_counter > 9 ? '9+' : file_counter}
                                </span>
                            ) : (
                                <></>
                            )}
                        </button>
                        <DropDownAttachFileGlobal
                            origin="receiving"
                            origin_data={receivingData}
                            new_uploads={new_uploads}
                            files_to_remove={files_to_remove}
                        />
                        {receivingData.email_sent ? (
                            <button
                                className="btn btn-circle btn-inv btn-grey mr-1"
                                onClick={(e) =>
                                    reSendEmail(e, receivingData._id)
                                }
                            >
                                <i
                                    className={
                                        sending_email
                                            ? 'icon-loading-custom icon-20 cursor-pointer'
                                            : 'ft-custom-mail-check cursor-pointer'
                                    }
                                ></i>
                            </button>
                        ) : (
                            <button
                                className="btn btn-circle btn-inv btn-grey mr-1"
                                onClick={(e) => sendEmail(e, receivingData._id)}
                            >
                                <i
                                    className={
                                        sending_email
                                            ? 'icon-loading-custom icon-20 cursor-pointer'
                                            : 'ft-custom-mail-x cursor-pointer'
                                    }
                                ></i>
                            </button>
                        )}
                        <button
                            className="btn btn-circle btn-inv btn-grey mr-1 icon-pdf-c"
                            onClick={(e) =>
                                getPdf(
                                    e,
                                    receivingData._id,
                                    receivingData.item_receipt_no,
                                    receivingData.supplier_name
                                )
                            }
                        >
                            <i
                                className={
                                    download_pdf
                                        ? 'icon-loading-custom icon-20 cursor-pointer'
                                        : 'la la-file-pdf-o cursor-pointer'
                                }
                            ></i>
                        </button>
                        <button
                            onClick={handlePrint}
                            className="btn btn-circle btn-inv btn-grey mr-1"
                        >
                            <i className="ft ft-printer cursor-pointer"></i>
                        </button>
                        <button
                            onClick={onHide}
                            className="btn btn-circle btn-inv m-close-btn cursor-pointer"
                        >
                            <i className="ft ft-x cursor-pointer"></i>
                        </button>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body className="unset-scroll p-0 m-0">
                <div className="sd-border">
                    {previousBtn}
                    {loading ? (
                        <div className="my-5">
                            <MiniSpinner />
                        </div>
                    ) : (
                        <ReceivingCustomTemplate componentRef={componentRef} />
                    )}
                    {nextBtn}
                </div>
            </Modal.Body>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    receiving: state.receiving,
    new_uploads: state.receiving.new_uploads,
    files_to_remove: state.receiving.files_to_remove,
    subs_setting: state.receiving.subs_setting,
});

export default connect(mapStateToProps, {
    goPage,
    globalDropDown,
    sendEmail,
    reSendEmail,
    getPdf,
})(ReceivingPrintModal);
