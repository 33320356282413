import React, { Fragment } from 'react';
import {
    dateFilterInput,
    dateFilterClear,
    handleDateFilterSubmit,
    handleSelectMovementType,
    getDailyMovement,
} from '../../redux/actions/daily_movement/daily_movement.actions';
import { connect } from 'react-redux';
import Select from 'react-select';

function MovementFilter({
    daily_movement: {
        date_from,
        date_to,
        movement_options,
        movement_option,
        isSearchable,
    },
    dateFilterInput,
    dateFilterClear,
    handleDateFilterSubmit,
    filterBy,
    pageOrigin,
    handleSelectMovementType,
    getDailyMovement,
}) {
    const handleMovementTypeChange = (selectedOption) => {
        handleSelectMovementType(selectedOption); // Dispatches action to update the movement option
        // Trigger fetching data based on the selected option
        // getDailyMovement(1, null, pageOrigin);
    };

    return (
        <Fragment>
            <form
                onSubmit={(e) =>
                    handleDateFilterSubmit(e, filterBy, pageOrigin)
                }
            >
                <section className="row mt-1 f-regular">
                    <div className="col-sm-12 col-md-3">
                        <div className="form-group row no-gutters">
                            <label className="col-4 text-center">
                                From: <span className="text-danger">*</span>
                            </label>
                            <div className="col-8">
                                <input
                                    type="date"
                                    name="date_from"
                                    value={date_from}
                                    onChange={dateFilterInput}
                                    className="form-control"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-3">
                        <div className="form-group row no-gutters">
                            <label className="col-4 text-center">
                                To: <span className="text-danger">*</span>
                            </label>
                            <div className="col-8">
                                <input
                                    type="date"
                                    name="date_to"
                                    value={date_to}
                                    onChange={dateFilterInput}
                                    className="form-control"
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-3">
                        <div className="form-group row no-gutters">
                            <label className="col-4 text-center">
                                Movement Type:
                            </label>
                            <div className="col-8">
                                <Select
                                    options={movement_options}
                                    onChange={handleMovementTypeChange} // Updates movement_option and refetches movement data
                                    value={movement_option}
                                    isSearchable={isSearchable}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-3">
                        <div className="btn-group" role="group">
                            <button className="btn btn-primary">Filter</button>
                            <a
                                onClick={() =>
                                    dateFilterClear(filterBy, pageOrigin)
                                }
                                className="btn btn-danger text-white"
                            >
                                <i className="ft ft-spinner"></i> Clear
                            </a>
                        </div>
                    </div>
                </section>
            </form>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    daily_movement: state.daily_movement,
});

export default connect(mapStateToProps, {
    dateFilterInput,
    dateFilterClear,
    handleDateFilterSubmit,
    handleSelectMovementType,
    getDailyMovement,
})(MovementFilter);
